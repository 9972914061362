import React from "react";
import { ReactComponent as RiderJourneyChartSvg } from "assets/Rider Journey Chart.svg";
import styled from "styled-components";

type RiderJourneyChartProps = {
  score: number;
};

export const RiderJourneyChart = ({ score }: RiderJourneyChartProps) => {
  let className = "level1";
  if (score > 10 && score <= 20) {
    className = "level2";
  } else if (score > 20 && score <= 30) {
    className = "level3";
  } else if (score > 30 && score <= 40) {
    className = "level4";
  } else if (score > 40 && score <= 50) {
    className = "level5";
  } else if (score > 50 && score <= 60) {
    className = "level6";
  } else if (score > 60 && score <= 70) {
    className = "level7";
  } else if (score > 70 && score <= 80) {
    className = "level8";
  } else if (score > 80 && score <= 90) {
    className = "level9";
  } else if (score > 90) {
    className = "level10";
  }
  return (
    <StyledRiderJourneyChartContainer>
      <RiderJourneyChartSvg className={className} />
    </StyledRiderJourneyChartContainer>
  );
};

const StyledRiderJourneyChartContainer = styled.div`
  display: grid;
  align-items: center;
  height: 100%;
  svg {
    min-height: 250px;
  }
  text {
    font-family: Work Sans, sans serif !important;
  }
  .circle {
    circle {
      fill: #424242 !important;
    }
    text {
      fill: #fff;
    }
  }
  .level1,
  .level2,
  .level3,
  .level4,
  .level5,
  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path1 {
      fill: #f2ed00;
    }
  }

  .level2,
  .level3,
  .level4,
  .level5,
  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path2 {
      fill: #f7b800;
    }
  }

  .level3,
  .level4,
  .level5,
  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path3 {
      fill: #ff8f00;
    }
  }

  .level4,
  .level5,
  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path4 {
      fill: #ff4900;
    }
  }

  .level5,
  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path5 {
      fill: #ff0006;
    }
  }

  .level6,
  .level7,
  .level8,
  .level9,
  .level10 {
    .path6 {
      fill: #d70082;
    }
  }

  .level7,
  .level8,
  .level9,
  .level10 {
    .path7 {
      fill: #2a3098;
    }
  }

  .level8,
  .level9,
  .level10 {
    .path8 {
      fill: #0062b5;
    }
  }

  .level9,
  .level10 {
    .path9 {
      fill: #00a4cd;
    }
  }

  .level10 {
    .path10 {
      fill: #74ca0e;
    }
  }
`;
