import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; 
import styled from "styled-components";

export type ImageWithLightboxProps = {
    src: string;
    alt: string;
    withLightbox?: boolean;
}

export const Image = ({ src, alt, withLightbox }: ImageWithLightboxProps) => {
    const [isOpen,setOpen] = useState<boolean>(false);
    const handleCloseRequest = () => {
        setOpen(false);
    }
    const handleOpenLightbox = () => {
        setOpen(true);
    }

    return (
        <>
        {withLightbox ? (
        <>
            <ImgWithHover src={src} alt={alt} onClick={handleOpenLightbox} />
            {isOpen &&
                <Lightbox 
                    mainSrc={src}
                    onCloseRequest={handleCloseRequest}
                />
            }
         </>
         ) : (
            <img src={src} alt={alt} />
         )}
         </>
    );
}

const ImgWithHover = styled.img`
    &:hover {
        cursor: pointer;
    }
`;
