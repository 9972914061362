import * as yup from "yup";

export const drillSchemaFields = (activityTypes?: ActivityType[]) => {
  return activityTypes?.reduce((acc, activityType) => {
    activityType.drills.reduce((_, drillType) => {
      acc[`videos-${drillType.name}`] = yup.mixed().when("activityType", (value: string, schema: yup.AnySchema) => {
        if (!activityTypes.some((at) => at.name === value && at.drills.some((d) => d.name === drillType.name))) return schema;

        return schema
          .test("required", `${drillType.name} video is required`, (value: FileList) => {
            return value && value.length > 0;
          })
          .test("fileSize", `Video must be less than 10GB`, (value: FileList) => {
            if (!value || !value.length) return false;
            return value && value[0].size < 10737418240;
          });
      });
      return acc;
    }, acc);
    return acc;
  }, {} as { [key: string]: any });
};

export const uploadFormSchemaFactory = (activityTypes?: ActivityType[]) =>
  yup
    .object()
    .shape({
      activityType: yup.string().required("Activity Type is required").notOneOf(["Other"]),
      bikeMake: yup.string().required("Bike Make is required"),
      bikeModel: yup.string().required("Bike Model is required"),
      bikeCapacity: yup
        .number()
        .typeError("Bike Capacity must be a number")
        .required("Bike Capacity is required")
        .min(0, "Bike Capacity must be a reasonable number")
        .max(9999, "Bike Capacity must be a reasonable number"),
      groups: yup.array(),
      challenges: yup.array(),
      hasConfirmed: yup.boolean().isTrue("Please confirm that the above statement is true"),
      ...drillSchemaFields(activityTypes),
    })
    .test("rider-details-valid", "", ({ riderFirstName, riderLastName, riderEmail }) => {
      if (!!riderFirstName || !!riderLastName || !!riderEmail) {
        if (!riderFirstName || !riderLastName || !riderEmail) {
          return false;
        }
      }
      return true;
    });
