import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography, SimplePaletteColorOptions } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import styled from "styled-components";
import { BodyText } from "..";

type GaugeProps = {
  /**
   * Number to display inside gauge (0 to 100)
   */
  value: number;

  size?: number;
  thickness?: number;
};

type LabelGaugeProps = GaugeProps & {
  /**
   * Text to display underneath gauge
   */
  label: string;
};

type ButtonGaugeProps = LabelGaugeProps & {
  selected: boolean;
  onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

const GaugeBox = ({ value, size = 80, thickness = 4.5 }: GaugeProps) => {
  const [actualValue, setActualValue] = useState(0);

  useEffect(() => {
    setTimeout(() => setActualValue(value), 0);
  }, [value]);

  return (
    <Box position="relative" display="inline-flex">
      <Box position="absolute">
        <BackgroundCircularProgress variant="determinate" value={100} size={size} thickness={thickness} />
      </Box>
      <AnimatedCircularProgress variant="determinate" color="primary" value={actualValue} size={size} thickness={thickness} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <GaugeValue variant="h4" color="textSecondary">{`${Math.round(value)}`}</GaugeValue>
      </Box>
    </Box>
  );
};

/**
 * A non-selectable gauge
 */
export const LabelGauge = ({ value, label, size, thickness }: LabelGaugeProps) => {
  return (
    <GaugeContainer>
      <GaugeBox value={value} size={size} thickness={thickness} />
      <GaugeLabel>{label}</GaugeLabel>
    </GaugeContainer>
  );
};

/**
 * A gauge that can be selected
 */
export const ButtonGauge = ({ selected, onClick, ...gaugeProps }: ButtonGaugeProps) => {
  return (
    <GaugeButton onClick={onClick} selected={selected}>
      <LabelGauge {...gaugeProps} />
    </GaugeButton>
  );
};

const BackgroundCircularProgress = styled(CircularProgress)`
  color: ${grey[700]};
`;

const GaugeLabel = styled(BodyText)`
  margin-top: ${(props) => props.theme.spacing * 2}px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 0.9em;
  }
`;

const GaugeValue = styled(Typography)`
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 1.1em;
  }
`;

const GaugeButton = styled(Button)<{ selected: boolean }>`
  ${GaugeLabel} {
    border-bottom: 2px solid
      ${(props) => (props.selected ? (props.theme.palette.primary as SimplePaletteColorOptions)?.dark : "transparent")};
  }

  &:hover {
    ${GaugeLabel} {
      border-bottom-color: ${(props) => (props.selected ? "" : (props.theme.palette.primary as SimplePaletteColorOptions)?.main)};
    }
  }
`;

const GaugeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AnimatedCircularProgress = styled(CircularProgress)`
  .MuiCircularProgress-circleDeterminate {
    transition: stroke-dashoffset 2200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    stroke-linecap: round;
  }
`;
