import React from "react";
import { Subheading, BodyText } from "components/primitives";
import {
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useApiData } from "plumbing/api";
import { listRiderGroups } from "api/riderGroups";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const GroupInfo = ({ group, size }: { group: GroupModel; size: "sm" | "lg" }) => {
  if (size === "lg")
    return (
      <GroupCard>
        <GroupImage image={group.imageUrl} title="Group Image" />
        <GroupCardContent>
          <BodyText>{group.name}</BodyText>
          <Chip variant="outlined" color="primary" label={`${group.riderCount} members`} size="small" style={{ marginTop: "4px" }} />
        </GroupCardContent>
        <GroupCardActions>
          <Button size="small" color="primary" variant="contained" fullWidth component={Link} to={`groups/${group.slug}`}>
            View Group
          </Button>
        </GroupCardActions>
      </GroupCard>
    );

  return (
    <GroupInfoListItemContainer>
      <ListItem button component={Link} to={`groups/${group.slug}`}>
        <ListItemAvatar>
          <GroupAvatar alt="Group Logo" src={group.imageUrl} variant="rounded" />
        </ListItemAvatar>
        <ListItemText>
          <BodyText>{group.name}</BodyText>
        </ListItemText>
      </ListItem>
    </GroupInfoListItemContainer>
  );
};

const GroupInfoLoading = ({ size }: { size: "sm" | "lg" }) => {
  if (size === "lg")
    return (
      <GroupCard>
        <GroupImage>
          <Skeleton variant="rect" height={200} />
        </GroupImage>
        <GroupCardContent>
          <BodyText>
            <Skeleton />
          </BodyText>
          <Skeleton width={100} />
        </GroupCardContent>
        <GroupCardActions>
          <Skeleton variant="rect" height={30} width="100%" />
        </GroupCardActions>
      </GroupCard>
    );

  return (
    <GroupInfoListItemContainer>
      <ListItem>
        <ListItemAvatar>
          <Skeleton height={40} width={40} variant="rect" style={{ borderRadius: "4px" }} />
        </ListItemAvatar>
        <ListItemText>
          <Skeleton />
        </ListItemText>
      </ListItem>
    </GroupInfoListItemContainer>
  );
};

export const RiderGroups = ({ forceSmallSize }: { forceSmallSize?: boolean }) => {
  const { data, loadingState } = useApiData(() => listRiderGroups({}));

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const groupInfoSize = isSmall || forceSmallSize ? "sm" : "lg";
  return (
    <>
      <Subheading gutterBottom>My Groups</Subheading>
      <GroupList forceSmall={forceSmallSize}>
        {loadingState.isLoading && <GroupInfoLoading size={groupInfoSize} />}
        {data?.riderGroups.map((g) => (
          <GroupInfo group={g} key={g.slug} size={groupInfoSize} />
        ))}
      </GroupList>
      {data?.riderGroups.length === 0 && <BodyText>You're not a member of any groups yet</BodyText>}
      <Divider style={{ margin: "16px 0" }} />

      <Subheading gutterBottom>Other Groups</Subheading>
      <GroupList forceSmall={forceSmallSize}>
        {loadingState.isLoading && <GroupInfoLoading size={groupInfoSize} />}
        {data?.otherGroups.map((g) => (
          <GroupInfo group={g} key={g.slug} size={groupInfoSize} />
        ))}
      </GroupList>
      {data?.otherGroups.length === 0 && <BodyText>There are no other groups to join</BodyText>}
    </>
  );
};

const GroupAvatar = styled(Avatar)`
  img.MuiAvatar-img {
    object-fit: contain;
  }
`;

const GroupInfoListItemContainer = styled.div`
  .MuiListItem-root {
    margin: 0 -4px;
    padding: 4px;
    border-radius: 4px;
    height: 64px;
  }
`;

const GroupList = styled(({ forceSmall, children, ...props }) => <div {...props}>{children}</div>)<{ forceSmall: boolean }>`
  display: ${(props) => (props.forceSmall ? "block" : "grid")};
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`;

const GroupImage = styled(CardMedia)`
  height: 200px;
  background-size: contain;
  margin: ${(props) => props.theme.spacing * 2}px;
`;

const GroupCardContent = styled(CardContent)`
  padding: ${(props) => props.theme.spacing * 2}px;
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex: 1;
  }

  .MuiChip-root {
    align-self: flex-start;
  }
`;

const GroupCardActions = styled(CardActions)`
  flex: 1;
  align-items: flex-end;
`;

const GroupCard = styled(Card)`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
