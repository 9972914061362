import React from "react";
import { PageGrid } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, LoadingPanel, VideoPlayer, BodyText } from "components/primitives";
import { useApiData } from "plumbing/api";
import { getTrainingVideoById } from "api/trainingVideos";
import { useParams } from "react-router-dom";
import { Grid, Card, CardContent } from "@material-ui/core";

export const TrainingVideo = () => {
  const { id } = useParams<{ id: string }>();
  const { data: video, loadingState: videoLoadingState } = useApiData(
    () => {
      return getTrainingVideoById(id);
    },
    undefined,
    [id]
  );

  return (
    <>
      <Helmet>
        <title> Digital Academy - Training {video ? video.name : "Video"}</title>
      </Helmet>
      <PageGrid>
        <LoadingPanel loadingState={[videoLoadingState]}>
          {video && (
            <>
              <Heading>{video?.name}</Heading>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <VideoPlayer src={video?.url} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <BodyText>{video.description}</BodyText>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </LoadingPanel>
      </PageGrid>
    </>
  );
};
