import React from "react";
import ReactMarkdown from "react-markdown";
import { Heading, Subheading, BodyText } from "./Typography";
import styled from "styled-components";
import { Link } from "@material-ui/core";

type MarkdownProps = {
  children: string;
};

export const Markdown = ({ children }: MarkdownProps) => {
  return (
    <ReactMarkdownContainer>
      <ReactMarkdown
        components={{
          // Map `h1` (`# heading`) to use `h2`s.
          h1: ({ node, ...props }) => <Heading {...props} />,
          h2: ({ node, ...props }) => <Subheading gutterBottom {...props} />,
          p: ({ node, ...props }) => <BodyText {...props} />,
          a: ({ node, ...props }) => <Link {...props} target="_blank" />,
        }}
      >
        {children}
      </ReactMarkdown>
    </ReactMarkdownContainer>
  );
};

const ReactMarkdownContainer = styled.div`
  ul:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing * 4}px;
  }
`;
