import React from "react";
import { Subheading, BodyText } from "components/primitives";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom"

type ActivityAdviceProps = { activity: ActivityModel };
export const ActivityAdvice = ({ activity }: ActivityAdviceProps) => {
  return (
    <>
      <Subheading gutterBottom>Advice</Subheading>
      <BodyText>{activity.advice || "No advice is currently available"}</BodyText>
      {activity.adviceTrainingVideoId && (
        <Button component={Link} to={`/training/videos/${activity.adviceTrainingVideoId}`} variant="contained" color="primary" style={{ marginTop: "16px" }}>
          Play Video
        </Button>
    )}
    </>     
  );
};