import React from "react";
import { SimpleCard, Subheading, Caption, HiddenField, Autocomplete } from "components/primitives";
import { Grid, IconButton, ListItemAvatar, ListItem, ListItemText, Avatar, Card } from "@material-ui/core";
import styled from "styled-components";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import { useFieldArray, useFormContext } from "react-hook-form";
import { searchRiders } from "api/rider";

export const MaintainCoachesPanel = () => {
  const { control, formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "coaches",
    control,
  });

  const handleAddNewCoach = (coach: CoachModel) => {
    append(coach);
  };

  return (
    <Grid item xs={12}>
      <SimpleCard elevation={6}>
        <Subheading>Maintain Coaches</Subheading>
        <Caption>Add / Remove coaches for this group</Caption>

        <Grid container spacing={2}>
          <CoachSearchBar existingCoaches={fields as CoachModel[]} onAddCoach={handleAddNewCoach} disable={formState.isSubmitting} />
          <Grid item xs={12}>
            <CoachListContainer>
              {fields.map((field, index) => {
                return (
                  <StyledCard elevation={6} key={field.id}>
                    <HiddenField name={`coaches.${index}.id`} defaultValue={field.id!} />
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {field.firstName.substr(0, 1)}
                          {field.lastName.substr(0, 1)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={`${field.firstName} ${field.lastName}`} secondary={field.email} />
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItem>
                  </StyledCard>
                );
              })}
            </CoachListContainer>
          </Grid>
        </Grid>
      </SimpleCard>
    </Grid>
  );
};

const StyledCard = styled(Card)`
  li {
    list-style-type: none !important;
  }
`;

const CoachListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type CoachSearchBarProps = {
  onAddCoach: (coach: CoachModel) => void;
  disable: boolean;
  existingCoaches: CoachModel[];
};

export const CoachSearchBar = ({ existingCoaches, onAddCoach, disable }: CoachSearchBarProps) => {
  const handleSearch = async (searchString: string) => {
    const searchResponse = await searchRiders({ searchQuery: searchString });
    return searchResponse.data.riders
      .filter((r) => !existingCoaches.some((c) => `${c.id}` === `${r.riderId}`))
      .map<CoachModel>((x) => ({
        id: x.riderId,
        firstName: x.firstName,
        lastName: x.lastName,
        email: x.email,
        externalIds: [],
      }));
  };

  const handleCoachSelected = (coach: CoachModel | null) => {
    if (coach != null) {
      onAddCoach(coach);
    }
  };

  return (
    <>
      <Autocomplete
        label="Search coaches..."
        onSearch={handleSearch}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
        onSelected={handleCoachSelected}
        getOptionSelected={(a, b) => {
          return a.id === b.id;
        }}
        disabled={disable}
        value={null}
      />
    </>
  );
};
