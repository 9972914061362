import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { VideoPlayer, LoadingPanel } from "components/primitives";
import { getVideoOverlay } from "api/videoOverlay";
import { useApiData } from "plumbing/api";
import logo from "assets/logo white gold.png";
import { CoastingIndicator } from "./CoastingIndicator";
import { WeightDistChart } from "./WeightDistChart";
import { LeanAngleChart } from "./LeanAngleChart";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { LONG_DATE_FORMAT } from "DateFormats";
import { usePrevious } from "plumbing/hooks/usePrevious";

type ActivityVideoProps = {
  videoSrc?: string | null;
  activity: ActivityModel;
  drillName?: string | null;
};

export const ActivityVideo = ({ videoSrc, activity, drillName }: ActivityVideoProps) => {
  const {
    data: videoOverlay,
    loadingState: overlayLoadingState,
    fetchData: fetchVideoOverlay,
  } = useApiData(
    () => {
      if (activity?.id && drillName) {
        return getVideoOverlay(activity.id, drillName);
      }
      return Promise.resolve({ data: undefined });
    },
    undefined,
    [activity.id, drillName],
    true
  );

  const previousDrillName = usePrevious(drillName);
  useEffect(() => {
    if (drillName && previousDrillName && drillName !== previousDrillName) {
      fetchVideoOverlay();
    }
  }, [drillName, fetchVideoOverlay, previousDrillName]);

  // Parse data
  const [videoOverlayData, setVideoOverlayData] = useState<{ t: number; lean: number; coasting: boolean; frontWeight: number; rearWeight: number }[]>([]);
  useEffect(() => {
    if (!videoOverlay) return;
    const leanAngleData = videoOverlay.find((d) => d.Name === "Lean Angle");
    const coastingData = videoOverlay.find((d) => d.Name === "Coasting");
    const frontWeightData = videoOverlay.find((d) => d.Name === "Front Weight Dist");
    const rearWeightData = videoOverlay.find((d) => d.Name === "Rear Weight Dist");
    if (!leanAngleData || !coastingData || !frontWeightData || !rearWeightData) return;

    const overlayData = videoOverlay[0].DataPoints.map((d) => ({
      t: d.Y || 0,
      lean: leanAngleData.DataPoints.find((x) => x.Y === d.Y)?.X || 0,
      coasting: coastingData.DataPoints.find((x) => x.Y === d.Y)?.X === 1,
      frontWeight: frontWeightData.DataPoints.find((x) => x.Y === d.Y)?.X || 0,
      rearWeight: rearWeightData.DataPoints.find((x) => x.Y === d.Y)?.X || 0,
    }));

    setVideoOverlayData(overlayData);
  }, [videoOverlay]);

  // Handle video time updates
  const [, setCurrentTime] = useState<number | undefined>(0);
  const [leanAngle, setCurrentLeanAngle] = useState(0);
  const [coasting, setCoasting] = useState(false);
  const [frontWeightDist, setFrontWeightDist] = useState(0);
  const [rearWeightDist, setRearWeightDist] = useState(0);
  const onTimeUpdate = (newCurrentTime?: number) => {
    if (!videoOverlay) return;

    setCurrentTime(newCurrentTime);

    const data = videoOverlayData?.find((d) => d.t > (newCurrentTime || 0))!;

    if (data) {
      setCurrentLeanAngle(data.lean);
      setCoasting(data.coasting);
      setFrontWeightDist(data.frontWeight);
      setRearWeightDist(data.rearWeight);
    }
  };

  // Hide controls based on drill / data availability
  const [isLeanVisible, setIsLeanVisible] = useState(false);
  const [isCoastingVisible, setIsCoastingVisible] = useState(false);
  const [isWeightDistVisible, setIsWeightDistVisible] = useState(false);
  useEffect(() => {
    if (!videoOverlay) {
      setIsLeanVisible(false);
      setIsCoastingVisible(false);
      setIsWeightDistVisible(false);
      return;
    }
    const isLeanDataAvailable = (videoOverlay.find((d) => d.Name === "Lean Angle")?.DataPoints.length || 0) > 0;
    setIsLeanVisible(isLeanDataAvailable);

    const isCoastingDataAvailable = (videoOverlay.find((d) => d.Name === "Coasting")?.DataPoints.length || 0) > 0;
    const isCoastingShownForDrill = ["Figure 8", "Avoidance", "Video", "Emergency Stop", "Corner Avoidance", "Balance"].indexOf(drillName || "") !== -1;
    setIsCoastingVisible(isCoastingDataAvailable && isCoastingShownForDrill);

    const isWeightDistDataAvailable = (videoOverlay.find((d) => d.Name === "Front Weight Dist")?.DataPoints.length || 0) > 0;
    const isWeightDistShownForDrill = ["Figure 8", "Avoidance", "Video", "Emergency Stop", "Corner Avoidance", "Balance"].indexOf(drillName || "") !== -1;
    setIsWeightDistVisible(isWeightDistDataAvailable && isWeightDistShownForDrill);
  }, [videoOverlay, drillName]);

  return (
    <LoadingPanel loadingState={[overlayLoadingState]}>
      {!videoSrc || !videoOverlay ? (
        <VideoPlaceholder>{videoSrc ? "" : "Video is not currently available"}</VideoPlaceholder>
      ) : (
        <StyledVideoPlayer src={videoSrc} onTimeUpdate={onTimeUpdate}>
          <VideoOverlay>
            <VideoOverlayInner>
              <RiderName>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" height="100%" style={{ fontFamily: "Work Sans" }}>
                  <text x="0" y="22" fontSize="67" dominantBaseline="central" fill="#fff">
                    {`${activity.riderFirstName} ${activity.riderLastName}`}
                  </text>
                  <text x="0" y="78" fontSize="50" dominantBaseline="central" fill="#fff">
                    {format(parseISO(activity.dateTime), LONG_DATE_FORMAT)}
                  </text>
                </svg>
              </RiderName>
              <Logo>
                <LogoImg src={logo} alt="logo" />
              </Logo>
              {isCoastingVisible && (
                <Coasting>
                  <CoastingIndicator value={coasting} />
                </Coasting>
              )}
              {isLeanVisible && (
                <LeanAngle>
                  <LeanAngleChart leanAngle={leanAngle} />
                </LeanAngle>
              )}
              {isWeightDistVisible && (
                <WeightDist>
                  <WeightDistChart front={frontWeightDist} rear={rearWeightDist} />
                </WeightDist>
              )}
            </VideoOverlayInner>
          </VideoOverlay>
        </StyledVideoPlayer>
      )}
    </LoadingPanel>
  );
};

const videoStyles = css`
  width: 100%;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  ${videoStyles}
`;

const VideoPlaceholder = styled.div`
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 324px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    min-height: 234px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    min-height: 293px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    min-height: 205px;
  }
`;

const VideoOverlay = styled.div`
  position: relative;
  padding: 2%;
  padding-bottom: 34px;
  height: 100%;
  width: 100%;
`;

const VideoOverlayInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const RiderName = styled.div`
  position: absolute;
  height: 6%;
`;

const Logo = styled.div`
  position: absolute;
  position: absolute;
  left: 30%;
  right: 30%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  height: 100%;
`;

const Coasting = styled.div`
  position: absolute;
  right: 0;
  width: 10%;
  font-size: 1rem;
`;

const LeanAngle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
  display: flex;
`;

const WeightDist = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  display: flex;
  justify-content: flex-end;
`;
