import { AnimationTiming } from "recharts/types/util/types";

export const lineChartConfig = {
  animationDuration: 2000,
  animationEasing: "ease" as AnimationTiming,
};
export const barChartConfig = {
  animationDuration: 1500,
  animationEasing: "linear" as AnimationTiming,
};

export const chartConfig = {
  aspectRatio: 1.3,
};
