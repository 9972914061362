import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle` 
body, html, #root, #root > div {
  height: 100%
}

.MuiContainer-root{
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    padding-left: ${(props) => props.theme.spacing * 3}px;
    padding-right: ${(props) => props.theme.spacing * 3}px;
  }
}

.MuiCardContent-root {
  padding: ${(props) => props.theme.spacing * 4}px !important;
  
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
  padding: ${(props) => props.theme.spacing * 3}px !important;
  }
}

.MuiTabPanel-root{
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
  padding: ${(props) => props.theme.spacing * 3}px !important;
  }
}

ul{ 
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}

.MuiCardContent-root:last-child {
  padding: ${(props) => props.theme.spacing * 4}px;
}

.MuiListItemIcon-root{
  min-width: 36px;
}

.MuiDialogActions-root {
  padding: 16px 24px;
}

.MuiPopover-paper .MuiListSubheader-root {
  line-height: 24px;
}
`;
