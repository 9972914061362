import React, { useState } from "react";
import { Leaderboard, RiderList, LongDate, ActivityFeed } from "components/ui";
import styled from "styled-components";
import { AppBar, Tabs, Tab, Card, Chip, Container, Button, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Toolbar, ListSubheader } from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import { SimpleCard, Heading, BodyText, Caption, Markdown, ConfirmDialog, VideoPlayer } from "components/primitives";
import { JoinChallenge } from "components/ui/Challenges";
import { listChallengeRiders, deleteChallenge, removeRiderFromChallenge } from "api/challenge";
import { userStore } from "plumbing/auth";
import { observer } from "mobx-react";
import MoreIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { useHistory, Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { analytics } from "plumbing/analytics";

type ChallengeDashboardViewProps = {
  challenge: ChallengeModel;
  onChange?: (model?: ChallengeModel) => Promise<any>;
  previewMode?: boolean;
  subscription: SubscriptionModel;
};

export const ChallengeDashboardView = observer(({ challenge, onChange, previewMode, subscription }: ChallengeDashboardViewProps) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = React.useState("Leaderboard");
  const handleJoinChallengeRequested = (challengeModel: ChallengeModel) => {
    if (onChange) onChange({ ...challenge, ...challengeModel });
    analytics.sendEvent({ category: "Challenges", action: "Join" });
    setSelectedTab("Leaderboard");
  };

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [isDeleteChallengeLoading, setIsDeleteChallengeLoading] = useState(false);
  const challengeMenu = usePopupState({ variant: "popover", popupId: "challengeMenu" });

  const challengeTabMenu = usePopupState({ variant: "popover", popupId: "challengeTabMenu" });
  const tabNames = ["Leaderboard", "Recent Activity", "Prizes", "Rules", "Members"];
  if (!challenge.riderJoined) tabNames.push("Join");
  let prizeLabel = challenge.name === "eASBK" ? "Classes" : "Prizes";
  return (
    <ChallengeDashboardLayout>
      <ChallengeHeaderWithImage as={!challenge.youtubeVideoUrl ? ChallengeHeaderWithImage : ChallengeHeaderWithVideo}>
        <ChallengeName>
          <Cardheading>{challenge.name}</Cardheading>
        </ChallengeName>
        <ChallengeDates>
          <LongDate date={challenge.startDate} /> - <LongDate date={challenge.endDate} />
        </ChallengeDates>
        <ChallengeSubheading>
          <Chip label={challenge.activitySkillType} variant="outlined" color="primary" size="small" />
          <Chip label={`${challenge.riderCount || 0} members`} variant="outlined" color="primary" size="small" />
          {challenge.riderJoined && <Chip label="Joined" variant="outlined" color="primary" size="small" />}
        </ChallengeSubheading>
        {!previewMode && userStore.isAdmin && (
          <ChallengeMenu>
            <IconButton aria-label="Challenge Menu" {...bindTrigger(challengeMenu)}>
              <MoreIcon />
            </IconButton>
            <Menu {...bindMenu(challengeMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
              <StyledListSubheader>Admin</StyledListSubheader>
              <MenuItem component={Link} to={`/challenges/${challenge.slug}/edit`}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Edit Challenge" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsConfirmDeleteDialogOpen(true);
                  challengeMenu.close();
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Delete Challenge" />
              </MenuItem>
            </Menu>
          </ChallengeMenu>
        )}
        <ChallengeDescription>
          <Markdown>{challenge.description}</Markdown>
        </ChallengeDescription>
        {!challenge.riderJoined && (
          <ChallengeJoin>
            <Button variant="contained" color="primary" size="medium" onClick={() => setSelectedTab("Join")}>
              Join Challenge
            </Button>
          </ChallengeJoin>
        )}
        {challenge.youtubeVideoUrl ? (
          <ChallengeHeaderVideo>
            <VideoPlayer src={challenge.youtubeVideoUrl} youtube />
          </ChallengeHeaderVideo>
        ) : (
          <ChallengeHeaderImage>
            <img src={challenge.imageUrl} alt="Challenge Logo" />
          </ChallengeHeaderImage>
        )}
      </ChallengeHeaderWithImage>
      <ChallengeContent>
        <Card>
          <MenuAppBar position="static" color="primary">
            <Toolbar variant="dense">
              <div style={{ flex: 1 }}>
                <BodyText>{selectedTab.toUpperCase()}</BodyText>
              </div>

              <IconButton edge="end" aria-label="Challenge Tab Menu" {...bindTrigger(challengeTabMenu)} color="secondary">
                <MenuIcon />
              </IconButton>
              <Menu {...bindMenu(challengeTabMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                {tabNames.map((tab) => (
                  <MenuItem
                    key={tab}
                    onClick={() => {
                      setSelectedTab(tab);
                      challengeTabMenu.close();
                    }}
                  >
                    {tab}
                  </MenuItem>
                ))}
              </Menu>
            </Toolbar>
          </MenuAppBar>
          <TabContext value={selectedTab}>
            <TabAppBar position="static">
              <Tabs
                value={selectedTab}
                onChange={(_e, newValue) => {
                  setSelectedTab(newValue);
                }}
                aria-label="Challenge Page Sections"
              >
                <Tab label="Leaderboard" value="Leaderboard" />
                <Tab label="Recent Activity" value="Recent Activity" />
                <Tab label={prizeLabel} value="Prizes" />
                <Tab label="Rules" value="Rules" />
                <Tab label="Members" value="Members" />
                {!challenge.riderJoined && <Tab label="Join" value="Join" />}
              </Tabs>
            </TabAppBar>
            <TabPanel value="Leaderboard">{previewMode ? <div>Leaderboard</div> : <Leaderboard challengeSlug={challenge.slug} showTrack />}</TabPanel>
            <TabPanel value="Recent Activity">
              {previewMode ? (
                <div>Recent activities</div>
              ) : (
                <ActivityFeedContainer fixed maxWidth="md">
                  <ActivityFeed challengeSlug={challenge.slug} elevation={6} />
                </ActivityFeedContainer>
              )}
            </TabPanel>
            <TabPanel value="Prizes">
              <Markdown>{challenge.prizes}</Markdown>
            </TabPanel>
            <TabPanel value="Rules">
              <Markdown>{challenge.rules}</Markdown>
            </TabPanel>
            <TabPanel value="Members">
              {previewMode ? (
                <div>Rider list</div>
              ) : (
                <RiderList
                  query={(pagination) => listChallengeRiders(challenge.slug, pagination)}
                  noRidersMessage="This challenge doesn't have any members yet"
                  removeRider={(riderId: number) => removeRiderFromChallenge(challenge.slug, riderId)}
                  removeRiderConfirmText="The rider will no longer be able to compete in the challenge. Any activities that the rider has linked to this challenge will also be unlinked."
                  onChange={onChange}
                />
              )}
            </TabPanel>
            <TabPanel value="Join">
              <JoinChallenge slug={challenge.slug} challengeModel={challenge} onJoinRequested={handleJoinChallengeRequested} previewMode={previewMode} />
            </TabPanel>
          </TabContext>
        </Card>
      </ChallengeContent>
      <ConfirmDialog
        title="Are you sure?"
        open={isConfirmDeleteDialogOpen}
        loading={isDeleteChallengeLoading}
        onClose={async (confirmed) => {
          setIsDeleteChallengeLoading(true);
          if (confirmed) {
            await deleteChallenge(challenge.slug);

            history.push("/");
          }
          setIsConfirmDeleteDialogOpen(false);
          setIsDeleteChallengeLoading(false);
        }}
      >
        <BodyText>Once the challenge is deleted, it no longer be visible. No one will be able to join, participate or see the results of the challenge.</BodyText>
      </ConfirmDialog>
    </ChallengeDashboardLayout>
  );
});

const ChallengeDashboardLayout = styled.div`
  display: grid;
  grid-template:
    "challenge-header"
    "challenge-content";
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-gap: ${(props) => props.theme.spacing * 3}px;
  }
`;

const ChallengeHeaderWithImage = styled(SimpleCard)`
  display: grid;
  grid-template:
    "challenge-name challenge-menu challenge-image"
    "challenge-dates challenge-dates challenge-image"
    "challenge-subheading challenge-subheading challenge-image"
    "challenge-description challenge-description challenge-image"
    "challenge-join challenge-join challenge-image";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto 1fr auto;
  grid-gap: ${(props) => props.theme.spacing}px ${(props) => props.theme.spacing * 6}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "challenge-name challenge-menu challenge-image"
      "challenge-dates challenge-dates challenge-image"
      "challenge-subheading challenge-subheading challenge-image"
      "challenge-description challenge-description challenge-description"
      "challenge-join challenge-join challenge-join";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto 1fr auto;
    grid-gap: ${(props) => props.theme.spacing * 2}px;
  }
`;

const ChallengeHeaderWithVideo = styled(SimpleCard)`
  display: grid;
  grid-template:
    "challenge-name challenge-menu challenge-video"
    "challenge-dates challenge-dates challenge-video"
    "challenge-subheading challenge-subheading challenge-video"
    "challenge-description challenge-description challenge-video"
    "challenge-join challenge-join challenge-video";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto 1fr auto;
  grid-gap: ${(props) => props.theme.spacing}px ${(props) => props.theme.spacing * 6}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "challenge-name challenge-menu"
      "challenge-dates challenge-dates"
      "challenge-subheading challenge-subheading"
      "challenge-video challenge-video"
      "challenge-description challenge-description"
      "challenge-join challenge-join";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-gap: ${(props) => props.theme.spacing * 2}px;
  }
`;

const ChallengeHeaderImage = styled.div`
  grid-area: challenge-image;
  display: flex;
  img {
    max-width: 500px;
    height: 250px;
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
      max-width: 300px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
      max-width: 130px;
      height: 100px;
    }
  }
`;

const ChallengeHeaderVideo = styled.div`
  grid-area: challenge-video;
  display: flex;
  width: 500px;
  height: calc(500px / (16 / 9));

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-area: challenge-video;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    iframe {
      border: none;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;

const ChallengeMenu = styled.div`
  grid-area: challenge-menu;
  button {
    margin-top: -12px;
  }
`;

const ChallengeContent = styled.div`
  grid-area: challenge-content;
`;

const ChallengeName = styled.div`
  grid-area: challenge-name;
  h1 {
    margin-bottom: 0;
  }
`;
const ChallengeDates = styled.div`
  grid-area: challenge-dates;
`;

const Cardheading = styled(Heading)`
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 1.4rem;
  }
`;

const ChallengeJoin = styled.div`
  grid-area: challenge-join;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    button {
      display: block;
      width: 100%;
    }
  }
`;

const ChallengeSubheading = styled(Caption)`
  grid-area: challenge-subheading;
  display: flex;
  gap: ${(props) => props.theme.spacing * 2}px;
`;

const ChallengeDescription = styled(BodyText)`
  grid-area: challenge-description;
  margin-top: ${(props) => props.theme.spacing * 4}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: 0;
  }
`;

const ActivityFeedContainer = styled(Container)`
  padding: 0;
  width: 630px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    width: auto;
  }
`;

const TabAppBar = styled(AppBar)`
  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    display: none;
  }
`;

const MenuAppBar = styled(AppBar)`
  @media (min-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    display: none;
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  line-height: 1;
  margin-top: ${(props) => props.theme.spacing}px;
  margin-bottom: ${(props) => props.theme.spacing}px;
`;
