import React from "react";
import { Button, PropTypes } from "@material-ui/core";
import { Link } from "react-router-dom";

export const LinkButton = ({
  to,
  children,
  disabled,
  variant,
  color,
  startIcon,
}: {
  to: string;
  children: React.ReactNode;
  disabled?: boolean;
  color?: PropTypes.Color;
  variant?: "text" | "outlined" | "contained";
  startIcon?: React.ReactNode;
}) => {
  return (
    <Button component={Link} to={to} disabled={disabled} variant={variant || "contained"} color={color || "primary"} startIcon={startIcon}>
      {children}
    </Button>
  );
};
