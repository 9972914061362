import { makeAutoObservable } from "mobx";
import { decodeToken } from "react-jwt";

interface UserDetails {
  given_name: string;
  family_name: string;
  picture: string;
  sub: string;
  email: string;
  email_verified: boolean;
}

interface DecodedToken {
  "https://motodna.net/given_name": string;
  "https://motodna.net/family_name": string;
  "https://motodna.net/email": string;
  "https://motodna.net/stripe_customer_id": string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions?: string[];
}

class UserStore {
  accessToken: string = "";
  userDetails?: UserDetails;
  decodedToken?: DecodedToken;

  setUserDetails(accessToken: string, userDetails: UserDetails) {
    this.accessToken = accessToken;
    this.userDetails = userDetails;
    this.decodedToken = decodeToken(accessToken);
    this.userDetails.given_name = <string>this.decodedToken?.["https://motodna.net/given_name"];
    this.userDetails.family_name = <string>this.decodedToken?.["https://motodna.net/family_name"];
  }

  hasRole(role: string) {
    return !!this.decodedToken && !!this.decodedToken.permissions?.some((p) => p === role);
  }

  get isAdmin() {
    return this.hasRole("Admin");
  }

  get isCoach() {
    return this.hasRole("Coach");
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const userStore = new UserStore();
(window as any).userStore = userStore;
