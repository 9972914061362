import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { userStore } from "./userStore";
import { AuthProviderProps, auth0Audience } from "./AuthProvider";
import { LoadingSpinner } from "components/primitives";
import { observer } from "mobx-react";
import { getSubscription } from "api/subscription";

export const UserProvider = observer(({ children }: AuthProviderProps) => {
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, user, error } = useAuth0();

  useEffect(() => {
    if (error) console.dir(error);
  }, [error]);

  if (!isAuthenticated && !isLoading) loginWithRedirect();

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently({ audience: auth0Audience, timeoutInSecond: 10 });
      userStore.setUserDetails(accessToken, user);

      // Check the subscription, if it's not premium redirect to /manage-subscriptions.
      // We can perform API calls like getSubscription() here because the access token
      // has been set above.
      if (window.location.pathname !== '/manage-subscription') {
        try {
          const response = await getSubscription();
          const subscription = response.data;

          if (subscription.level !== "Premium") {
            window.location.href = '/manage-subscription';
          }
        } catch(e) {
          console.log("Error getting subscription", e);
        }
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, user]);

  return <>{userStore.accessToken ? children : <LoadingSpinner />}</>;
});
