import React, { useState, useEffect } from "react";
import { LinkButton, Subheading } from "components/primitives";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";
import { observer } from "mobx-react";
import { ActivityVideo } from "../Video";
import Upload from "@material-ui/icons/CloudUpload";
import { userStore } from "plumbing/auth";

type ActivityVideosProps = {
  activity: ActivityModel;
};

export const ActivityVideos = observer(({ activity }: ActivityVideosProps) => {
  const [videoSrc, setVideoSrc] = useState("");
  const [drill, setDrill] = useState("");
  const [selectedDrill, setSelectedDrill] = useState("");

  useEffect(() => {
    if (!selectedDrill) {
      if (activity.drills.length) setSelectedDrill(activity.drills.find((d) => d.drillName === "Figure 8")?.drillName || activity.drills[0].drillName);
      else {
        setVideoSrc("");
        setDrill("");
      }
      return;
    }

    const drill = activity.drills.find((d) => d.drillName === selectedDrill);
    if (!drill) {
      setVideoSrc("");
      setDrill("");
      return;
    }

    setVideoSrc(drill.videoUrl || "");
    setDrill(drill.drillName);
  }, [activity.drills, selectedDrill]);

  const drillsWithVideo = activity.drills.filter((d) => !!d.videoUrl);
  const canUploadActivityVideos =
    activity.videoEncodingStatus !== "Complete" && activity.videoEncodingStatus !== "InProgress" && (userStore.isAdmin || userStore.isCoach || activity.isOwnActivity);
  return (
    <>
      <Header>
        <Subheading>Video</Subheading>

        {drillsWithVideo.length > 1 && (
          <Select
            value={selectedDrill}
            onChange={(event) => {
              setSelectedDrill(event.target.value as string);
            }}
          >
            {drillsWithVideo.map((drill) => (
              <MenuItem key={drill.drillName} value={drill.drillName}>
                {drill.drillName}
              </MenuItem>
            ))}
          </Select>
        )}
      </Header>
      <ActivityVideo videoSrc={videoSrc} activity={activity} drillName={drill} />
      {canUploadActivityVideos && (
        <div style={{ marginTop: "20px" }}>
          <LinkButton to={`/activities/${activity.id}/upload-videos`} startIcon={<Upload />}>
            Upload Video{activity.drills.length > 1 ? "s" : ""}
          </LinkButton>
        </div>
      )}
    </>
  );
});

const Header = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing * 4}px;
  > *:first-child {
    flex: 1;
  }
  align-items: center;
`;
