import React, { useEffect } from "react";
import { ensureRiderIndentityExists } from "api/rider";
import { useApiData } from "plumbing/api";
import { LoadingPanel } from "components/primitives";

let hasChecked = false;

export const EnsureRiderIdentityExists = ({ children }: { children: React.ReactNode | React.ReactNodeArray }) => {
  const { loadingState } = useApiData(ensureRiderIndentityExists, undefined, undefined, !hasChecked);

  useEffect(() => {
    if (loadingState.hasLoaded) hasChecked = true;
  }, [loadingState.hasLoaded]);

  if (hasChecked) return <>{children}</>;

  return <LoadingPanel loadingState={loadingState}>{children}</LoadingPanel>;
};
