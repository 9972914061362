import axios from "axios";
import { apiConfiguration } from "plumbing/api";
import { serialize } from "object-to-formdata";

export const createChallenge = (request: CreateChallengeRequest) => {
  const url = `${apiConfiguration.apiUrl}/challenges`;
  const formData = serialize(request);

  return axios.post<ChallengeModel>(url, formData);
};
