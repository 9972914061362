import React from "react";
import { LoadingSpinner } from "components/primitives";
import styled, { css } from "styled-components";
import { Link } from "@material-ui/core";

export interface LoadingState {
  isLoading: boolean;
  hasError: boolean;
  fetchData: () => void;
  hasLoaded?: boolean;
}

interface LoadingPanelProps {
  loadingState: LoadingState | LoadingState[];
  errorMessage?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const LoadingPanel = ({ errorMessage, loadingState, className, children }: LoadingPanelProps) => {
  let combinedLoadingState: LoadingState;
  if ((loadingState as LoadingState[]).length) {
    const loadingStateList = loadingState as LoadingState[];
    combinedLoadingState = {
      isLoading: loadingStateList.some((ls) => ls.isLoading),
      hasError: loadingStateList.some((ls) => ls.hasError),
      hasLoaded: loadingStateList.every((ls) => ls.hasLoaded === true),
      fetchData: () => {
        loadingStateList.forEach((ls) => ls.fetchData());
      },
    };
  } else {
    combinedLoadingState = loadingState as LoadingState;
  }
  const { isLoading, hasError, fetchData: retry, hasLoaded } = combinedLoadingState;

  const handleRetry = () => {
    retry!();
  };

  const showChildren = (!isLoading && !hasError) || hasLoaded;

  return (
    <LoadingPanelWrapper isLoading={isLoading} className={className}>
      {isLoading && (
        <>
          <LoadingOverlay />
          <LoadingSpinnerWrapper>
            <LoadingSpinner />
          </LoadingSpinnerWrapper>
        </>
      )}
      {hasError && (
        <div>
          {errorMessage ? errorMessage : "Unable to load data"}
          &nbsp;
          {retry !== undefined && (
            <span>
              <Link href="#" onClick={handleRetry}>
                Try Again
              </Link>
            </span>
          )}
        </div>
      )}
      {showChildren && children}
    </LoadingPanelWrapper>
  );
};

const LoadingPanelWrapper = styled.div<{ isLoading?: boolean }>`
  ${(props) =>
    props.isLoading &&
    css`
      min-height: ${props.theme.spacing * 2 + 40}px;
    `}
  position: relative;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  z-index: 99;
`;

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  z-index: 100;
  padding: ${(props) => props.theme.spacing}px;
`;
