import React from 'react';
import {Subheading} from 'components/primitives';
import styled from 'styled-components';
import {LabelGauge} from 'components/primitives/Gauges';
import {drillNameComparer, drillNameDisplay} from 'plumbing/sorting';

type ActivityDrillScoresProps = {
  activity: ActivityModel;
};

export const ActivityDrillScores = ({activity}: ActivityDrillScoresProps) => {
  return (
    <>
      <Subheading gutterBottom>Drill Scores</Subheading>
      <div>
        <Scores>
          {activity.drills
            .filter(a => drillNameDisplay(a.drillName))
            .sort((a, b) => drillNameComparer(a.drillName, b.drillName))
            .map((drill) => (
              <LabelGauge key={drill.drillName} value={drill.score} label={drill.drillName}/>
            ))}
        </Scores>
      </div>
    </>
  );
};

const Scores = styled.div`
  display: grid;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: ${(props) => props.theme.spacing * 4}px;
  align-items: start;

  > * {
    flex-grow: 1;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values['md']}px) {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values['md']}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values['sm']}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
