import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { UserProvider } from "./UserProvider";
import { AuthProviderProps } from "./AuthProvider";
import { userStore } from "./userStore";
import { LoadingSpinner } from "components/primitives";
import { observer } from "mobx-react";
import { EnsureRiderIdentityExists } from ".";
import { EnsureEmailVerified } from "./EnsureEmailVerified";

export type ProtectedRouteProperties = {
  requiredPermissions?: ("Admin" | "Coach")[];
} & (AuthProviderProps | RouteProps);

export const ProtectedRoute = observer(({ children, requiredPermissions: requiredPermission, ...props }: ProtectedRouteProperties) => {
  if (!!requiredPermission && userStore.decodedToken?.permissions?.every((p) => requiredPermission?.every((r) => p !== r))) return <Redirect to="/" />;
  return (
    <Route {...props}>
      <UserProvider>
        {userStore.accessToken ? (
          <EnsureEmailVerified>
            <EnsureRiderIdentityExists>{children}</EnsureRiderIdentityExists>
          </EnsureEmailVerified>
        ) : (
          <LoadingSpinner />
        )}
      </UserProvider>
    </Route>
  );
});
