import React, { useRef, useImperativeHandle } from "react";
import { TextField, FormHelperText, FormControl } from "@material-ui/core";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { Controller, useFormContext } from "react-hook-form";

export const StripeInput = ({ component: Component, inputRef, ...props }: any) => {
  const elementRef: any = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return <Component onReady={(element: any) => (elementRef.current = element)} {...props} />;
};

const elementOptions = { style: { base: { fontFamily: "Work Sans", fontSize: "16px", color: "#fff", fontWeight: "300" } } };

const CardNumberComponent = (componentProps: any) => {
  const { formState } = useFormContext();
  return <CardNumberElement {...componentProps} options={{ ...elementOptions, disabled: formState.isSubmitting }} />;
};

export const CardNumberField = () => {
  const { errors, control, formState } = useFormContext();
  return (
    <FormControl fullWidth error={errors["cardNumber"]?.message !== undefined}>
      <Controller
        control={control}
        name="cardNumber"
        defaultValue=""
        render={(props) => (
          <TextField
            label="Credit Card Number"
            required
            fullWidth
            onChange={props.onChange}
            value={props.value}
            disabled={formState.isSubmitting}
            error={errors["cardNumber"]?.message !== undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberComponent,
              },
            }}
          />
        )}
      />

      <FormHelperText>{errors["cardNumber"]?.message}</FormHelperText>
    </FormControl>
  );
};
const CardCvcComponent = (props: any) => {
  const { formState } = useFormContext();
  return <CardCvcElement {...props} options={{ ...elementOptions, disabled: formState.isSubmitting }} />;
};
export const CardCvcField = () => {
  const { errors, control } = useFormContext();
  return (
    <FormControl fullWidth error={errors["cvc"]?.message !== undefined}>
      <Controller
        control={control}
        name="cvc"
        defaultValue=""
        render={(props) => (
          <TextField
            label="CVC"
            required
            fullWidth
            onChange={props.onChange}
            value={props.value}
            error={errors["cvc"]?.message !== undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcComponent,
              },
            }}
          />
        )}
      />
      <FormHelperText>{errors["cvc"]?.message}</FormHelperText>
    </FormControl>
  );
};
const CardExpiryComponent = (props: any) => {
  const { formState } = useFormContext();
  return <CardExpiryElement {...props} options={{ ...elementOptions, disabled: formState.isSubmitting }} />;
};
export const CardExpiryField = () => {
  const { errors, control } = useFormContext();
  return (
    <FormControl fullWidth error={errors["cardExpiry"]?.message !== undefined}>
      <Controller
        control={control}
        name="cardExpiry"
        defaultValue=""
        render={(props) => (
          <TextField
            label="Expiry"
            required
            fullWidth
            onChange={props.onChange}
            value={props.value}
            error={errors["cardExpiry"]?.message !== undefined}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryComponent,
              },
            }}
          />
        )}
      />
      <FormHelperText>{errors["cardExpiry"]?.message}</FormHelperText>
    </FormControl>
  );
};
