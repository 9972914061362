import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const auth0ClientId: string = process.env.REACT_APP_Auth0ClientId!;
const auth0Domain = process.env.REACT_APP_Auth0Domain!;
export const auth0Audience = process.env.REACT_APP_Auth0Audience!;

export type AuthProviderProps = { children: React.ReactNode | React.ReactNodeArray };

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      audience={auth0Audience}
      scope="profile"
    >
      {children}
    </Auth0Provider>
  );
};
