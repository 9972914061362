import React from "react";
import { Subheading, VideoBlock, Image } from "components/primitives";
import styled from "styled-components";

export type ContentPageSectionProps = {
  title?: string;
  children: React.ReactNode;
} & (WithVideo | WithImage);

type WithVideo = {
  videoUrl: string;
};

type WithImage = {
  imageUrl: string;
  imageAlt: string;
};

export const ContentPageSection = ({ title, children, ...props }: ContentPageSectionProps) => {
  const videoProps = props as WithVideo;
  const imageProps = props as WithImage;

  return (
    <ContentPageSectionContainer>
      <InstructionHeading>{title && <Subheading gutterBottom>{title}</Subheading>}</InstructionHeading>
      <InstructionText>{children}</InstructionText>
      <InstructionMedia>
        {(props as any).videoUrl ? (
          <VideoBlock src={videoProps.videoUrl} />
        ) : (
          <Image src={imageProps.imageUrl} alt={imageProps.imageAlt} withLightbox />
        )}
      </InstructionMedia>
    </ContentPageSectionContainer>
  );
};

export const ContentPageSectionContainer = styled.div`
  display: grid;
  grid-template:
    "media header"
    "media content";
  grid-template-columns: 2fr 3fr;
  column-gap: ${(props) => props.theme.spacing * 4}px;
  grid-template-rows: auto 1fr;

  :not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing * 4}px;
  }

  :not(:first-child) {
    margin-top: ${(props) => props.theme.spacing * 4}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "header"
      "media"
      "content";
  }
`;

export const InstructionHeading = styled.div`
  grid-area: header;
`;

export const InstructionText = styled.div`
  grid-area: content;
  p:not(:first-child) {
    padding-top: 8px;
  }
`;

export const InstructionMedia = styled.div`
  grid-area: media;
  width: 100%;
  height: 100%;
  line-height: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-bottom: ${(props) => props.theme.spacing * 2}px;
  }

  img {
    width: 100%;
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
      height: 250px;
    }
  }
`;
