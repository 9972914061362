import React from "react";
import { Link as MuiLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

type InternalLinkProps = {
  to: string;
};

type ExternalLinkProps = {
  href: string;
};

type LinkProps = {
  children: React.ReactNode;
  to?: string;
  href?: string;
  className?: string;
  style?: React.CSSProperties;
} & (InternalLinkProps | ExternalLinkProps);

export const Link = ({ to, href, children, className, style }: LinkProps) => {
  if (to)
    return (
      <MuiLink component={RouterLink} to={to} className={className} style={style}>
        {children}
      </MuiLink>
    );
  return (
    <MuiLink href={href} target="_blank" className={className} style={style}>
      {children}
    </MuiLink>
  );
};
