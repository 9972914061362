import React from "react";
import { PageGrid, RiderChallenges } from "components/ui";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { userStore } from "plumbing/auth";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const Challenges = observer(() => {
  return (
    <>
      <Helmet>
        <title> Digital Academy - Challenges</title>
      </Helmet>
      <PageGrid>
        {userStore.isAdmin && (
          <Button component={Link} to="/challenges/create" variant="contained" color="primary" style={{ marginBottom: "16px" }}>
            Create Challenge
          </Button>
        )}
        <RiderChallenges />
      </PageGrid>
    </>
  );
});
