import React from "react";
import { PageGrid, ContentPageSection, Accordion, AccordionSummaryWithIcon } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, Subheading, BodyText, Link } from "components/primitives";
import { AccordionDetails, Divider, Typography } from "@material-ui/core";
import cameraSetupImage from "assets/instructions/Camera Setup.jpg";
import GoProQuik from "assets/instructions/GoProQuikTrim.jpg";
import { DropboxVideoUploadInstructions } from "components/ui/UploadActivity/DropboxVideoUploadInstructions";

export const CameraSetup = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - Camera Set-Up & Upload</title>
      </Helmet>
      <PageGrid>
        <Heading>Camera Set-Up & Upload</Heading>
        <div>
          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Mounting Instructions</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Mounting Instructions" imageUrl={cameraSetupImage} imageAlt="Camera Mount Image">
                  <BodyText>
                    The GoPro must be securely mounted to a static portion of the motorcycle, preferably on the rear seat - we want to see your body position. Alternatively if you have a short seat, mount it on the tank. The camera must have clear sky access, and cannot be mounted to your body, or any part connected to the steering. With the bike standing upright the camera should be level (for accurate lean angle) and facing forwards or backwards, within a few degrees.
              </BodyText>

                  <Divider style={{ margin: "24px 0 16px 0" }} />

                  <Subheading gutterBottom>Camera Settings</Subheading>
                  <BodyText>
                    Camera must be a GPS enabled GoPro. See the technical details of your camera to check if it is suitable. The camera must be
                    set to video mode with the GPS activated. If the GPS is not activated, the data cannot be processed. The video resolution
                    is recommended to be 1080p at 30fps, with the wide angle view selected. Be sure to have the latest firmware installed.
              </BodyText>

                  <Divider style={{ margin: "24px 0 16px 0" }} />

                  <Subheading gutterBottom>Camera Usage</Subheading>
                  <BodyText>
                    To ensure accuracy with the video processing, the activity should begin once the GPS symbol is lit, and should
                    end as soon as the bike comes to a stop. The GPS symbol may take up to one minute to activate. streetSKILLS101 - There must be a separate video file for each of the drills.
              </BodyText>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Video Upload</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Video Upload" imageUrl={GoProQuik} imageAlt="GoPro Quik">
                  <BodyText>
                    Upload your video via the upload page. Once your details are filled out attach your GoPro video, this should have the file extension of .mp4. Selecting "Data only upload" will extract and upload the data from the video without uploading the video itself.
              </BodyText> 
                  <Divider style={{ margin: "24px 0 16px 0" }} />
                  <Subheading gutterBottom>Video Trimming</Subheading>
                  <BodyText>
                    If you want to reduce the upload time of your video, it can be trimmed using only the GoPro Quik app on your mobile device. Using any other app or editing program will remove the data from the video, making it unusable. Wirelessly connect your GoPro to the GoPro Quik app and trim your video to 10 seconds before and after the start/finish line of your best lap.
                  </BodyText>
                  <BodyText>
                    App link: <Link href="https://gopro.com/en/us/shop/quik-app-video-photo-editor">https://gopro.com/en/us/shop/quik-app-video-photo-editor</Link>
                  </BodyText>

                  <Divider style={{ margin: "24px 0 16px 0" }} />
                  <Subheading gutterBottom>Unlisted Tracks</Subheading>
                  <DropboxVideoUploadInstructions />
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageGrid>
    </>
  );
};

      
