import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import { tileUrl } from "plumbing/maps";
import styled from "styled-components";
import { max, min } from "lodash";

export const ActivityMap = ({ activity }: { activity: ActivityFeedItemModel }) => {
  const [isMapReady, setIsMapReady] = useState(false);
  const [bounds, setBounds] = useState<LatLngTuple[] | undefined>();

  const [gpsPoints, setGpsPoints] = useState<LatLngTuple[]>([]);

  useEffect(() => {
    if (activity.gpsPath) {
      const path = JSON.parse(activity.gpsPath) as { Name: string; DataPoints: { X: number; Y: number; Label: string }[] }[];
      if (path.length) {
        const points = path[0].DataPoints
          .filter(dp => !isNaN(dp.X) && !isNaN(dp.Y))
          .map((dp) => [dp.Y, dp.X] as LatLngTuple);
  
        setGpsPoints(points);

        const lats = points.map((p) => p[0]);
        const longs = points.map((p) => p[1]);
        const maxLat = max(lats) || 0;
        const minLat = min(lats) || 0;
        const maxLong = max(longs) || 0;
        const minLong = min(longs) || 0;
        setBounds([
          [minLat, minLong],
          [maxLat, maxLong],
        ]);
      }
    }
    setIsMapReady(true);
  }, [activity]);

  return (
    <>
      {isMapReady && (
        <ActivityMapContainer
          center={!bounds ? [activity.location.lat, activity.location.long] : undefined}
          zoom={16}
          maxZoom={20}
          attributionControl={false}
          zoomControl={false}
          scrollWheelZoom={false}
          bounds={bounds}
          dragging={false}
        >
          <TileLayer url={tileUrl} maxNativeZoom={18} />
          {!bounds && <Marker position={[activity.location.lat, activity.location.long]} />}

          {!!gpsPoints.length && <Polyline positions={gpsPoints} smoothFactor={0.5} pathOptions={{ weight: 1.8 }} />}
        </ActivityMapContainer>
      )}
    </>
  );
};

const ActivityMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
`;
