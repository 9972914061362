import React from "react";
import { PageGrid, ContentPageSection, InstructionMedia } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, Subheading, BodyText } from "components/primitives";
import { Card, CardContent, Divider } from "@material-ui/core";

import briefingPhoto from "assets/irish-briefing-smsp-gp.jpg";
import qaPhoto from "assets/jack-miller-qa-wide.jpg";
import femalePhoto from "assets/streetSKILLS-female-coach-sc-2.jpg";

import styled from "styled-components";

export const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - About Us</title>
      </Helmet>
      <PageGrid>
        <Heading>About Us</Heading>
        <AboutUsCard>
          <CardContent>
            <ContentPageSection title="" imageUrl={femalePhoto} imageAlt="Mark riding a bike">
              <StyledBodyText>
                motoDNA is a digital motorcycle academy that enables riders to improve skills with their own online coach. Developed in
                conjunction with Yamaha Motor Co and Riders Academy at Sydney Motorsport Park innovation hub, our technology uses action
                camera sensors to measure riders behaviour compared to other riders. Next our algorithms grade the riders skill level and
                then advise the rider how to improve their riding.
              </StyledBodyText>
            </ContentPageSection>

            <StyledDivider />

            <ContentPageSection title="Our Mission" imageUrl={qaPhoto} imageAlt="Mark riding a bike">
              <StyledBodyText>
                Innovation is changing the world, and startups are becoming not only an R&amp;D engine for large corporations, but also for
                global challenges such as climate change.
              </StyledBodyText>

              <StyledBodyText>motoDNA’s mission is global motorcycle rider safety - data driven.</StyledBodyText>
              <StyledBodyText>
                motoDNA’s Vision is ambitious and daring as we urgently aim to empower our motorcycle community TOWARDS ZERO.
              </StyledBodyText>

              <ul>
                <li>Zero crashes and fatalities</li>
                <li>Empower riders to take control of motorcycling</li>
                <li>Grow the motorcycle industry</li>
                <li>Grow motorcycle sport</li>
              </ul>
            </ContentPageSection>

            <StyledDivider />
            <ContentPageSection title="Why, How and What" imageUrl={briefingPhoto} imageAlt="Mark giving a safety briefing">
              <StyledBodyText>
                Our WHY is to Turn Ordinary People Into Extraordinary Riders to save lives on the road. This is our sense of purpose and WHY
                we exist.
              </StyledBodyText>
              <StyledBodyText>
                Our HOW is Motorcycles Play Here. Science shows that if learning is fun, positive side effects like dopamine, endorphins,
                oxygen and engagement increase learning exponentially as we engage with serious games psychology to keep our tech sticky.
              </StyledBodyText>
              <StyledBodyText>
                Our WHAT is Data Driven, not opinion driven, as we integrate technology into our products, we can create a paradigm shift in
                safety &amp; performance at scale while knowing in our heart that we are getting to the root cause of the problem.
              </StyledBodyText>
            </ContentPageSection>
          </CardContent>
        </AboutUsCard>

        <FoundersStoryCard>
          <CardContent>
            <Subheading gutterBottom>Founders story</Subheading>
            <StyledBodyText>motoDNA was founded in Australia by Irish Expat Mark McVeigh during 2000.</StyledBodyText>
            <StyledBodyText>
              Mark’s motorcycle journey started at a young age, due to having motorcycles in his DNA (motoDNA), but despite an annual plea,
              a motorcycle never found its way under the Christmas tree.
            </StyledBodyText>
            <StyledBodyText>Eventually the young teenager bought his first bike under his own steam, but it didn’t go well.</StyledBodyText>
            <StyledBodyText>
              Mark’s Father had passed away at a young age and the following few years were grim with Mark’s 2 best mates killed and many
              close calls in Northern Ireland's troubled tight and twisty roads.
            </StyledBodyText>
            <StyledBodyText>
              Between his father, best mates and Irish Road Racing mates, Mark lost 20 close friends to motorcycles in his early years.
            </StyledBodyText>
            <StyledBodyText>Faced with a stark choice, Mark decided to go racing.</StyledBodyText>
            <StyledBodyText>This decision ultimately saved Mark's life and laid out his destiny.</StyledBodyText>
            <StyledBodyText>
              Mark proudly went on to finish runner up in the Irish 250GP Championship before representing Ireland at international level in
              250 European championships alongside many stars of the future including MotoGP winners Simon Crafer, Jeremy McWilliams, Regis
              Laconi and World Champion Oliver Jacque until a serious injury curtailed his career.
            </StyledBodyText>
            <StyledBodyText>
              As an Engineer, a career in MotoGP then beckoned in the glorious 500 Grand Prix era with Mark going on to work directly with
              MotoGP factories as a consultant in the 4-stroke era.
            </StyledBodyText>
            <StyledBodyText>
              Post MotoGP and now an engine research specialist, Mark enjoyed time in England with EMC genius Dr Joe Ehrlich and Sir Jack
              Brabham, ending up down under in Australia a few years later to work with Jack Brabham Engines and then Supercars, winning the
              drivers, team and Bathurst titles.
            </StyledBodyText>
            <StyledBodyText>
              Bikes were never far away though as Mark continued racing on tracks, riding on the road and then in the bush culminating with
              a 10k transcontinental ride across the Australian desert.
            </StyledBodyText>
            <StyledBodyText>
              However, throughout Mark's career he often wondered why so many road riders crashed and were killed, he just accepted that was
              part of the game.
            </StyledBodyText>
            <StyledBodyText>
              It was only when Mark was studying Psychology, he came across Dunning Krugers Nobel prize winning study which provided
              evidence of human’s cognitive bias of illusory superiority.
            </StyledBodyText>
            <StyledBodyText>
              This new knowledge; fused with Mark’s first hand witness of poor road rider behaviour and low skills at the motoDNA Riders
              Academy was another step towards Mark's destiny.
            </StyledBodyText>
            <StyledBodyText>The lightbulb moment truly came during a crisis of purpose.</StyledBodyText>
            <StyledBodyText>When trying to figure out his place in the world, Mark went deeper into psychology.</StyledBodyText>
            <StyledBodyText>
              After working out his need for Autonomy, Mastery and Purpose, Mark realized he was an expert in his field and it was his duty
              to use this unique fusion of holistic knowledge and experience to save motorcycle riders lives.
            </StyledBodyText>
            <StyledBodyText>
              Mark quickly founded motoDNA rider’s academy in 2000 and then motoDNA Technology in 2010 and has built a team of Coaches,
              Engineers and Investors to scale motoDNA’s unique expertise to save lives on the road Towards Zero (accidents) around the
              world.
            </StyledBodyText>
            <StyledBodyText>
              This unique experience as a competitive Racer, Engineer and more recently with Psychology, together with the advent of
              technology, has led motoDNA to unlock the mysteries of riding motorcycles.
            </StyledBodyText>
            <StyledBodyText>
              As motoDNA scales our technology, Mark is excited about the huge sense of purpose around creating a paradigm shift in
              motorcycle safety and the amazing tribe that are now working with us on this journey.
            </StyledBodyText>
            <StyledBodyText>Come join us …</StyledBodyText>
          </CardContent>
        </FoundersStoryCard>
      </PageGrid>
    </>
  );
};

const StyledDivider = styled(Divider)`
  margin: ${(props) => props.theme.spacing * 4}px 0;
`;

const FoundersStoryCard = styled(Card)`
  margin-top: ${(props) => props.theme.spacing * 4}px;
`;

const StyledBodyText = styled(BodyText)`
  margin: ${(props) => props.theme.spacing * 3}px 0;
`;

const AboutUsCard = styled(Card)`
  ${InstructionMedia} img {
    height: 320px;
    object-fit: cover;
    display: block;
  }
`;
