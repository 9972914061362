import { FormHelperText, Hidden, InputAdornment, ListSubheader, MenuItem } from "@material-ui/core";
import { uploadFormSchemaFactory } from "./schema";
import {
  AutocompleteFieldFormItem,
  CheckboxFieldFormItem,
  Form,
  FormItem,
  LoadingPanel,
  SelectFieldFormItem,
  SimpleCard,
  Subheading,
  SubmitButtonFormItem,
  TextFieldFormItem,
} from "components/primitives";
import React, { useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { useApiData } from "plumbing/api";
import { getActivityReferenceData } from "api/activityReferenceData";
import { groupBy, map } from "lodash";
import { VideoFormItems } from "./VideoFormItems";
import { RiderDetailsFormSection } from "./RiderDetailsFormSection";
import { userStore } from "plumbing/auth";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { DropboxVideoUploadInstructions } from "./DropboxVideoUploadInstructions";

export type UploadActivityFormProps = {
  onSubmit: (data: UploadActivityFormData) => Promise<any>;
};
export const UploadActivityForm = ({ onSubmit }: UploadActivityFormProps) => {
  const isAdmin = userStore.isAdmin;
  const isCoach = userStore.isCoach;
  const { data, loadingState } = useApiData(getActivityReferenceData);
  const availableChallenges = (activityType: string) => {
    const challenges = (userStore.isAdmin ? data?.riderChallenges.concat(data?.otherChallenges) : data?.riderChallenges) || [];
    return challenges.filter((c) => c.activitySkillType === data?.activityTypes.find((at) => at.name === activityType)?.skillType);
  };

  const availableGroups = (activityType: string) => {
    const groups = (userStore.isAdmin ? data?.riderGroups.concat(data?.otherGroups) : data?.riderGroups) || [];
    return groups.filter((g) => g.activitySkillType === data?.activityTypes.find((at) => at.name === activityType)?.skillType);
  };

  const [formValues, setFormValues] = useState<UploadActivityFormData | undefined>(undefined);
  const handleFormDataChange = (formData: UploadActivityFormData, formMethods: UseFormMethods<UploadActivityFormData>) => {
    if (formValues && formData?.activityType !== formValues.activityType) {
      const selectedGroups = availableGroups(formData?.activityType).filter((g) => data?.riderGroups?.some((rg) => rg === g));
      if (!formMethods.getValues().hasOwnProperty("groups")) formMethods.register("groups");
      formMethods.setValue("groups", selectedGroups);

      if (!formMethods.getValues().hasOwnProperty("challenges")) formMethods.register("challenges");
      const selectedChallenges = availableChallenges(formData?.activityType).filter((c) => data?.riderChallenges?.some((rc) => c === rc));
      formMethods.setValue("challenges", selectedChallenges);
    }

    setFormValues(formData);
  };
  // Prefill for local testing...
  // defaultValues={{
  //   activityType: "trackSKILLS 101",
  //   bikeCapacity: "1000",
  //   bikeMake: "Honda",
  //   bikeModel: "CBR",
  //   hasConfirmed: true,
  //   riderFirstName: "Isaac",
  //   riderLastName: "Lamb",
  //   riderEmail: "isaac@fake.email",
  // }}
  return (
    <LoadingPanel loadingState={loadingState}>
      <Form onSubmit={onSubmit} schema={uploadFormSchemaFactory(data?.activityTypes)} onChange={handleFormDataChange}>
        {(isAdmin || isCoach) && <RiderDetailsFormSection />}
        <SelectFieldFormItem label="Activity" name="activityType" sm={4} autoFocus={!isCoach && !isAdmin}>
          {map(groupBy(data?.activityTypes, "skillType"), (skillTypeActivityTypes, skillType) => {
            return [
              <ListSubheader>{skillType}</ListSubheader>,
              skillTypeActivityTypes.map((activityType) => (
                <MenuItem key={activityType.name} value={activityType.name}>
                  {activityType.name}
                </MenuItem>
              )),
            ];
          })}
          <MenuItem value="Other">Other</MenuItem>
        </SelectFieldFormItem>
        <FormItem sm={8}>
          <FormHelperText style={{ display: "flex", alignItems: "center", marginTop: 0 }}>
            <InfoOutlinedIcon fontSize="small" style={{ marginRight: "6px", fontSize: "1.5em" }} />
            We are adding new tracks all the time, if your track is not listed please select 'Other'
          </FormHelperText>
        </FormItem>

        {formValues?.activityType === "Other" && (
          <FormItem xs={12}>
            <SimpleCard elevation={6}>
              <Subheading gutterBottom>Video Upload</Subheading>
              {DropboxVideoUploadInstructions()}
            </SimpleCard>
          </FormItem>
        )}

        <SelectFieldFormItem label="Bike Make" name="bikeMake" sm={4} disabled={formValues?.activityType === "Other"}>
          {data?.bikeMakes.map((bikeMake) => (
            <MenuItem key={bikeMake.name} value={bikeMake.name}>
              {bikeMake.name}
            </MenuItem>
          ))}
        </SelectFieldFormItem>

        <TextFieldFormItem label="Bike Model" name="bikeModel" sm={4} disabled={formValues?.activityType === "Other"} />

        <TextFieldFormItem
          label="Bike Capacity"
          name="bikeCapacity"
          endAdornment={<InputAdornment position="end">cc</InputAdornment>}
          sm={4}
          disabled={formValues?.activityType === "Other"}
        />

        <VideoFormItems activityTypes={data?.activityTypes || []} />

        {data && availableGroups(formValues?.activityType || "").length > 0 && (
          <>
            <AutocompleteFieldFormItem
              label="Groups"
              name="groups"
              options={availableGroups(formValues?.activityType || "")}
              multiple
              getOptionLabel={(option) => option.name}
              sm={8}
              disabled={formValues?.activityType === "Other"}
            />
            <Hidden only="xs">
              <FormItem sm={4} />
            </Hidden>
          </>
        )}

        {data && availableChallenges(formValues?.activityType || "").length > 0 && (
          <>
            <AutocompleteFieldFormItem
              sm={8}
              label="Challenges"
              name="challenges"
              options={availableChallenges(formValues?.activityType || "")}
              multiple
              getOptionLabel={(option) => option.name}
              disabled={formValues?.activityType === "Other"}
            />
            <Hidden only="xs">
              <FormItem sm={4} />
            </Hidden>
          </>
        )}

        <CheckboxFieldFormItem
          label="I confirm that the uploaded data is mine and that it is a genuine attempt"
          name="hasConfirmed"
          disabled={formValues?.activityType === "Other"}
        />
        <SubmitButtonFormItem disabled={formValues?.activityType === "Other"}>Submit</SubmitButtonFormItem>
      </Form>
    </LoadingPanel>
  );
};
