import axios from "axios";
import { apiConfiguration } from "plumbing/api";

type GenerateMultipartFileUploadUrl = {
  fileUploadUrl: string
};

export const generateMultipartFileUploadUrl = async (fileName: string, uploadId: string, partNumber: number) => {
  const url = `${apiConfiguration.apiUrl}/multipart-file-upload/generate-upload-url`;
  const response = await axios.post<GenerateMultipartFileUploadUrl>(url, {
    fileName,
    contentType: 'application/octet-stream',
    uploadId,
    partNumber,
  });
  return {
    fileUploadUrl: response.data.fileUploadUrl
  }
}
