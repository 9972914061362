import axios, { AxiosRequestConfig } from "axios";
import { userStore } from "plumbing/auth";

axios.interceptors.request.use((axiosConfig: AxiosRequestConfig) => {
  if (axiosConfig.url && axiosConfig.url.startsWith(process.env.REACT_APP_ApiUrl!)) {

    if (userStore.accessToken) {
      axiosConfig.headers.Authorization = `Bearer ${userStore.accessToken}`;
    }

  }

  return axiosConfig;
});
