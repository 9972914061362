import React from "react";

export const LeanAngleChart = ({ leanAngle }: { leanAngle: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="100%">
      <circle cx="50" cy="50" r="48" style={{ fill: "transparent", stroke: "#f3bf30" }} />
      <path
        d="M 50 50 L 63.68080573302675 12.412295168563666 A 40 40 0 0 0 36.319194266973255 12.41229516856366 L 50 50"
        fill="#f3bf30"
        className="needle"
        transform-origin="50 50"
        style={{ transform: `rotate(${leanAngle}deg)`, transition: "transform 0.05s linear" }}
      />
      <g className="ticks">
        <path
          d="M 82.76608177155967 27.056942545958158 A 40 40 0 0 0 17.233918228440338 27.056942545958144"
          stroke="#fff"
          fill="transparent"
        />
        <path d="M 82.76608177155967 27.056942545958158 L 72.93625724009178 33.939859782170714" stroke="#fff" fill="transparent" />
        <path d="M 17.233918228440338 27.056942545958144 L 27.063742759908237 33.9398597821707" stroke="#fff" fill="transparent" />
        <path d="M 62.58179024606456 12.030294257078644 L 58.80725317224519 23.42120597995505" stroke="#fff" />
        <path d="M 37.41820975393545 12.030294257078644 L 41.19274682775482 23.42120597995505" stroke="#fff" />
      </g>
      <path d="M 10 50 L 90 50" stroke="#fff" />

      <text
        x="50"
        y="67"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="central"
        fontSize="22px"
        fontFamily="Work Sans"
        fill="#fff"
        fontWeight="500"
      >
        {leanAngle.toFixed(1)}°
      </text>
      <g className="label-rect">
        <rect x="0" y="84" width="100" height="16" style={{ fill: "#f3bf30" }} />
        <text
          x="50"
          y="92"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="central"
          fontSize="10px"
          fontFamily="Work Sans"
          fontWeight="500"
        >
          LEAN ANGLE
        </text>
      </g>
    </svg>
  );
};
