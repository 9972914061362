import { CheckboxFieldFormItem, Form, HiddenField, LoadingPanel, SubmitButtonFormItem } from "components/primitives";
import React from "react";
import { useApiData } from "plumbing/api";
import { getActivityReferenceData } from "api/activityReferenceData";
import { VideoFormItems } from "../UploadActivity/VideoFormItems";
import { ActivityDetailsHeader } from "../ActivityDashboard/ActivityDetailsHeader";
import { uploadVideosFormSchemaFactory } from "./schema";

export type UploadActivityVideosFormProps = {
  activity: ActivityModel;
  onSubmit: (data: UploadActivityVideosFormData) => Promise<any>;
};
export const UploadActivityVideosForm = ({ activity, onSubmit }: UploadActivityVideosFormProps) => {
  const { data, loadingState } = useApiData(getActivityReferenceData);

  return (
    <LoadingPanel loadingState={loadingState}>
      <ActivityDetailsHeader activity={activity} />
      <div style={{ marginTop: "16px" }}>
        <Form onSubmit={onSubmit} schema={uploadVideosFormSchemaFactory(data?.activityTypes)}>
          <HiddenField name="activityType" defaultValue={activity.activityType} />
          <VideoFormItems activityTypes={data?.activityTypes || []} allowDataOnly={false} />
          <CheckboxFieldFormItem label="I confirm that the uploaded data is mine and that it is a genuine attempt" name="hasConfirmed" />
          <SubmitButtonFormItem>Submit</SubmitButtonFormItem>
        </Form>
      </div>
    </LoadingPanel>
  );
};
