import React, { useState, useEffect } from "react";
import { useQuery } from "plumbing/routes";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PageGrid, DateAtTime, ActivityVideo, ComparisonChart, ActivityCompareMap } from "components/ui";
import { LoadingPanel, Subheading, Caption } from "components/primitives";
import { useApiData } from "plumbing/api";
import { getActivityComparison } from "api/activityComparison";
import styled from "styled-components";
import { Card, CardContent, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { getSubscription } from "api/subscription";
import uniq from "lodash/uniq";
import { drillNameComparer, drillNameDisplay, skillNameComparer } from "plumbing/sorting";
import { analytics } from "plumbing/analytics";

const RiderDetails = ({ activity }: { activity: ActivityModel }) => {
  const wetWeather = activity.drills.filter(x => x.wetWeather).length > 0;

  return (
    <>
      <Subheading>
        {activity.riderFirstName} {activity.riderLastName}
      </Subheading>
      <Subheading>{activity.activityType}</Subheading>
      <Caption>
        <DateAtTime time={activity.dateTime} />
      </Caption>
      <br/>
      <Caption>Weather - {wetWeather ? "Wet" : "Dry"}</Caption>
    </>
  );
};

/* To test this, find two of your activities in the database. Make sure they
 * have the same ActivityTypeId (you can just update the ActivityTypeId
 * directly). Then enter the URL
 * http://localhost:3000/compare-activities/activity1Id=123&activity2Id=124
 */

export const CompareActivities = () => {
  const history = useHistory();
  const query = useQuery();
  const activity1Id = query.get("activity1Id");
  const activity2Id = query.get("activity2Id");

  if (!activity1Id || !activity2Id) history.push("/");

  const {
    loadingState: activityComparisonloadingState,
    data: activityComparison,
    fetchData: fetchActivityComparison,
    setData: setActivityComparison,
  } = useApiData(() => getActivityComparison({ activity1Id: activity1Id!, activity2Id: activity2Id! }), undefined, undefined, false);

  const { data: subscription, loadingState: subscriptionLoadingState } = useApiData(getSubscription);

  useEffect(() => {
    if (subscription) {
      analytics.sendEvent({ category: "Activities", action: "Comparison", label: `${activity1Id} vs ${activity2Id}` });
      fetchActivityComparison();
    }
  }, [fetchActivityComparison, setActivityComparison, subscription, activity1Id, activity2Id]);

  const [selectedDrill, setSelectedDrill] = useState<string>("Figure 8");

  useEffect(() => {
    if (activityComparison && activityComparison.activity1.drills.length === 1) {
      setSelectedDrill(activityComparison.activity1.drills[0].drillName);
    }
  }, [activityComparison]);

  const activity1VideoSrc = activityComparison?.activity1.drills.find((d) => d.drillName === selectedDrill)?.videoUrl;
  const activity2VideoSrc = activityComparison?.activity2.drills.find((d) => d.drillName === selectedDrill)?.videoUrl;

  const drills = uniq(
    activityComparison?.activity1.drills
      .concat(activityComparison.activity2.drills)
      .filter(a => drillNameDisplay(a.drillName))
      .sort((a, b) => drillNameComparer(a.drillName, b.drillName))
      .map((d) => d.drillName) || []
  );

  const drillsWithVideo = uniq(
    activityComparison?.activity1.drills
      .concat(activityComparison.activity2.drills)
      .filter((d) => !!d.videoUrl)
      .sort((a, b) => drillNameComparer(a.drillName, b.drillName))
      .map((d) => d.drillName) || []
  );

  const isMapVisible = activityComparison && !!activityComparison.activity1.gpsPath && activityComparison.activity1.gpsPath !== "[]";

  return (
    <>
      <Helmet>
        <title>Digital Academy - Compare Activities</title>
      </Helmet>
      <PageGrid>
        <LoadingPanel loadingState={[activityComparisonloadingState, subscriptionLoadingState]}>
          {subscription && activityComparison && (
            <ActivityCompareContainer map={isMapVisible}>
              <Header>
                <HeaderContent>
                  <Rider1Details>
                    <RiderDetails activity={activityComparison.activity1} />
                  </Rider1Details>
                  <DrillSelect>
                    <FormControl fullWidth>
                      <InputLabel>Drill</InputLabel>
                      <Select
                        style={{ minWidth: "160px" }}
                        value={selectedDrill}
                        onChange={(event) => {
                          setSelectedDrill(event.target.value as string);
                        }}
                      >
                        {drillsWithVideo.map((d) => (
                          <MenuItem key={d} value={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DrillSelect>
                  <Rider2Details>
                    <RiderDetails activity={activityComparison.activity2} />
                  </Rider2Details>
                </HeaderContent>
              </Header>
              <Activity1Video>
                <ActivityVideo videoSrc={activity1VideoSrc} activity={activityComparison.activity1} drillName={selectedDrill} />
              </Activity1Video>
              <Activity2Video>
                <ActivityVideo videoSrc={activity2VideoSrc} activity={activityComparison.activity2} drillName={selectedDrill} />
              </Activity2Video>

              {isMapVisible && (
                <Map>
                  <ActivityCompareMap activity1Path={activityComparison.activity1.gpsPath} activity2Path={activityComparison.activity2.gpsPath} />
                </Map>
              )}

              <Charts>
                <Subheading gutterBottom>Skills</Subheading>
                <ChartsContent>
                  <div>
                    <ComparisonChart
                      aKey={`${activityComparison.activity1.riderFirstName} ${activityComparison.activity1.riderLastName}`}
                      aValue={activityComparison.activity1.score}
                      bKey={`${activityComparison.activity2.riderFirstName} ${activityComparison.activity2.riderLastName}`}
                      bValue={activityComparison.activity2.score}
                      name="Overall"
                      barSize={55}
                    />
                  </div>
                  {activityComparison.activity1.skillScores
                    .sort((a, b) => skillNameComparer(a.skillName, b.skillName))
                    .map((score) => (
                      <div key={score.skillName}>
                        <ComparisonChart
                          aKey={`${activityComparison.activity1.riderFirstName} ${activityComparison.activity1.riderLastName}`}
                          aValue={activityComparison.activity1.skillScores.find((s) => s.skillName === score.skillName)?.score || 0}
                          bKey={`${activityComparison.activity2.riderFirstName} ${activityComparison.activity2.riderLastName}`}
                          bValue={activityComparison.activity2.skillScores.find((s) => s.skillName === score.skillName)?.score || 0}
                          name={score.skillName}
                          barSize={55}
                        />
                      </div>
                    ))}
                </ChartsContent>
              </Charts>
              {activityComparison.activity1.activitySkillType === "Street" && (
                <DrillCharts>
                  <Subheading gutterBottom>Drills</Subheading>
                  <DrillChartsContent>
                    {drills.map((drill) => (
                      <div key={drill}>
                        <ComparisonChart
                          aKey={`${activityComparison.activity1.riderFirstName} ${activityComparison.activity1.riderLastName}`}
                          aValue={activityComparison.activity1.drills.find((d) => d.drillName === drill)?.score || 0}
                          bKey={`${activityComparison.activity2.riderFirstName} ${activityComparison.activity2.riderLastName}`}
                          bValue={activityComparison.activity2.drills.find((s) => s.drillName === drill)?.score || 0}
                          name={drill}
                          barSize={22}
                        />
                      </div>
                    ))}
                  </DrillChartsContent>
                </DrillCharts>
              )}
            </ActivityCompareContainer>
          )}
        </LoadingPanel>
      </PageGrid>
    </>
  );
};

const ActivityCompareSection = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div style={{ height: "100%" }} className={className}>
      <ActivityCompareSectionCard>
        <CardContent>{children}</CardContent>
      </ActivityCompareSectionCard>
    </div>
  );
};

const ActivityCompareContainer = styled(({ map, ...props }) => <div {...props} />)<{ map: boolean }>`
  display: grid;
  grid-template:
    "header header"
    "activity1-video activity2-video"
    ${(props) => props.map && '"map map"'}
    "charts charts"
    "drill-charts drill-charts";
  grid-gap: ${(props) => props.theme.spacing * 4}px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "header"
      "activity1-video"
      "activity2-video"
      "map"
      "charts"
      "drill-charts";
  }
`;

const Header = styled(ActivityCompareSection)`
  grid-area: header;
`;

const Activity1Video = styled(ActivityCompareSection)`
  grid-area: activity1-video;
`;

const Activity2Video = styled(ActivityCompareSection)`
  grid-area: activity2-video;
`;

const Map = styled(ActivityCompareSection)`
  grid-area: map;
`;

const Charts = styled(ActivityCompareSection)`
  grid-area: charts;
`;

const DrillCharts = styled(ActivityCompareSection)`
  grid-area: drill-charts;
`;

const ActivityCompareSectionCard = styled(Card)`
  height: 100%;
  .MuiCardContent-root {
    padding: 16px;
    height: 100%;
  }
`;

const HeaderContent = styled.div`
  display: grid;
  grid-template: "rider1 drill-select rider2";
  grid-template-columns: 1fr auto 1fr;
  grid-gap: ${(props) => props.theme.spacing * 2}px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "rider1"
      "rider2"
      "drill-select";
  }
`;

const Rider1Details = styled.div`
  grid-area: rider1;
`;
const Rider2Details = styled.div`
  grid-area: rider2;
  justify-self: flex-end;
  text-align: right;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    justify-self: flex-start;
    text-align: left;

    padding-top: ${(props) => props.theme.spacing * 2}px;
    &:before {
      content: "vs";
      position: relative;
      top: -${(props) => props.theme.spacing * 2}px;
    }
  }
`;
const DrillSelect = styled.div`
  grid-area: drill-select;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: ${(props) => props.theme.spacing * 4}px;
  }
`;

const ChartsContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${(props) => props.theme.spacing * 6}px;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;
const DrillChartsContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: ${(props) => props.theme.spacing * 4}px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
`;
