import React, { useState, useEffect } from "react";
import { SteeringChart, ThrottleChart, BrakingChart } from "..";
import styled from "styled-components";
import { useApiData } from "plumbing/api";
import { getActivityCharts } from "api/activityChart";
import { LoadingPanel } from "components/primitives";
import flatten from "lodash/flatten";

type ActivityChartsProps = { selectedContent: string; activity: ActivityModel };
export const ActivityCharts = ({ selectedContent, activity }: ActivityChartsProps) => {
  const [throttleData, setThrottleData] = useState<{}[]>([]);
  const [steeringData, setSteeringData] = useState<{}[]>([]);
  const [brakingData, setBrakingData] = useState<{}[]>([]);
  const { data: charts, fetchData, loadingState } = useApiData(() => getActivityCharts(activity.id), undefined, undefined, false);

  const isVisible = selectedContent === "Throttle" || selectedContent === "Steering" || selectedContent === "Braking";

  useEffect(() => {
    if (isVisible && !charts) {
      fetchData();
    }
  }, [fetchData, isVisible, charts]);

  useEffect(() => {
    const setChartData = (chartName: string, setFunction: (value: any) => void) => {
      const chart = flatten(parsed).find((c: any) => c.Name === chartName);

      if (chart) {
        setFunction(chart.DataPoints);
      }
    };

    if (!charts) return;

    const parsed = charts.map((c) => JSON.parse(c.chartData));

    setChartData("Throttle", setThrottleData);
    setChartData("Steering", setSteeringData);
    setChartData("Braking", setBrakingData);
  }, [charts]);

  return (
    <>
      {isVisible && (
        <StyledLoadingPanel loadingState={loadingState}>
          {selectedContent === "Throttle" && (
            <ThrottleChart
              data={throttleData}
              activitySkillType={activity.activitySkillType}
              activityType={activity.activityType}
              dataVersion={activity.algorithmVersion}
            />
          )}
          {selectedContent === "Braking" && (
            <BrakingChart
              data={brakingData}
              activitySkillType={activity.activitySkillType}
              activityType={activity.activityType}
              dataVersion={activity.algorithmVersion}
            />
          )}
          {selectedContent === "Steering" && (
            <SteeringChart
              data={steeringData}
              activitySkillType={activity.activitySkillType}
              activityType={activity.activityType}
              dataVersion={activity.algorithmVersion}
            />
          )}
        </StyledLoadingPanel>
      )}
    </>
  );
};

const StyledLoadingPanel = styled(LoadingPanel)`
  height: 100%;
`;
