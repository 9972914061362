import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { SHORT_DATE_FORMAT } from "DateFormats";
import { FormItem } from "../FormItem";
import { Grid } from "@material-ui/core";
import get from "lodash/get";

type DatePickerFieldProps = { label: string; name: string; maxDate?: Date };

export const DatePickerField = ({ label, name, maxDate }: DatePickerFieldProps) => {
  const { control, errors, formState } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <StyledKeyboardDatePicker
          autoOk
          error={get(errors, name)?.message !== undefined}
          helperText={get(errors, name)?.messagee}
          variant="inline"
          format={SHORT_DATE_FORMAT}
          id={name}
          label={label}
          onChange={(e) => {
            props.onChange(e);
          }}
          value={props.value}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          maxDate={maxDate}
          fullWidth
          disabled={formState.isSubmitting}
        />
      )}
    />
  );
};

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const DatePickerFieldFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: DatePickerFieldProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <DatePickerField {...props} />
    </FormItem>
  );

  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
