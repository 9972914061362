import React from "react";
import styled from "styled-components";
import { ButtonGauge } from "components/primitives/Gauges";
import { BodyText } from "components/primitives";
import { Button, Box, SimplePaletteColorOptions } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import TimerIcon from "@material-ui/icons/Timer";
import { skillNameComparer } from "plumbing/sorting";
import { ActivityDetailsHeader } from "./ActivityDetailsHeader";

type ActivityDashboardHeaderProps = {
  activity: ActivityModel;
  currentSkill: string;
  onSelectedContentChange: (skill: string) => void;
  selectedContent: string;
};
export const ActivityDashboardHeader = ({ activity, currentSkill, onSelectedContentChange, selectedContent }: ActivityDashboardHeaderProps) => {
  return (
    <ActivityDashboardHeaderContainer>
      <ActivityDetailsHeader activity={activity} />

      <Scores>
        <ButtonGauge key="Overall" value={activity.score} label={"Overall"} selected={currentSkill === "Overall"} onClick={() => onSelectedContentChange("Overall")} size={90} />

        {activity.skillScores
          .sort((a, b) => skillNameComparer(a.skillName, b.skillName))
          .map((skill) => (
            <ButtonGauge
              key={skill.skillName}
              value={skill.score}
              label={skill.skillName}
              selected={currentSkill === skill.skillName}
              onClick={() => onSelectedContentChange(skill.skillName)}
              size={70}
            />
          ))}
        <HeaderIconButton onClick={() => onSelectedContentChange("Videos")} selected={selectedContent === "Videos"}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <StyledVideocamIcon />
            <HeaderLinkLabel selected={selectedContent === "Videos"}>Video</HeaderLinkLabel>
          </Box>
        </HeaderIconButton>
        {activity.drills[0].hasLapTimes && (
          <HeaderIconButton onClick={() => onSelectedContentChange("LapTimes")} selected={selectedContent === "LapTimes"}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <StyledLapTimeIcon />
              <HeaderLinkLabel selected={selectedContent === "LapTimes"}>Lap Times</HeaderLinkLabel>
            </Box>
          </HeaderIconButton>
        )}
      </Scores>
    </ActivityDashboardHeaderContainer>
  );
};

const ActivityDashboardHeaderContainer = styled.div`
  display: grid;
  grid-template:
    "activity-details"
    "scores";
`;

const Scores = styled.div`
  grid-area: scores;
  justify-self: center;
  display: grid;
  flex-wrap: wrap;
  grid-gap: ${(props) => props.theme.spacing * 12}px;
  align-items: flex-end;

  @media (min-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-auto-flow: column;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${(props) => props.theme.spacing * 6}px;
    width: 100%;
  }
`;

const HeaderLinkLabel = styled(BodyText)<{ selected: boolean }>`
  margin-top: ${(props) => props.theme.spacing * 2}px;
  border-bottom: 2px solid ${(props) => (props.selected ? (props.theme.palette.primary as SimplePaletteColorOptions)?.dark : "transparent")};
`;

const HeaderIconButton = styled(Button)<{ selected: boolean }>`
  &:hover {
    ${HeaderLinkLabel} {
      border-bottom-color: ${(props) => (props.selected ? "" : (props.theme.palette.primary as SimplePaletteColorOptions)?.main)};
    }
  }
`;
const iconFontSize = "70px";
const StyledLapTimeIcon = styled(TimerIcon)`
  font-size: ${iconFontSize};
`;
const StyledVideocamIcon = styled(VideocamIcon)`
  font-size: ${iconFontSize};
`;
