export type TaskProgress = { [key: string]: number };
export type TaskProgressCallback = (percentComplete: number) => void;
export type ProgressUpdater = (tasks: TaskProgress) => void;

export const initTasks = <T>(entities: T[], getKey: (x: T) => string) => {
  return entities.reduce((tasks, entity) => {
    tasks[getKey(entity)] = 0;
    return tasks;
  }, {} as TaskProgress);
};

export const progressUpdater: (callback: TaskProgressCallback, maxPercent?: number) => ProgressUpdater =
  (callback: TaskProgressCallback, maxPercent?: number) => (tasks: TaskProgress) => {
    callback(calculateTotalPercentage(tasks, maxPercent));
  };

export const calculateTotalPercentage = (tasks: TaskProgress, maxPercent?: number) => {
  return (
    (Object.keys(tasks)
      .map((k) => tasks[k])
      .reduce((total, value) => (total += value), 0) /
      Object.keys(tasks).length) *
    (maxPercent || 1)
  );
};
