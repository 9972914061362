import axios from "axios";
import { apiConfiguration } from "plumbing/api";
import { TaskProgressCallback } from "./uploadActivity/taskProgress";
import { extractData } from "./uploadActivity/extractData";
import { uploadData } from "./uploadActivity/uploadData";
import { uploadVideos } from "./uploadActivity/uploadVideos";
import { waitForActivityProcessingToComplete } from "./uploadActivity/waitForActivityProcessingToComplete";
import { waitForVideoProcessingToComplete } from "./uploadActivity/waitForVideoProcessingToComplete";

export enum Tasks {
  DataExtract,
  DataUpload,
  VideoUpload,
}
export const uploadActivity = async (
  request: CreateActivityRequest,
  videos: DrillVideo[],
  onDataExtractProgress: TaskProgressCallback,
  onDataUploadProgress: TaskProgressCallback,
  onVideoUploadProgress: TaskProgressCallback,
  onError: (taskName: Tasks, error: string) => void
) => {
  onDataExtractProgress(0);
  onDataUploadProgress(0);
  onVideoUploadProgress(0);

  // Create activity
  const createActivityUrl = `${apiConfiguration.apiUrl}/activities`;
  let activity = (await axios.post<ActivityModel>(createActivityUrl, request)).data;

  // Execute tasks
  const videoData = await extractData(videos, onDataExtractProgress).catch((error) => {
    onError(Tasks.DataExtract, error);
    throw error;
  });
  const uploadDataTask = uploadData(activity.id.toString(), videoData, onDataUploadProgress).catch((error) => {
    onError(Tasks.DataUpload, error);
    throw error;
  });
  // Don't await this as we need to return the activity for the user to preview once scores are available
  if (videos.some(v => v.dataOnly !== true)) {
    uploadVideos(
      activity.id.toString(),
      videos.filter((v) => v.dataOnly !== true),
      onVideoUploadProgress
    ).catch((error) => {
      onError(Tasks.VideoUpload, error);
      throw error;
    })
  };

  await uploadDataTask;

  // Wait for processing to complete
  activity = await waitForActivityProcessingToComplete(activity.id?.toString());
  onDataUploadProgress(100);
  if (videos.some((v) => v.dataOnly !== true)) {
    waitForVideoProcessingToComplete(activity.id?.toString()).then(() => onVideoUploadProgress(100));
  }
  return activity;
};
