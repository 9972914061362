import axios from "axios";
import { apiConfiguration } from "plumbing/api";

export const getVideoOverlay = async (activityId: string | number, drillName: string) => {
  const url = `${apiConfiguration.apiUrl}/video-overlay`;
  const response = await axios.get<VideoOverlayResponse>(url, { params: { activityId: activityId, drillName: drillName } });

  if (!response.data) return { data: [] };

  const model = JSON.parse(response.data.data) as VideoOverlayModel[];

  return { data: model };
};
