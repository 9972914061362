/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { UploadActivityForm, UploadProgressPanel } from "components/ui";
import { uploadActivity } from "api/activity";

export const UploadActivity = () => {
  const [isUploading, setIsUploading] = useState(false);

  const [hasVideoUpload, setHasVideoUpload] = useState(true);
  const [extractProgress, setExtractProgress] = useState(0);
  const [dataProgress, setDataProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);

  const [activity, setActivity] = useState<ActivityModel | null>(null);
  const [riderDetails, setRiderDetails] = useState<RiderModel | null>(null);
  const [activityType, setActivityType] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSubmit = async (data: UploadActivityFormData) => {
    const getDataOnly = (data: UploadActivityFormData, name: string): boolean => {
      return Object.entries(data)
        .filter((entry) => entry[0] === `dataonly-${name}`)
        .map((entry) => entry[1])[0];
    };
    const getWetWeather = (data: UploadActivityFormData, name: string): boolean => {
      return Object.entries(data)
        .filter((entry) => entry[0] === `wetweather-${name}`)
        .map((entry) => entry[1])[0];
    };

    const anyVideos = () => {
      const dataonly = Object.entries(data)
        .filter((entry) => entry[0].startsWith("dataonly-"))
        .map((entry) => entry[1]);

      return !dataonly.reduce((acc, cur) => {
        acc = acc && cur;
        return acc;
      }, true);
    };
    setErrorMessage(undefined);
    setHasVideoUpload(anyVideos());
    setIsUploading(true);
    setExtractProgress(0);
    setDataProgress(0);
    setVideoProgress(0);
    if (!!data.riderFirstName) {
      setRiderDetails({
        firstName: data.riderFirstName!,
        lastName: data.riderLastName!,
        email: data.riderEmail!,
        riderId: -1,
      });
    }
    setActivityType(data.activityType);

    const createActivityRequest = {
      activityType: data.activityType,
      bikeCapacity: data.bikeCapacity,
      bikeMake: data.bikeMake,
      bikeModel: data.bikeModel,
      groupSlugs: data.groups?.map((g) => g.slug) || [],
      challengeSlugs: data.challenges?.map((c) => c.slug) || [],
      riderFirstName: data.riderFirstName,
      riderLastName: data.riderLastName,
      riderEmail: data.riderEmail,
    };
    const videos = Object.entries(data)
      .filter((entry) => entry[0].startsWith("videos-"))
      .map((entry) => ({
        drillName: entry[0].substring(7),
        dataOnly: getDataOnly(data, entry[0].substring(7)),
        wetWeather: getWetWeather(data, entry[0].substring(7)),
        file: entry[1][0],
      }));
    try {
      const createdActivity = await uploadActivity(createActivityRequest, videos, setExtractProgress, setDataProgress, setVideoProgress, (taskName, error) => {
        console.error(`${taskName} failed with error ${error}`);
        setErrorMessage("There was a problem processing your video - please try again and contact us if the error persists");
      });

      setActivity(createdActivity);
    } catch (error) {
      setErrorMessage("There was a problem processing your video - please try again and contact us if the error persists");
      throw error;
    }
  };

  return (
    <Card>
      <CardContent>
        {isUploading && (
          <UploadProgressPanel
            riderDetails={riderDetails}
            activityType={activityType!}
            hasVideoUpload={hasVideoUpload}
            extractProgress={extractProgress}
            dataUploadProgress={dataProgress}
            videoUploadProgress={videoProgress}
            activity={activity}
            onBack={() => setIsUploading(false)}
            errorMessage={errorMessage || activity?.friendlyFailureMessage}
          />
        )}
        <div style={{ display: isUploading ? "none" : "block" }}>
          <UploadActivityForm onSubmit={onSubmit} />
        </div>
      </CardContent>
    </Card>
  );
};
