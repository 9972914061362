import axios from 'axios';
import {TaskProgressCallback} from "./taskProgress";
import {initiateMultipartFileUpload} from "./initiateMultipartFileUpload";
import {generateMultipartFileUploadUrl} from "./generateMultipartFileUploadUrl";
import {completeMultipartFileUpload, PartETag} from "./completeMultipartFileUpload";
import {abortMultipartFileUpload} from "./abortMultipartFileUpload";

type UploadFileInfo = {
  name: string;
  file: File | Blob;
};

export const uploadFile = async (fileInfo: UploadFileInfo, metadata: { [key: string]: string }, onProgress: TaskProgressCallback) => {

  const enrichedMetaData: { [key: string]: string } = {
    ...metadata,
    fileName: fileInfo.name,
    fileType: fileInfo.file.type,
  };

  const headers: { [key: string]: string } = {
     'content-type': fileInfo.file.type,
  };

  for (const key in enrichedMetaData) {
    if (enrichedMetaData.hasOwnProperty(key)) {
      headers[`x-amz-meta-${key}`] = enrichedMetaData[key];
    }
  }

  const uploadId = await initiateMultipartUpload(fileInfo);

  const pendingUploads: Promise<PartETag>[] = [];
  const partSize = 10 * 1024 * 1024; // 10MB
  let partNumber = 0;
  let totalParts = Math.ceil(fileInfo.file.size / partSize);
  let completedParts = 0;

  for (let start = 0; start < fileInfo.file.size; start += partSize) {
    const end = Math.min(start + partSize, fileInfo.file.size);
    const filePart = fileInfo.file.slice(start, end);
    partNumber += 1;

    let pendingUpload = uploadPart(fileInfo, uploadId, filePart, partNumber).then(partETag => {
        completedParts++;
        let percentComplete = completedParts / totalParts * 100;
        onProgress(percentComplete);
        return partETag;
      });
    pendingUploads.push(pendingUpload);
  }

  try {
    const completedUploadParts: PartETag[] = await Promise.all(pendingUploads);
    await completeMultipartUpload(fileInfo, uploadId, completedUploadParts);
  } catch (error) {
    await abortMultipartUpload(fileInfo, uploadId);
  }
};

const initiateMultipartUpload = async (fileInfo: UploadFileInfo) => {
  return await initiateMultipartFileUpload(fileInfo.name, fileInfo.file.type);
};

const uploadPart = async (fileInfo: UploadFileInfo, uploadId: string, filePart: Blob, partNumber: number) => {
  const uploadUrl  = await generateMultipartFileUploadUrl(fileInfo.name, uploadId, partNumber);
  const response = await axios.put(uploadUrl.fileUploadUrl, filePart, {
    headers: { 'Content-Type': 'application/octet-stream'},
  });

  return {
    eTag: response.headers['etag'],
    partNumber: partNumber,
  };
};

const completeMultipartUpload = async (fileInfo: UploadFileInfo, uploadId: string, partETags: PartETag[]) => {
  await completeMultipartFileUpload(fileInfo.name, uploadId, partETags);
};

const abortMultipartUpload = async (fileInfo: UploadFileInfo, uploadId: string) => {
  await abortMultipartFileUpload(fileInfo.name, uploadId);
};
