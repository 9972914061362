import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardContent, CardMedia } from "@material-ui/core";
import {
  getSubscription,
  fetchManageSubscriptionLink,
  fetchCreateCheckoutSessionLink
} from "api/subscription";
import { BodyText, LinkButton, LoadingPanel, LoadingSpinner, Subheading } from "components/primitives";
import { PageGrid } from "components/ui";
import { useApiData } from "plumbing/api";
import { useQuery } from "plumbing/routes";
import React, { useEffect } from "react";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

export const ManageSubscription = () => {
  const query = useQuery();
  const isSuccessCallback = query.get("success") === "true";
  const { isAuthenticated } = useAuth0();

  const { data: subscription, loadingState: subscriptionLoadingState } = useApiData(getSubscription);
  const { fetchData: manageSubscriptionLink, loadingState: manageSubscriptionLinkLoading } = useApiData(fetchManageSubscriptionLink, undefined, undefined, false);

  const hasPremiumSubscription = subscription?.level === "Premium";

  useEffect(() => {
    const redirectToStripeCustomerPortal = async () => {
      const response = await manageSubscriptionLink();
      if (response !== undefined) window.location.href = response?.data;
    };

    const redirectToStripeCreateCheckoutSession = async () => {
      const response = await fetchCreateCheckoutSessionLink(process.env.REACT_APP_StripePremiumPriceId! as string);
      if (response !== undefined) window.location.href = response?.data;
    };
  
    if (isAuthenticated && !isSuccessCallback) {
      if (hasPremiumSubscription) redirectToStripeCustomerPortal();
      else redirectToStripeCreateCheckoutSession();
    }
  }, [isAuthenticated, isSuccessCallback, hasPremiumSubscription, manageSubscriptionLink]);

  return (
    <PageGrid>
      <LoadingPanel loadingState={[manageSubscriptionLinkLoading, subscriptionLoadingState]}>
        {!isSuccessCallback && hasPremiumSubscription && <LoadingSpinner />}
        {isSuccessCallback && <SuccessCallbackMessage />}
      </LoadingPanel>
    </PageGrid>
  );
};

const SuccessCallbackMessage = () => {
  return (
    <Card style={{ textAlign: "center" }}>
      <CardMedia style={{ backgroundColor: "#4caf50", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "24px" }}>
        <CheckIcon style={{ fontSize: 100, color: "white" }} />
        <Subheading>Success!</Subheading>
      </CardMedia>
      <CardContent style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "16px", gap: "30px" }}>
        <BodyText>Your premium subscription is ready to go!</BodyText>
        <LinkButton to="/">Continue</LinkButton>
      </CardContent>
    </Card>
  );
};
