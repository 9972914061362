import React from "react";
import { useLocation } from "react-router-dom";
 
import { analytics } from "./analytics"
 
export const useGoogleAnalytics = () => {
  const location = useLocation();
 
  React.useEffect(() => {
    analytics.init();
  }, [])
 
  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    analytics.sendPageview(currentPath);
  }, [location])

  return null;
}