import { AccordionDetails, Typography } from "@material-ui/core";
import { Heading } from "components/primitives";
import { AccordionSummaryWithIcon, PageGrid, Accordion } from "components/ui";
import React from "react";
import { Helmet } from "react-helmet";

export const FAQ = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - FAQ</title>
      </Helmet>

      <PageGrid>
        <Heading>Frequently asked questions</Heading>
        <div>
          <Accordion>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Can I use a different action camera?</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <Typography>
                Currently only GPS enabled GoPro’s are supported. We are planning to expand the list of compatible cameras.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">
                I accidentally uploaded the wrong video for a drill. Can I change or edit the submission?
              </Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <Typography>
                If you believe you made a mistake with your upload, please send us an email and we will remove the uploaded data.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Are the scores accurate?</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <Typography>
                The algorithms have been developed and tested based on data from thousands of riders and are in a continuous refinement loop.
                The scores that are given are an accurate representation of your riding behaviour (within 5% accuracy).
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Can I compare my data with myself?</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <Typography>
                Currently, no. We are working on giving you the ability to compare yourself with other activities you have completed.
              </Typography>
            </AccordionDetails>
          </Accordion>
    
          <Accordion>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">My riding line or laptimes are not working properly</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <Typography>
                Your riding line and laptimes are calculated using GPS. The accuracy on the GPS is dependant on the view that the GoPro has to the sky. Parts of the bike, your body, and cloudy days can all reduce the accuracy of the GPS, leading to inaccurate riding lines or laptimes.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageGrid>
    </>
  );
};

/*
 <Accordion>
  <AccordionSummaryWithIcon>
    <Typography variant="h3">How do I cancel my subscription?</Typography>
  </AccordionSummaryWithIcon>
  <AccordionDetails>
    <Typography>
      To cancel your subscription, click on your profile icon and then 'Manage Subscription'. Here you can manage/cancel your subscription.
              </Typography>
  </AccordionDetails>
</Accordion>
*/