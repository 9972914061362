import React from "react";
import { PageGrid, ContentPageSection, Accordion, AccordionSummaryWithIcon } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, BodyText } from "components/primitives";
import { AccordionDetails, Typography } from "@material-ui/core";
import trackSkills from "assets/instructions/trackskills.png";

export const TrackSkills = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - trackSKILLS</title>
      </Helmet>
      <PageGrid>
        <Heading>trackSKILLS</Heading>
        <div>
          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Riding Instructions</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection imageUrl={trackSkills} imageAlt="Track Rider">
                  <BodyText>
                    When using the GoPro on a circuit, you may start and stop recording at any stage throughout the session. A minimum of one complete lap is required to upload to
                    the Digital Academy, however ensure that the video starts atleast 100m before the start/finish line and ends 100m after. Advanced features such as GoPro Labs
                    can be enabled to automatically begin recording above a certain speed, and stop recording when below a minimum speed. Typical values for these are 80% of your
                    top speed at the circuit, and 40kph for the minimum speed. You may begin recording before entering the track and stop the recording once you have stopped, but
                    beware of the large file size and upload time.
                  </BodyText>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageGrid>
    </>
  );
};
