import React from "react";
import { FormControl, Input, InputLabel, FormHelperText, Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { FormItem } from "../FormItem";
import get from "lodash/get";

type TextFieldProps = {
  label: string;
  name: string;
  fullWidth?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  multiline?: boolean;
  rows?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  type?: string;
  defaultValue?: string;
};

export const TextField = ({
  label,
  name,
  fullWidth = true,
  endAdornment,
  multiline = false,
  rows,
  autoFocus,
  disabled,
  type,
  startAdornment,
  defaultValue,
}: TextFieldProps) => {
  const { register, errors, formState } = useFormContext();

  return (
    <FormControl fullWidth={fullWidth} error={get(errors, name)?.message !== undefined}>
      <InputLabel htmlFor={name}>{label}</InputLabel>

      {/* https://stackoverflow.com/a/56224685/25702 */}
      <Input
        type={type}
        inputRef={register()}
        defaultValue={defaultValue}
        name={name}
        fullWidth={fullWidth}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        multiline={multiline}
        rows={rows}
        disabled={formState.isSubmitting || disabled}
        key={name}
        id={name}
        inputProps={{
          autoComplete: "off",
        }}
        autoFocus={autoFocus}
      />

      <FormHelperText> {get(errors, name)?.message}</FormHelperText>
    </FormControl>
  );
};

export const TextFieldFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: TextFieldProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <TextField {...props} />
    </FormItem>
  );
  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};

export const HiddenField = ({ name, defaultValue }: { name: string; defaultValue: string }) => {
  const { register } = useFormContext();

  return <input type="hidden" name={name} ref={register()} defaultValue={defaultValue} />;
};
