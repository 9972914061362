import React from "react";
import { FormControl, FormLabel, FormHelperText, Button, Grid, SimplePaletteColorOptions } from "@material-ui/core";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { FormItem } from "../FormItem";
import get from "lodash/get";

type FileInputFieldProps = { label: string; name: string; accept?: string };

export const FileInputField = ({ name, label, accept }: FileInputFieldProps) => {
  const { register, errors, formState } = useFormContext();
  const selectedFiles = useWatch<File[]>({ name, defaultValue: [] });
  const hasError = get(errors, name)?.message !== undefined;
  return (
    <FormControl error={hasError} fullWidth>
      <FormLabel component="legend">{label}</FormLabel>
      <FileInputContainer>
        <FileLabel error={hasError}>{selectedFiles.length ? selectedFiles[0].name : "No file selected"}</FileLabel>
        <Button variant="contained" component="label" disabled={formState.isSubmitting}>
          {selectedFiles.length === 0 ? "Select File" : "Change File"}
          <input type="file" name={name} ref={register} hidden accept={accept} />
        </Button>
      </FileInputContainer>
      <FormHelperText>{get(errors, name)?.message}</FormHelperText>
    </FormControl>
  );
};

const FileInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileLabel = styled(({ error, ...props }) => <label {...props} />)<{ error: boolean }>`
  color: ${(props) => (props.error ? (props.theme.palette.error as SimplePaletteColorOptions).main : "inherit")};
`;

export const FileInputFieldFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: FileInputFieldProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <FileInputField {...props} />
    </FormItem>
  );

  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
