import React from "react";
import { PageGrid } from "components/ui";
import { Helmet } from "react-helmet";
import { LoadingPanel } from "components/primitives";
import { useParams } from "react-router-dom";
import { useApiData } from "plumbing/api";
import { getChallengeBySlug } from "api/challenge";
import { ChallengeDashboardView } from "components/ui/Challenges/ChallengeDashboardView";
import { getSubscription } from "api/subscription";

export const ChallengeDashboard = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: challengeDetails, loadingState, setData, fetchData } = useApiData(() => getChallengeBySlug(slug));
  const { data: subscription, loadingState: subscriptionLoadingState } = useApiData(getSubscription);

  const handleChange = async (model: ChallengeModel | undefined) => {
    if (model) setData(model);
    else await fetchData();
  };

  return (
    <>
      <Helmet>
        <title> Digital Academy - {challengeDetails ? challengeDetails.name : "Challenge"}</title>
      </Helmet>
      <PageGrid>
        <LoadingPanel loadingState={[loadingState, subscriptionLoadingState]}>
          {challengeDetails && subscription && (
            <ChallengeDashboardView challenge={challengeDetails} onChange={handleChange} subscription={subscription} />
          )}
        </LoadingPanel>
      </PageGrid>
    </>
  );
};
