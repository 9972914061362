import React from "react";
import { Helmet } from "react-helmet";
import { PageGrid, CreateEditChallengeForm, ChallengeFormData } from "components/ui";
import { Heading, LoadingPanel } from "components/primitives";
import { editChallenge, getChallengeBySlug } from "api/challenge";
import { useHistory, useParams } from "react-router-dom";
import { useApiData } from "plumbing/api";

export const EditChallenge = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();

  const { data: challengeDetails, loadingState } = useApiData(() => getChallengeBySlug(slug));

  const handleSubmit = async (data: ChallengeFormData) => {
    const request: EditChallengeRequest = {
      name: data.name,
      description: data.description,
      activitySkillType: data.activitySkillType,
      startDate: data.startDate,
      endDate: data.endDate,
      image: data.photo![0],
      youtubeVideoUrl: data.youtubeVideoUrl,
      prizes: data.prizes,
      rules: data.rules,
      signUpText: data.signupText,
      termsAndConditions: data.termsAndConditions?.length ? data.termsAndConditions[0] : undefined,
      signUpPrice: data.cost,
    };

    const response = await editChallenge(challengeDetails?.slug!, request);

    history.push(`/challenges/${response.data.slug}`);
  };

  const initialFormValues: ChallengeFormData | undefined = challengeDetails
    ? {
        name: challengeDetails?.name,
        startDate: new Date(challengeDetails?.startDate),
        endDate: new Date(challengeDetails?.endDate),
        activitySkillType: challengeDetails?.activitySkillType,
        description: challengeDetails?.description,
        imageUrl: challengeDetails?.imageUrl,
        youtubeVideoUrl: challengeDetails?.youtubeVideoUrl,
        rules: challengeDetails?.rules,
        prizes: challengeDetails?.prizes,
        cost: challengeDetails?.signUpPrice || 0,
        signupText: challengeDetails?.signUpText,
        termsAndConditions: undefined,
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title>Digital Academy - Edit Challenge</title>
      </Helmet>
      <PageGrid>
        <Heading>Edit Challenge</Heading>
        <LoadingPanel loadingState={loadingState}>{initialFormValues && <CreateEditChallengeForm onSubmit={handleSubmit} initialValues={initialFormValues} />}</LoadingPanel>
      </PageGrid>
    </>
  );
};
