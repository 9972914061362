import React from "react";
import { RiderGroups, PageGrid } from "components/ui";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { userStore } from "plumbing/auth";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export const Groups = observer(() => {
  return (
    <>
      <Helmet>
        <title> Digital Academy - Groups</title>
      </Helmet>
      <PageGrid>
        {userStore.isAdmin && (
          <Button component={Link} to="/groups/create" variant="contained" color="primary" style={{ marginBottom: "16px" }}>
            Create Group
          </Button>
        )}
        <RiderGroups />
      </PageGrid>
    </>
  );
});
