import axios from "axios";
import { apiConfiguration } from "plumbing/api";

export type PartETag = {
  eTag: string;
  partNumber: number;
};

export const completeMultipartFileUpload = async (fileName: string, uploadId: string, partETags: PartETag[]) => {
  const url = `${apiConfiguration.apiUrl}/multipart-file-upload/complete`;
  await axios.post(url, {
    fileName,
    uploadId,
    partETags
  });
  return {};
}
