import React from "react";
import { Typography } from "@material-ui/core";
import { Subheading, BodyText } from "components/primitives";
import { InfoTooltip } from ".";
import { TelemetryLineChart } from "./TelemetryLineChart";
import { ReferenceLines } from "AdminConstants";
import styled from "styled-components";

type ThrottleChartProps = {
  data: DataPoint[];
  activitySkillType: string;
  activityType: string;
  dataVersion: string;
};

export const ThrottleChart = ({ data, activitySkillType, activityType, dataVersion }: ThrottleChartProps) => {
  let referenceData = activitySkillType === "Street" ? ReferenceLines.Street.Throttle : ReferenceLines.Track.Throttle;
  let xAxisLabel = isVersionAtLeast("1.0.632", dataVersion) ? "Time - sec" : "Distance - M";
  if (activityType === "trackSKILLS 101") referenceData = ReferenceLines.TrackSkills101.Throttle;
  if (activityType === "streetSKILLS 101") xAxisLabel = "Distance - M";


  const [mappedData, setMappedData] = React.useState<DataPoint[]>([]);
  React.useEffect(() => {
    setMappedData(data.map((d) => ({ ...d })));
  }, [data]);

  return (
    <ThrottleChartContainer>
      <Subheading gutterBottom>
        Throttle{" "}
        <InfoTooltip
          activitySkillType={activitySkillType}
          streetText={
            <Typography>
              This graph shows your best acceleration out of a corner on the Figure 8. The goal is to maximise the throttle usage by being
              smooth and progressive, to achieve and maintain a high maximum.
            </Typography>
          }
          trackText={
            <Typography>
              This graph shows your best acceleration out of a corner. The goal is to maximise the throttle usage by being smooth and
              progressive, to achieve and maintain a high maximum.
            </Typography>
          }
        />
      </Subheading>

      {!mappedData.length && <StyledBodyText>Throttle chart is not currently available</StyledBodyText>}

      {mappedData.length > 0 && (
        <TelemetryLineChart
          dataVersion={dataVersion}
          data={mappedData}
          referenceData={referenceData}
          xAxisLabel={xAxisLabel}
          yAxisLabel="G-Force - G"
          showXZero={true}
          showYZero={true}
          featureLabel="Apex"
          activitySkillType={activitySkillType}
        />
      )}
    </ThrottleChartContainer>
  );
};

const ThrottleChartContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
`;

const StyledBodyText = styled(BodyText)`
  height: 100%;
`;


const isVersionAtLeast = (requiredVersion: string, version: string): boolean => {
  // Version is a string in three parts "X.Y.Z[.A]"
  const requiredVersionSegments: number[] = requiredVersion.split(".").map((x) => parseInt(x, 10));
  const versionSegments: number[] = (version ?? "0.0.0.0").split(".").map((x) => parseInt(x, 10));

  if (requiredVersionSegments.length < 3 || versionSegments.length < 3) {
    console.dir(`Algorithm version ${version} is not in the expected format.  Assuming versions don't match for graph calculations`);
    return false;
  }

  if (versionSegments[0] < requiredVersionSegments[0]) return false;
  if (versionSegments[0] > requiredVersionSegments[0]) return true;
  if (versionSegments[1] < requiredVersionSegments[1]) return false;
  if (versionSegments[1] > requiredVersionSegments[1]) return true;
  if (versionSegments[2] < requiredVersionSegments[2]) return false;

  return true;
};

