import React, { useState } from "react";
import {
  Form,
  TextFieldFormItem,
  SimpleCard,
  SelectFieldFormItem,
  FileInputFieldFormItem,
  SubmitButtonFormItem,
  LoadingPanel,
  Subheading,
  Caption,
  HiddenField,
} from "components/primitives";
import * as yup from "yup";
import { Button, Grid, Divider, IconButton } from "@material-ui/core";
import { useApiData } from "plumbing/api";
import { getActivityReferenceData } from "api/activityReferenceData";
import { GroupDashboardView } from ".";
import styled from "styled-components";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { MaintainCoachesPanel } from "./MaintainCoaches";

export interface SignUpQuestionFormModel {
  questionId: number;
  label: string;
  questionType: QuestionType;
  options: string;
}

export interface GroupFormData {
  name: string;
  activitySkillType: string;
  description: string;
  photo?: FileList;
  imageUrl?: string;
  signupText: string;
  signUpQuestions?: SignUpQuestionFormModel[];
  coaches?: CoachModel[];
}

type CreateEditGroupFormProps = {
  onSubmit: (formData: GroupFormData) => Promise<any>;
  initialValues?: GroupFormData;
};

export const CreateEditGroupForm = ({ onSubmit, initialValues }: CreateEditGroupFormProps) => {
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    activitySkillType: yup.string().required("Activity Type is required").oneOf(["Street", "Track"]),
    description: yup.string().required("Description is required"),
    photo: yup.mixed().test("required", `Photo is required`, (value: FileList) => {
      if (initialValues?.imageUrl) return true;
      return value && value.length > 0;
    }),
    signupText: yup.string().required(),
    signUpQuestions: yup.array().of(
      yup.object().shape({
        questionType: yup.string().required("Question type is required"),
        label: yup.string().required("Label is required"),
        options: yup.string().when("questionType", (v: string, schema: yup.AnySchema) => {
          if (v === "Dropdown") {
            return schema.test("commaSeparatedList", 'Options must be a comma-separate list like "a,b,c"', (value: string) => {
              return !!value && value.split(",").length > 0;
            });
          }
          return schema;
        }),
      })
    ),
  });

  const { data, loadingState } = useApiData(getActivityReferenceData);
  const [group, setGroup] = useState<GroupModel>({
    name: "",
    slug: "",
    description: "",
    imageUrl: initialValues?.imageUrl || "",
    riderCount: 0,
    activitySkillType: "",
    signUpText: "",
    userStatus: "NotJoined",
    signUpQuestions: [],
    coaches: [],
  });
  const handleFormChange = (data: GroupFormData) => {
    let newValues: Record<string, any> = {};

    if (data.photo && data.photo.length) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (e === null || e.target === null) return;

        setGroup((c) => ({
          ...c,
          imageUrl: (e as any).target.result as string,
        }));
      };
      reader.readAsDataURL(data.photo[0]);
    } else {
      newValues.imageUrl = initialValues?.imageUrl;
    }

    setGroup((c) => ({
      ...c,
      ...newValues,
      name: data.name,
      slug: "",
      description: data.description,
      riderCount: 0,
      activitySkillType: data.activitySkillType,
      signUpText: data.signupText,
      riderJoined: false,
      signUpQuestions:
        data.signUpQuestions?.map((q) => ({
          id: q.questionId,
          label: q.label,
          questionType: q.questionType,
          optionsValues: q.options?.split(","),
        })) || [],
      coaches: [],
    }));
  };

  return (
    <>
      <SimpleCard>
        <LoadingPanel loadingState={loadingState}>
          {data && (
            <Form onSubmit={onSubmit} schema={schema} onChange={handleFormChange} defaultValues={initialValues}>
              <Grid container item spacing={2}>
                <TextFieldFormItem name="name" label="Name" xs={6} />
                <SelectFieldFormItem label="Skill Type" name="activitySkillType" items={["Street", "Track"]} xs={3} />
              </Grid>
              <TextFieldFormItem name="description" label="Description" multiline rows={6} xs={6} fullRow />
              <FileInputFieldFormItem name="photo" label="Photo" accept=".png,.jpg" xs={4} fullRow />

              <TextFieldFormItem name="signupText" label="Signup Text" multiline rows={6} xs={6} />
              <Grid item xs={12}>
                <SimpleCard elevation={6}>
                  <Subheading>Sign Up Questions</Subheading>
                  <Caption>Ask new members questions when they request to join the group.</Caption>
                  <SignUpQuestions />
                </SimpleCard>
              </Grid>
              <MaintainCoachesPanel />
              <SubmitButtonFormItem fullRow>Save</SubmitButtonFormItem>
            </Form>
          )}
        </LoadingPanel>
      </SimpleCard>
      <Divider style={{ margin: "24px 0" }} />
      <GroupDashboardView group={group} previewMode />
    </>
  );
};

const SignUpQuestionRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing * 2}px;
`;

const SignUpQuestions = () => {
  const { control, formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: "signUpQuestions",
    control,
  });

  const signUpQuestions = useWatch<SignUpQuestionFormModel[]>({
    name: "signUpQuestions",
    defaultValue: [],
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <SignUpQuestionRow key={field.id}>
            <Grid container spacing={2}>
              <HiddenField name={`signUpQuestions.${index}.questionId`} defaultValue={field.questionId} />
              <SelectFieldFormItem name={`signUpQuestions.${index}.questionType`} label="Type" xs={4} items={["Text", "Dropdown", "Checkbox"]} defaultValue={field.questionType} />
              <TextFieldFormItem name={`signUpQuestions.${index}.label`} label="Label" xs={4} defaultValue={field.label} />
              <TextFieldFormItem
                name={`signUpQuestions.${index}.options`}
                label="Options"
                xs={4}
                disabled={signUpQuestions.length > index && signUpQuestions[index].questionType !== "Dropdown"}
                defaultValue={field.options}
              />
            </Grid>
            <IconButton
              onClick={() => {
                remove(index);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </SignUpQuestionRow>
        );
      })}
      <Button
        onClick={() => {
          append({ label: "", questionType: "Text" });
        }}
        color="primary"
        variant="outlined"
        disabled={formState.isSubmitting}
        style={{ marginTop: "8px", display: "block" }}
      >
        Add Sign Up Question
      </Button>
    </>
  );
};
