import React from "react";
import { Typography } from "@material-ui/core";
import { UploadLinearProgressBar } from "./UploadLinearProgressBar";

export const DataExtractProgress = ({ progress, message, hasFailed }: ProgressProps) => {
  return (
    <>
      <Typography variant="h5" color={hasFailed ? "error" : undefined}>
        {hasFailed ? "Extract Failed" : message}
      </Typography>
      <UploadLinearProgressBar variant="determinate" value={progress} />
    </>
  );
};
