import { useEffect, useState, useCallback } from "react";
import { LoadingState } from "components/primitives";

export function useApiData<T>(request: () => Promise<{ data: T }>, onError?: (ex: Error) => void, requestDeps?: ReadonlyArray<any>, fetchImmediately: boolean = true) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestCallback = useCallback(request, requestDeps || []);
  const [data, setData] = useState<T | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    setHasError(false);

    try {
      const response = await requestCallback();
      setData(response.data);
      return response;
    } catch (ex) {
      setHasError(true);
      // eslint-disable-next-line no-console
      console.error(ex);

      if (onError) {
        onError(ex as Error);
      }
    } finally {
      setIsFetching(false);
    }
  }, [onError, requestCallback]);

  useEffect(() => {
    if (fetchImmediately) fetchData();
    else setIsFetching(false);
  }, [fetchData, fetchImmediately]);

  const loadingState: LoadingState = {
    isLoading: isFetching,
    hasError,
    fetchData,
    hasLoaded: data !== undefined,
  };

  return { data, setData, fetchData, isFetching, hasError, loadingState };
}
