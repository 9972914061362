/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import Helmet from "react-helmet";
import { PageGrid, UploadActivity } from "components/ui";
import styled from "styled-components";
import { Heading } from "components/primitives";
import { Button } from "@material-ui/core";

export const UploadActivityPage = () => {
  const [uploads, setUploads] = useState<string[]>(["1"]);
  return (
    <>
      <Helmet>
        <title> Digital Academy - Upload Activity</title>
      </Helmet>
      <PageGrid>
        <Heading>Upload Activity</Heading>
        <UploadActivityList>
          {uploads.map((x) => (
            <UploadActivity key={x} />
          ))}
        </UploadActivityList>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginTop: "24px" }}
          onClick={() => {
            setUploads([...uploads, `${new Date()}`]);
          }}
        >
          Upload More
        </Button>
      </PageGrid>
    </>
  );
};

const UploadActivityList = styled.div`
  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;
