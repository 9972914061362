import React, { useState } from "react";
import {
  Form,
  TextFieldFormItem,
  SimpleCard,
  DatePickerFieldFormItem,
  SelectFieldFormItem,
  FileInputFieldFormItem,
  SubmitButtonFormItem,
  LoadingPanel,
} from "components/primitives";
import * as yup from "yup";
import { InputAdornment, Grid, Divider } from "@material-ui/core";
import { useApiData } from "plumbing/api";
import { getActivityReferenceData } from "api/activityReferenceData";
import { ChallengeDashboardView } from ".";

export interface ChallengeFormData {
  name: string;
  startDate: Date;
  endDate: Date;
  activitySkillType: string;
  description: string;
  photo?: FileList;
  imageUrl?: string;
  youtubeVideoUrl: string;
  rules: string;
  prizes: string;
  cost: number;
  signupText: string;
  termsAndConditions?: FileList;
}

type CreateEditChallengeFormProps = {
  onSubmit: (formData: ChallengeFormData) => Promise<any>;
  initialValues?: ChallengeFormData;
};
export const CreateEditChallengeForm = ({ onSubmit, initialValues }: CreateEditChallengeFormProps) => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup.date().required().min(yup.ref("startDate"), "End date must be after start date"),
    activitySkillType: yup.string().required(),
    description: yup.string().required(),
    youtubeVideoUrl: yup.string().url("Video must be a valid URL (starting with http)"),
    photo: yup.mixed().test("required", `Photo is required`, (value: FileList) => {
      if (initialValues?.imageUrl) return true;
      return value && value.length > 0;
    }),
    rules: yup.string().required(),
    prizes: yup.string().required(),
    cost: yup.number().min(0),
    signupText: yup.string().required(),
    termsAndConditions: yup.mixed(),
  });

  const { data, loadingState } = useApiData(getActivityReferenceData);
  const [challenge, setChallenge] = useState<ChallengeModel>({
    name: "",
    slug: "",
    description: "",
    imageUrl: initialValues?.imageUrl || "",
    youtubeVideoUrl: "",
    riderCount: 0,
    activitySkillType: "",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    prizes: "",
    rules: "",
    signUpText: "",
    termsAndConditionsUrl: "",
    riderJoined: false,
    signUpPrice: 0,
  });

  const handleSubmit = async (formData: ChallengeFormData) => {
    await onSubmit(formData);
  };

  const handleFormChange = (data: ChallengeFormData) => {
    let newValues: Record<string, any> = {};

    if (data.photo && data.photo.length) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (e === null || e.target === null) return;

        setChallenge((c) => ({ ...c, imageUrl: (e as any).target.result as string }));
      };
      reader.readAsDataURL(data.photo[0]);
    } else {
      newValues.imageUrl = initialValues?.imageUrl;
    }

    setChallenge((c) => ({
      ...c,
      ...newValues,
      name: data.name,
      slug: "",
      description: data.description,
      riderCount: 0,
      activitySkillType: data.activitySkillType,
      startDate: isNaN(data.startDate?.getTime() as any) ? c.startDate : data.startDate.toISOString(),
      endDate: isNaN(data.endDate?.getTime() as any) ? c.endDate : data.endDate.toISOString(),
      prizes: data.prizes,
      rules: data.rules,
      signUpText: data.signupText,
      termsAndConditionsUrl: "",
      youtubeVideoUrl: data.youtubeVideoUrl,
      riderJoined: false,
      signUpPrice: data.cost,
    }));
  };

  const defaultValues = initialValues || { startDate: new Date(), endDate: new Date(), cost: 0 };
  return (
    <>
      <SimpleCard>
        <LoadingPanel loadingState={loadingState}>
          {data && (
            <Form onSubmit={handleSubmit} schema={schema} onChange={handleFormChange} defaultValues={defaultValues}>
              <Grid container item spacing={2}>
                <TextFieldFormItem name="name" label="Name" xs={6} />
                <SelectFieldFormItem label="Skill Type" name="activitySkillType" items={["Street", "Track"]} xs={3} />
              </Grid>
              <DatePickerFieldFormItem name="startDate" label="Start Date" xs={3} />
              <DatePickerFieldFormItem name="endDate" label="End Date" xs={3} />
              <TextFieldFormItem name="description" label="Description" multiline rows={6} xs={6} fullRow />
              <FileInputFieldFormItem name="photo" label="Photo" accept=".png,.jpg" xs={4} fullRow />
              <TextFieldFormItem name="youtubeVideoUrl" label="Video" xs={6} fullRow />
              <TextFieldFormItem name="rules" multiline rows={6} label="Rules" xs={6} />
              <TextFieldFormItem name="prizes" multiline rows={6} label="Prizes" xs={6} />
              <TextFieldFormItem name="signupText" label="Signup Text" multiline rows={6} xs={6} />
              <TextFieldFormItem name="cost" label="Cost" startAdornment={<InputAdornment position="start">$</InputAdornment>} xs={3} type="number" fullRow />
              <FileInputFieldFormItem name="termsAndConditions" label="Terms and Conditions" xs={4} fullRow />
              <SubmitButtonFormItem fullRow>Save</SubmitButtonFormItem>
            </Form>
          )}
        </LoadingPanel>
      </SimpleCard>
      <Divider style={{ margin: "24px 0" }} />
      <ChallengeDashboardView challenge={challenge} previewMode subscription={{ level: "Premium", customerId: "" }} />
    </>
  );
};
