import React from "react";
import { Typography } from "@material-ui/core";

export const Heading = ({
  children,
  className,
  gutterBottom = true,
}: {
  children: React.ReactNode;
  className?: string;
  gutterBottom?: boolean;
}) => {
  return (
    <Typography variant="h1" gutterBottom={gutterBottom} className={className}>
      {children}
    </Typography>
  );
};
