import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Avatar } from "@material-ui/core";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { Form, SubmitButton, FormItem, TextField, BodyText, SimpleCard } from "components/primitives";
import { CardNumberField, CardExpiryField, CardCvcField } from "./StripeElements";
import * as yup from "yup";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

type CheckoutFormProps = {
  isOpen: boolean;
  onClose: (wasPaymentSuccess: boolean) => void;
  paymentIntentClientSecret: string;
  productName: string;
  productPrice: number;
  productImageUrl: string;
};

const validateStripeElement: (requiredErrorMessage: string) => yup.TestFunction<any, Record<string, any>> = (requiredErrorMessage) => (
  value,
  context
) => {
  if (!value) return false;
  const stripeEvent = (value as unknown) as StripeElementChangeEvent;
  if (stripeEvent.empty) {
    return context.createError({ message: requiredErrorMessage, path: context.path });
  }
  if (stripeEvent.error) return context.createError({ message: stripeEvent.error.message });
  if (!stripeEvent.complete) return false;
  return true;
};

export const CheckoutForm = ({
  isOpen,
  onClose,
  paymentIntentClientSecret,
  productName,
  productPrice,
  productImageUrl,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const schema = yup.object().shape({
    nameOnCard: yup.string().required("Name on Card is required"),
    cardNumber: yup
      .mixed()
      .required("Card number is required")
      .test("validCardNumber", "Card number is not valid", validateStripeElement("Card number is required")),
    cardExpiry: yup
      .mixed()
      .required("Card expiry is required")
      .test("validCardExpiry", "Card expiry is not valid", validateStripeElement("Card expiry is required")),
    cvc: yup
      .mixed()
      .required("Card CVC is required")
      .test("validCardCvc", "Card CVC is not valid", validateStripeElement("CVC is required")),
  });

  const handleSubmitPayment = async () => {
    setError("");
    if (!stripe || !elements) {
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    if (!cardNumber) return;

    const result = await stripe.confirmCardPayment(paymentIntentClientSecret, {
      payment_method: {
        card: cardNumber,
      },
    });

    if (result.error) {
      setError(result.error.message ?? "");
      console.warn(result.error.message);
    } else {
      console.dir(result);
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setIsBusy(true);
        onClose(true);
      }
    }
  };
  return (
    <Form onSubmit={handleSubmitPayment} schema={schema}>
      <Dialog open={isOpen} onClose={() => onClose(false)} fullWidth disablePortal disableEscapeKeyDown disableBackdropClick>
        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {!!error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            <FormItem>
              <SimpleCard elevation={6}>
                <LineItem>
                  <LineItemImage>
                    <Avatar src={productImageUrl} variant="rounded" />
                  </LineItemImage>
                  <LineItemName>{productName}</LineItemName>
                  <LineItemPrice>{`$${productPrice.toFixed(2)}`}</LineItemPrice>
                </LineItem>
              </SimpleCard>
            </FormItem>
            <FormItem>
              <TextField label="Name on Card *" name="nameOnCard" autoFocus />
            </FormItem>
            <FormItem>
              <CardNumberField />
            </FormItem>
            <FormItem xs={6}>
              <CardExpiryField />
            </FormItem>
            <FormItem xs={6}>
              <CardCvcField />
            </FormItem>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SubmitButton disabled={isBusy}>Pay Now</SubmitButton>
          <Button disabled={isBusy} variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Form>
  );
};

const LineItem = styled.div`
  display: flex;
  align-items: center;
`;

const LineItemImage = styled.div`
  margin-right: ${(props) => props.theme.spacing * 2}px;
  img.MuiAvatar-img {
    object-fit: contain;
  }
`;

const LineItemName = styled(BodyText)`
  flex: 1;
`;
const LineItemPrice = styled(BodyText)``;
