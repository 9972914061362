import { AccordionSummary, AccordionSummaryProps } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";

export const AccordionSummaryWithIcon = (props: AccordionSummaryProps) => {

  return (
    <AccordionSummary {...props} expandIcon={<ExpandMoreIcon />} />
  );
};
