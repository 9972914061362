import React from "react";
import { Subheading } from "components/primitives";
import { Table, TableHead, TableRow, TableCell, TableBody, Chip } from "@material-ui/core";
import styled from "styled-components";
import orderBy from "lodash/orderBy";

type ActivityLapTimesProps = {
  activity: ActivityModel;
};

export const ActivityLapTimes = ({ activity }: ActivityLapTimesProps) => {
  const lapTimes = activity.drills[0].lapTimes || [];
  const fastest = orderBy(
    lapTimes.filter((lt) => lt.label.indexOf("Invalid") === -1 && lt.label.indexOf("OutLap") === -1 && lt.label.indexOf("InLap") === -1),
    (t) => t.time
  )[0];

  const isInvalid = (row: LapTimeModel) => row.label.indexOf("Invalid") !== -1;

  const formatLabel = (rowLabel: string) => rowLabel.replace(" (Invalid)", "");

  return (
    <>
      <Subheading>Lap Times</Subheading>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Lap</StyledTableCell>
            <StyledTableCell align="right">Time</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lapTimes.map((row) => (
            <TableRow key={row.label}>
              <StyledTableCell>
                {formatLabel(row.label)}
                {fastest === row && <Chip label="Fastest" size="small" variant="outlined" color="primary" style={{ marginLeft: "8px" }} />}
                {isInvalid(row) && (
                  <Chip label="Invalid" size="small" variant="outlined" style={{ marginLeft: "8px", borderColor: "#fff" }} />
                )}
              </StyledTableCell>
              <StyledTableCell align="right" style={{ fontFamily: "Cutive Mono", fontWeight: "normal", fontSize: "1.03em" }}>
                {formatTime(row.time)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const formatTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(14, -1);
};

const StyledTableCell = styled(TableCell)`
  height: 36px;
`;
