import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import { useTheme } from "@material-ui/core";
import { BodyText } from "components/primitives";
import styled from "styled-components";
import { barChartConfig, chartConfig } from "./chartConfig";

type ComparisonChartProps = {
  aValue: number;
  aKey: string;
  bValue: number;
  bKey: string;
  name: string;
  barSize?: number;
  radius?: [number, number, number, number];
};

export const ComparisonChart = ({ aValue, aKey, bValue, bKey, name, barSize = 40, radius = [4, 4, 0, 0] }: ComparisonChartProps) => {
  const theme = useTheme();

  const [data, setData] = useState([{ name: name, [aKey]: aValue || 10, [bKey]: bValue || 10 }]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (aKey === bKey) bKey = `${bKey} 2`;
    setData([{ name: name, [aKey]: aValue || 10, [bKey]: bValue || 10 }]);
  }, [aKey, aValue, bKey, bValue, name]);

  if (aKey === bKey) {
    aKey = `${aKey} (L)`;
    bKey = `${bKey} (R)`;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" }}>
      <ResponsiveContainer width="100%" aspect={chartConfig.aspectRatio}>
        <BarChart data={data} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <Bar dataKey={aKey} fill={theme.palette.primary.main} barSize={barSize} radius={radius} {...barChartConfig} />
          <Bar dataKey={bKey} fill="#Fff" barSize={barSize} radius={radius} {...barChartConfig} />
          <XAxis tick={false} dataKey="name" height={1} />
          <YAxis width={32} domain={[0, 100]} />
          <Tooltip cursor={{ opacity: 0.05 }} contentStyle={{ backgroundColor: "#424242", color: "#fff" }} />
        </BarChart>
      </ResponsiveContainer>

      <StyledBodyText>{name.toUpperCase()}</StyledBodyText>
    </div>
  );
};

const StyledBodyText = styled(BodyText)`
  text-align: center;
  margin-left: 32px;
`;
