import React from "react";
import { Form, FormItem, SubmitButton, Markdown } from "components/primitives";
import * as yup from "yup";
import { QuestionFormItem } from "./QuestionFormItem";
import { Hidden } from "@material-ui/core";
import styled from "styled-components";
import { requestToJoinGroup } from "api/group";

type JoinGroupFormProps = {
  questions: SignUpQuestionModel[];
  signUpText: string;
  slug: string;
  onJoinRequested: (groupModel: GroupModel) => void;
  previewMode?: boolean;
};

export const JoinGroupForm = ({ slug, questions, signUpText, onJoinRequested, previewMode }: JoinGroupFormProps) => {
  const handleSubmit = async (data: Record<string, any>) => {
    const response = await requestToJoinGroup(slug, { answers: Object.keys(data).map((k) => ({ questionId: k, answer: data[k] })) });

    onJoinRequested(response.data);
  };

  const schema = yup.object(
    questions.reduce((questionSchema, question) => {
      questionSchema[question.id?.toString() || "0"] = yup.string().required(`'${question.label}' is required`);
      return questionSchema;
    }, {} as { [key: string]: any })
  );

  return (
    <>
      <SignUpText>
        <Markdown>{signUpText}</Markdown>
      </SignUpText>
      <Form onSubmit={handleSubmit} schema={schema}>
        {questions.map((q) => (
          <React.Fragment key={q.id}>
            <FormItem md={6} lg={4}>
              <QuestionFormItem question={q} previewMode={previewMode} />
            </FormItem>
            <Hidden smDown>
              <FormItem md={6} lg={8} />
            </Hidden>
          </React.Fragment>
        ))}
        <FormItem>
          <SubmitButton disabled={previewMode}>
            {questions.length > 0 && "Request to Join"}
            {questions.length === 0 && "Join now"}
          </SubmitButton>
        </FormItem>
      </Form>
    </>
  );
};

const SignUpText = styled.div`
  margin-bottom: ${(props) => props.theme.spacing * 4}px;
`;
