import { getActivityById } from "..";

export const waitForVideoProcessingToComplete = (activityId: string) => {
  return new Promise<ActivityModel>((resolve, reject) => {
    const pollForProcessingComplete = async () => {
      try {
        const getActivityResponse = await getActivityById(activityId);
        if (getActivityResponse.data.videoEncodingStatus === "Complete") {
          resolve(getActivityResponse.data);
        } else if (getActivityResponse.data.videoEncodingStatus === "Failed") {
          reject();
        } else {
          setTimeout(pollForProcessingComplete, 500);
        }
      } catch {
        setTimeout(pollForProcessingComplete, 500);
      }
    };

    pollForProcessingComplete();
  });
};
