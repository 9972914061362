import React from "react";
import { PageGrid, GroupDashboardView } from "components/ui";
import { Helmet } from "react-helmet";
import { LoadingPanel } from "components/primitives";
import { useParams } from "react-router-dom";
import { useApiData } from "plumbing/api";
import { getGroupBySlug } from "api/group";

export const GroupDashboard = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: groupDetails, loadingState, setData, fetchData } = useApiData(() => getGroupBySlug(slug));

  const handleChange = async (model: GroupModel | undefined): Promise<void> => {
    if (model) setData(model);
    else await fetchData();
  };
  return (
    <>
      <Helmet>
        <title> Digital Academy - {groupDetails ? groupDetails.name : "Group"}</title>
      </Helmet>
      <PageGrid>
        <LoadingPanel loadingState={[loadingState]}>
          {groupDetails && <GroupDashboardView group={groupDetails} onChange={handleChange} />}
        </LoadingPanel>
      </PageGrid>
    </>
  );
};
