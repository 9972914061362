import ReactGA from "react-ga";
import { AnalyticsConfiguration } from "./AnalyticsConfiguration";

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  ReactGA.initialize(AnalyticsConfiguration.universalTrackingId, {
    debug: isDev,
  });
};

export type AnalyticsEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
};

export type AnalyticsSocialEvent = {
  socialNetwork: "Facebook" | "Twitter" | "Instagram";
  socialAction: "share" | "like";
  socialTarget?: string;
};

const sendEvent = (payload: AnalyticsEvent) => {
  ReactGA.event(payload);
};

const sendPageview = (path: string) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

const sendSocial = (social: AnalyticsSocialEvent) => {
  ReactGA.ga("send", "social", social);
};

const sendFromWebVitals = ({ name, delta, id }: any) => {
  ReactGA.ga("send", "event", {
    eventCategory: "Web Vitals",
    eventAction: name,
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    eventLabel: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    eventValue: Math.round(name === "CLS" ? delta * 1000 : delta),
    // Use a non-interaction event to avoid affecting bounce rate.
    nonInteraction: true,
    // Use `sendBeacon()` if the browser supports it.
    transport: "beacon",

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // dimension1: '...',
    // dimension2: '...',
    // ...
  });
};

export const analytics = {
  init,
  sendEvent,
  sendPageview,
  sendSocial,
  sendFromWebVitals,
};
