import React from "react";
import { FormControl, FormHelperText, TextField, Checkbox, Chip, Grid } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { FormItem } from "..";
import get from "lodash/get";

type AutocompleteFieldProps<T> = {
  options: T[];
  label: string;
  name: string;
  fullWidth?: boolean;
  multiple?: boolean;
  getOptionLabel: (option: T) => string;
  defaultValue?: T[];
  disabled?: boolean;
};

export const AutocompleteField = <T extends {}>({ label, name, multiple, options, getOptionLabel, defaultValue = [], fullWidth = true, disabled }: AutocompleteFieldProps<T>) => {
  const { control, errors, watch } = useFormContext();

  const value = watch(name);

  return (
    <FormControl fullWidth error={get(errors, name)?.message !== undefined}>
      <Controller
        render={(props) => (
          <MuiAutocomplete
            {...props}
            value={value}
            fullWidth={fullWidth}
            multiple={multiple}
            options={options}
            getOptionLabel={getOptionLabel}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox style={{ padding: 0, paddingRight: 8 }} checked={selected} color="primary" />
                {getOptionLabel(option)}
              </React.Fragment>
            )}
            renderInput={(params) => <TextField {...params} label={label} />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant="outlined" color="primary" label={getOptionLabel(option)} size="small" {...getTagProps({ index })} />)
            }
            onChange={(_, data) => props.onChange(data)}
            disabled={disabled}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText>{get(errors, name)?.message}</FormHelperText>
    </FormControl>
  );
};

export const AutocompleteFieldFormItem = <T extends {}>({ xs = 12, sm, md, lg, fullRow, ...props }: AutocompleteFieldProps<T> & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <AutocompleteField {...props} />
    </FormItem>
  );

  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
