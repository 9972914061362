import { extractGoProMetadata } from "services/GoProMetadataService";
import { TaskProgressCallback, initTasks, progressUpdater } from "./taskProgress";

export type VideoData = { [key: string]: { data: string, wetWeather: boolean } };
export const extractData = async (videos: DrillVideo[], onDataExtractProgress: TaskProgressCallback) => {
  const dataExtractTasks = initTasks(videos, (v) => v.drillName);
  const processedVideos: VideoData = {};
  const updateProgress = progressUpdater(onDataExtractProgress);

  for (var i = 0; i < videos.length; i++) {
    const video = videos[i];

    const extractedVideo = await extractGoProMetadata(video.file, (event: any) => {
      const percentage = Math.round((100.0 * event) / 100.0);
      dataExtractTasks[video.drillName] = percentage;
      updateProgress(dataExtractTasks);
    });

    processedVideos[video.drillName] = {
      data: extractedVideo.data[1],
      wetWeather: video.wetWeather
    };

    dataExtractTasks[video.drillName] = 100;
    updateProgress(dataExtractTasks);
  }

  return processedVideos;
};
