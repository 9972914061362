import React from "react";
import styled from "styled-components";
import { Link, BodyText } from "components/primitives";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

export const VideoThumbnail = ({ video }: { video: TrainingVideoModel }) => {
  return (
    <VideoThumbNailContainer to={`/training/videos/${video.id}`}>
      <PlayIconContainer>
        <StyledPlayCircleFilledIcon />
      </PlayIconContainer>
      <ThumbnailImage src={video.thumbnailUrl} />
      <ThumbnailCaption>{video.name}</ThumbnailCaption>
    </VideoThumbNailContainer>
  );
};

const StyledPlayCircleFilledIcon = styled(PlayCircleFilledIcon)`
  fill: white;
  font-size: 50px;
`;

const VideoThumbNailContainer = styled(Link)`
  position: relative;
  display: flex;
  :hover {
    text-decoration: none;
    ${StyledPlayCircleFilledIcon} {
      opacity: 0.6;
    }
  }
`;

const PlayIconContainer = styled.div`
  position: absolute;
  height: 195px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbnailImage = styled.img`
  object-fit: cover;

  height: 195px;
  width: 100%;
`;
const ThumbnailCaption = styled(BodyText)`
  position: absolute;
  bottom: ${(props) => props.theme.spacing}px;
  left: ${(props) => props.theme.spacing}px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: ${(props) => props.theme.spacing}px ${(props) => props.theme.spacing * 2}px;
`;
