import { getLeaderboard } from "api/leaderboard";
import { useApiData } from "plumbing/api";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import CompareIcon from "@material-ui/icons/CompareArrows";
import {
  DataGrid,
  GridRowsProp,
  GridCellParams,
  GridPageChangeParams,
  GridOverlay,
  GridSortModel,
  GridSortModelParams,
  GridColumns,
  GridColumnHeaderParams,
  GridSortDirection,
  GridCellClassParams,
  useGridSlotComponentProps,
} from "@material-ui/data-grid";
import { Link, TablePagination } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Autocomplete, BodyText } from "components/primitives";
import { searchLeaderboardRiders } from "api/leaderboardRider";
import { LeaderboardContext } from "./LeaderboardContext";
import { analytics } from "plumbing/analytics";
import { Select, MenuItem, Grid } from "@material-ui/core";

const CustomLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

type LeaderboardProps = {
  activityType?: string;
  activityId?: number;
  groupSlug?: string;
  challengeSlug?: string;
  showTrack?: boolean;
};

const CustomPagination = () => {
  const data = useContext(LeaderboardContext);
  const { state, apiRef } = useGridSlotComponentProps();

  const handleChangePage = (event: any, newPageNumber: number) => {
    apiRef.current.setPage(newPageNumber);
  };

  return (
    <TablePagination
      component="div"
      count={data?.totalRowsToShow || 0}
      page={state.pagination.page}
      onChangePage={handleChangePage}
      rowsPerPage={data?.pageSize || 0}
      rowsPerPageOptions={[data?.pageSize || 0]}
    />
  );
};

const sortingOrder: GridSortDirection[] = ["desc", "asc"];
const dataGridComponents = {
  Pagination: CustomPagination,
  LoadingOverlay: CustomLoadingOverlay,
  NoRowsOverlay: () => (
    <GridOverlay>
      <BodyText>No activities have been uploaded yet</BodyText>
    </GridOverlay>
  ),
};

export const Leaderboard = ({ activityType, activityId, groupSlug, challengeSlug, showTrack }: LeaderboardProps) => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "score", sort: "desc" }]);
  const [selectedRider, setSelectedRider] = useState<LeaderboardRiderModel | null>(null);
  const [serverSpotlightRider, setServerSpotlightRider] = useState<LeaderboardRiderModel | null>(null);
  const [activityUsersFilter, setActivityUsersFilter] = useState<ActivityFeedUserFilter>("Everyone");

  const { data, loadingState } = useApiData(
    () => {
      const request: GetLeaderboardQueryRequest = {
        activityType: activityType,
        groupSlug: groupSlug,
        challengeSlug: challengeSlug,
        pageNumber: page,
        pageSize: pageSize,
        sortField: sortModel.length ? sortModel[0].field : undefined,
        sortDir: sortModel.length ? sortModel[0].sort : undefined,
        spotlightRiderId: serverSpotlightRider?.riderId,
        activityUsersFilter: activityUsersFilter,
      };

      return getLeaderboard(request);
    },
    undefined,
    [page, sortModel, selectedRider, activityUsersFilter]
  );

  const handleActivityUsersFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const usersFilter = event.target.value as ActivityFeedUserFilter;
    setActivityUsersFilter(usersFilter);
  };

  const handlePageChange = useCallback(
    (params: GridPageChangeParams) => {
      if (page !== params.page && (!loadingState || !loadingState.isLoading)) {
        setPage(params.page);
      }
    },
    [page, loadingState]
  );

  const handleSortModelChange = useCallback(
    (params: GridSortModelParams) => {
      if (params.sortModel !== sortModel) {
        setSortModel(params.sortModel);
        setPage(0);
      }
    },
    [sortModel]
  );

  const renderHeader = (params: GridColumnHeaderParams) => {
    const currentSortModel = params.api.getSortModel();
    return <ColumnHeader isActive={currentSortModel.length && params.field === currentSortModel[0].field}>{params.colDef.headerName}</ColumnHeader>;
  };

  const stickyRowClass = (gccp: GridCellClassParams): string | string[] => (gccp.row.stickyRow === 1 ? "sticky-row" : "");

  const [columns] = useState<GridColumns>([
    {
      field: "position",
      headerName: "#",
      width: 54,
      sortable: false,
      cellClassName: stickyRowClass,
      renderCell: (params: GridCellParams) => {
        const currentSortModel = params.api.getSortModel();
        const sortField = currentSortModel?.length ? currentSortModel[0].field : "position";

        switch (sortField) {
          case "throttleScore":
            return <span>{params.row.throttleRank}</span>;
          case "brakingScore":
            return <span>{params.row.brakingRank}</span>;
          case "steeringScore":
            return <span>{params.row.steeringRank}</span>;
          default:
            return <span>{params.row.position}</span>;
        }
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 210,
      sortable: false,
      cellClassName: stickyRowClass,
      renderCell: (params: GridCellParams) => {
        const rowActivityId = params.row.activityId;

        return (
          <div style={{ lineHeight: 1 }}>
            {rowActivityId !== activityId ? (
              <Link component={RouterLink} to={`/activities/${rowActivityId}`}>
                {params.value}
              </Link>
            ) : (
              <div>{params.value}</div>
            )}
            <small style={{ display: "block", height: "auto", lineHeight: 1, marginTop: "5px", fontSize: "0.75em" }}>
              {params.row.bikeMake} {params.row.bikeModel}
            </small>
          </div>
        );
      },
    },
    {
      field: "activityType",
      headerName: "Track",
      width: 150,
      cellClassName: stickyRowClass,
      renderHeader: renderHeader,
      hide: !showTrack,
    },
    {
      field: "score",
      headerName: "Overall",
      width: 80,
      cellClassName: stickyRowClass,
      renderHeader: renderHeader,
    },
    { field: "throttleScore", headerName: "Throttle", width: 80, renderHeader: renderHeader, cellClassName: stickyRowClass },
    { field: "brakingScore", headerName: "Braking", width: 80, renderHeader: renderHeader, cellClassName: stickyRowClass },
    { field: "steeringScore", headerName: "Steering", width: 80, renderHeader: renderHeader, cellClassName: stickyRowClass },
    {
      field: "",
      headerName: "",
      sortable: false,
      width: 36,
      hide: !activityId,
      cellClassName: stickyRowClass,
      renderCell: (params: GridCellParams) => {
        const rowActivityId = params.row.activityId;
        return rowActivityId !== activityId ? (
          <Link
            component={RouterLink}
            to={`/compare-activities?activity1Id=${activityId}&activity2Id=${rowActivityId}`}
            style={{ display: "flex" }}
            title="Compare with this activity"
          >
            <CompareIcon fontSize="small" />
          </Link>
        ) : (
          <span />
        );
      },
    },
  ]);

  const [rows, setRows] = useState<GridRowsProp>([]);
  useEffect(() => {
    if (data) {
      setPage(data.pageNumber);
      setServerSpotlightRider(null);
      const idForSticky = (r: LeaderboardRowModel) => {
        if (r.stickyRow === 1) {
          return 0;
        }
        return r.activityId;
      };
      setRows(data?.items.map((r) => ({ ...r, fullName: `${r.name}`, id: idForSticky(r) })));
    }
  }, [activityId, columns, data]);

  const handleAutocompleteInputChange = async (value: string) => {
    if (!value) {
      return [];
    }
    const searchResponse = await searchLeaderboardRiders({ activityType, groupSlug, challengeSlug, searchQuery: value });
    return searchResponse.data.riders;
  };

  const handleAutocompleteChange = (value: LeaderboardRiderModel | null) => {
    analytics.sendEvent({ category: "Leaderboard", action: "Search", label: value?.fullName });
    setSelectedRider(value);
    setServerSpotlightRider(value);
  };

  const [selectionModel, setSelectionModel] = useState<number[]>([]);
  useEffect(() => {
    const value = selectedRider && data ? data?.items.filter((r) => r.riderId === selectedRider.riderId).map((r) => r.activityId) : [];
    setSelectionModel(value);
  }, [data, selectedRider]);

  return (
    <LeaderboardContext.Provider value={data}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            label="Search riders..."
            onSearch={handleAutocompleteInputChange}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            onSelected={handleAutocompleteChange}
            value={selectedRider}
            getOptionSelected={(a, b) => {
              return a.riderId === b.riderId;
            }}
            disabled={!data || data.items.length === 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select value={activityUsersFilter} onChange={handleActivityUsersFilterChange} style={{ float: "right", marginTop: "16px" }}>
            <MenuItem value="Everyone">Everyone</MenuItem>
            <MenuItem value="Mine">My Activities</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <StyledDataGrid
        columns={columns}
        rows={rows}
        density="compact"
        autoHeight
        disableSelectionOnClick
        loading={loadingState.isLoading}
        rowCount={data?.totalRows || 0}
        pageSize={pageSize}
        paginationMode="server"
        page={page}
        onPageChange={handlePageChange}
        sortingOrder={sortingOrder}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        hideFooterSelectedRowCount
        disableColumnMenu
        components={dataGridComponents}
        rowHeight={72}
      />
    </LeaderboardContext.Provider>
  );
};

const StyledDataGrid = styled(DataGrid)`
  border: none;
  margin-top: ${(props) => props.theme.spacing * 4}px;
  min-height: 451px;
  .MuiDataGrid-colCell,
  .MuiDataGrid-cell {
    padding: 0 ${(props) => props.theme.spacing * 2}px;
  }

  .sticky-row {
    background-color: rgba(159, 255, 0, 0.08);
  }
  .MuiDataGrid-iconButtonContainer {
    display: none;
  }
`;

const ColumnHeader = styled(({ isActive, children, ...props }) => <div {...props}>{children}</div>)<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? "500" : "normal")};
  text-decoration: ${(props) => (props.isActive ? "underline" : "none")};
`;
