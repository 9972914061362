import React from "react";
import { Typography } from "@material-ui/core";
import { Subheading, BodyText } from "components/primitives";
import { InfoTooltip } from ".";
import { TelemetryLineChart } from "./TelemetryLineChart";
import { ReferenceLines } from "AdminConstants";
import styled from "styled-components";

type BrakingChartProps = {
  data: DataPoint[];
  activityType: string;
  activitySkillType: string;
  dataVersion: string;
};

var SkillsGraph_TrackGP_Braking_ReferenceLine = {
  x: [0,],
  y: [0,],
};
var length = 0;
export const BrakingChart = ({ data, activitySkillType, activityType, dataVersion }: BrakingChartProps) => {
  const [mappedData, setMappedData] = React.useState<DataPoint[]>([]);

  React.useEffect(() => {
    setMappedData(data.map((d) => ({ ...d })));
  }, [data]);

  length = mappedData.length - 300;
  var ArrayNew = [];
  for (let i = 0; i < length; i++) {
    ArrayNew.push((i-length-100)/100);
  }

  /*{
    mappedData.length > 301 && (
        SkillsGraph_TrackGP_Braking_ReferenceLine = {
        x: Array().concat(ArrayNew, [-1, 2,]),
        y: Array().concat(Array(length).fill(-1.1), [-1.1, 1,]),
      })}
      */
  return (
    <BrakingChartContainer>
      <Subheading gutterBottom>
        Braking{" "}
        <InfoTooltip
          activitySkillType={activitySkillType}
          streetText={
            <Typography>
              This graph shows the braking effort throughout your emergency stop. The goal is to maximise the braking effort to ensure the
              bike comes to a complete stop as soon as possible. This is done by progressively increasing the braking effort, and by
              maintaining a high maximum.
            </Typography>
          }
          trackText={
            <Typography>
              This graph shows your best braking effort into a corner. The goal is to maximise the braking effort as late as possible into
              the corner, whilst trail braking through to the apex.
            </Typography>
          }
        />
      </Subheading>

      {!mappedData.length && <StyledBodyText>Braking chart is not currently available</StyledBodyText>}

      {mappedData.length > 0 && activitySkillType === "Street" && (
        <TelemetryLineChart
          dataVersion={dataVersion}
          data={mappedData}
          referenceData={ReferenceLines.Street.Braking}
          xAxisLabel="Distance - M"
          yAxisLabel="G-Force - G"
          showXZero={true}
          showYZero={false}
          xFeatureValue={mappedData[mappedData.length - 1].X}
          featureLabel={mappedData[mappedData.length - 1].X !== undefined ? mappedData[mappedData.length - 1].X?.toFixed(2) : undefined}
          activitySkillType = {activitySkillType}
        />
      )}


      {mappedData.length > 0 && activitySkillType === "Track" && (

        <TelemetryLineChart
          dataVersion={dataVersion}
          data={mappedData}
          referenceData={activityType === "trackSKILLS 101" ? ReferenceLines.TrackSkills101.Braking : SkillsGraph_TrackGP_Braking_ReferenceLine}
          xAxisLabel={isVersionAtLeast("1.0.632", dataVersion) ? "Time - sec" : "Distance - M"}
          yAxisLabel="G-Force - G"
          showXZero={true}
          showYZero={true}
          featureLabel="Apex"
          activitySkillType={activitySkillType}
        />
      )}
    </BrakingChartContainer>
  );
};

const BrakingChartContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
`;

const StyledBodyText = styled(BodyText)`
  height: 100%;
`;


const isVersionAtLeast = (requiredVersion: string, version: string): boolean => {
  // Version is a string in three parts "X.Y.Z[.A]"
  const requiredVersionSegments: number[] = requiredVersion.split(".").map((x) => parseInt(x, 10));
  const versionSegments: number[] = (version ?? "0.0.0.0").split(".").map((x) => parseInt(x, 10));

  if (requiredVersionSegments.length < 3 || versionSegments.length < 3) {
    console.dir(`Algorithm version ${version} is not in the expected format.  Assuming versions don't match for graph calculations`);
    return false;
  }

  if (versionSegments[0] < requiredVersionSegments[0]) return false;
  if (versionSegments[0] > requiredVersionSegments[0]) return true;
  if (versionSegments[1] < requiredVersionSegments[1]) return false;
  if (versionSegments[1] > requiredVersionSegments[1]) return true;
  if (versionSegments[2] < requiredVersionSegments[2]) return false;

  return true;
};
