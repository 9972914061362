import React, { useState } from "react";
import { joinChallenge, completeJoinChallengeRequest } from "api/challenge";
import { Link, Form, FormItem, CheckboxField, SubmitButton, CheckoutForm } from "components/primitives";
import { Markdown } from "components/primitives";
import styled from "styled-components";
import * as yup from "yup";

export type JoinChallengeProps = {
  challengeModel: ChallengeModel;
  slug: string;
  onJoinRequested: (challengeModel: ChallengeModel) => void;
  previewMode?: boolean;
};

export const JoinChallenge = ({ challengeModel, slug, onJoinRequested, previewMode }: JoinChallengeProps) => {
  const schema = yup.object().shape({
    hasAcceptedTerms: !!challengeModel.termsAndConditionsUrl
      ? yup.boolean().oneOf([true], "Please accept the Terms and Conditions")
      : yup.boolean(),
  });
  const [joinChallengeResponse, setJoinChallengeResponse] = useState<JoinChallengeResponse | undefined>();
  const handleJoinChallenge = async () => {
    const response = await joinChallenge(slug);

    setJoinChallengeResponse(response.data);
    if (challengeModel.signUpPrice && challengeModel.signUpPrice > 0) setIsPaymentDialogOpen(true);
    else onJoinRequested(response.data);
  };

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const handleCheckoutClose = async (paymentSucceeded: boolean) => {
    if (paymentSucceeded) {
      const response = await completeJoinChallengeRequest(slug);
      onJoinRequested(response.data);
    }

    setIsPaymentDialogOpen(false);
  };
  return (
    <>
      <SignUpText>
        <Markdown>{challengeModel.signUpText}</Markdown>
      </SignUpText>
      <Form onSubmit={handleJoinChallenge} schema={schema}>
        {!!challengeModel.termsAndConditionsUrl && (
          <FormItem>
            <CheckboxField
              name="hasAcceptedTerms"
              label={
                <>
                  I accept the <Link href={challengeModel.termsAndConditionsUrl}>Terms and Conditions</Link>
                </>
              }
            />
          </FormItem>
        )}
        <FormItem>
          <SubmitButton disabled={previewMode}>
            {challengeModel && challengeModel.signUpPrice && challengeModel.signUpPrice > 0 ? "Continue to payment" : "Join"}
          </SubmitButton>
        </FormItem>
      </Form>

      {joinChallengeResponse && joinChallengeResponse.paymentIntentClientSecret && (
        <CheckoutForm
          isOpen={isPaymentDialogOpen}
          onClose={handleCheckoutClose}
          paymentIntentClientSecret={joinChallengeResponse?.paymentIntentClientSecret}
          productName={challengeModel.name}
          productPrice={challengeModel.signUpPrice!}
          productImageUrl={challengeModel.imageUrl}
        />
      )}
    </>
  );
};

const SignUpText = styled.div`
  margin-bottom: ${(props) => props.theme.spacing * 4}px;
`;
