import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

type SubheadingProps = {
  gutterBottom?: boolean;
  children: React.ReactNode;
};

export const Subheading = ({ children, ...props }: SubheadingProps) => {
  return (
    <StyledTypography variant="h5" {...props}>
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)``;
