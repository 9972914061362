// Constants in this file should be maintained by Steve
const SkillsGraph_TrackGP_Steering_ReferenceLine = {
  x: [-34,-33,-32,-31,-30,-29,-28,-27,-26,-25,-24,-23,-22,-21,-20,-19,-18,-17,-16,-15,-14,-13,-12,-11,-10,-9,-8,-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,],
  y: [13.609,15.82,17.917,19.907,21.798,23.598,25.313,26.952,28.521,30.026,31.472,32.866,34.212,35.516,36.785,38.022,39.232,40.411,41.556,42.663,43.729,44.749,45.72,46.638,47.499,48.3,49.037,49.709,50.314,50.85,51.315,51.708,52.027,52.27,52.435,52.521,52.53,52.467,52.335,52.141,51.887,51.578,51.22,50.815,50.37,49.887,49.37,48.822,48.242,47.635,47,46.341,45.659,44.957,44.234,43.494,42.733,41.947,41.134,40.291,39.413,38.499,37.545,36.548,35.504,34.409,33.259,32.05,30.779,],
};

const SkillsGraph_TrackGP_Braking_ReferenceLine = {
  x: [-47,-46,-45,-44,-43,-42,-41,-40,-39,-38,-37,-36,-35,-34,-33,-32,-31,-30,-29,-28,-27,-26,-25,-24,-23,-22,-21,-20,-19,-18,-17,-16,-15,-14,-13,-12,-11,-10,-9,-8,-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,],
  y: [-0.98,-0.978,-0.978,-0.979,-0.981,-0.982,-0.985,-0.986,-0.988,-0.989,-0.989,-0.989,-0.987,-0.985,-0.982,-0.979,-0.976,-0.973,-0.969,-0.963,-0.956,-0.946,-0.933,-0.917,-0.897,-0.874,-0.848,-0.818,-0.784,-0.747,-0.706,-0.661,-0.612,-0.559,-0.503,-0.445,-0.384,-0.323,-0.263,-0.207,-0.157,-0.114,-0.078,-0.052,-0.032,-0.019,-0.009,-0.003,0.003,0.011,0.021,0.038,],
};

const SkillsGraph_TrackGP_Throttle_ReferenceLine = {
  x: [-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,],
  y: [-0.169,-0.141,-0.099,-0.047,0.014,0.081,0.153,0.226,0.299,0.369,0.435,0.496,0.551,0.6,0.643,0.682,0.716,0.748,0.778,0.805,0.831,0.854,0.874,0.892,0.908,0.921,0.932,0.941,0.95,0.957,0.964,0.971,0.976,0.981,0.986,0.99,0.993,0.996,0.998,0.999,0.999,0.997,0.995,],
};

const SkillsGraph_Street_Throttle_ReferenceLine = {
  x: [
    -3.5,
    -3,
    -2.5,
    -2,
    -1.5,
    -1,
    -0.5,
    0,
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    12.5,
  ],
  y: [
    -0.243,
    -0.23,
    -0.212,
    -0.191,
    -0.163,
    -0.127,
    -0.081,
    -0.022,
    0.053,
    0.142,
    0.243,
    0.353,
    0.457,
    0.555,
    0.643,
    0.719,
    0.781,
    0.836,
    0.881,
    0.914,
    0.939,
    0.958,
    0.971,
    0.98,
    0.987,
    0.993,
    0.997,
    1,
    1.003,
    1.005,
    1.006,
    1.007,
    1.008,
  ],
};

const SkillsGraph_Street_Braking_ReferenceLine = {
  x: [0,0.05,0.1,0.15,0.2,0.25,0.3,0.35,0.4,0.45,0.5,0.55,0.6,0.65,0.7,0.75,0.8,0.85,0.9,0.95,1,1.05,1.1,1.15,1.2,1.25,1.3,1.35,1.4,1.45,3,3.1,3.2,3.3,3.4,3.5,3.6,3.7,3.8,3.9,4,4.1,4.2,4.3,4.4,4.5,4.6,4.7,4.8,4.9,5,5.1,5.2,5.3,5.4,5.5,5.6,5.7,5.8,5.9,6,6.1,6.2,6.3,6.4,6.5,6.6,6.7,6.8,6.9,7,7.1,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,8,8.1,8.2,8.3,8.4,8.5,8.6,8.7,8.8,8.9,9,9.1,9.2,9.3,9.4,9.5,9.6,9.7,9.8,9.9,],
  y: [0.026,0.096,0.166,0.236,0.299,0.36,0.421,0.482,0.534,0.576,0.617,0.659,0.7,0.742,0.783,0.801,0.819,0.837,0.855,0.873,0.891,0.909,0.928,0.946,0.96,0.966,0.973,0.98,0.986,0.993,1.1,1.1077,1.1143,1.122,1.1297,1.1363,1.144,1.1517,1.1583,1.1638,1.1682,1.1726,1.1781,1.1825,1.1869,1.1913,1.1968,1.2012,1.2056,1.21,1.2155,1.2199,1.2243,1.2287,1.2309,1.2331,1.2364,1.2386,1.2408,1.2441,1.2463,1.2485,1.2518,1.254,1.2562,1.2584,1.2617,1.2639,1.2661,1.2694,1.2716,1.2749,1.2771,1.2804,1.2826,1.2859,1.2881,1.2914,1.2936,1.2969,1.2991,1.3024,1.3046,1.3057,1.3068,1.3068,1.3079,1.3079,1.3079,1.309,1.309,1.3079,1.3057,1.3024,1.3002,1.2364,1.0461,0.8316,0.5126,0.1749,],
};

export const ReferenceLines = {
  Track: {
    Throttle: SkillsGraph_TrackGP_Throttle_ReferenceLine,
    Braking: SkillsGraph_TrackGP_Braking_ReferenceLine,
    Steering: SkillsGraph_TrackGP_Steering_ReferenceLine,
  },
  TrackSkills101: {
    Throttle: SkillsGraph_TrackGP_Throttle_ReferenceLine,
    Braking: SkillsGraph_TrackGP_Braking_ReferenceLine,
    Steering: SkillsGraph_TrackGP_Steering_ReferenceLine,
  },
  Street: {
    Throttle: SkillsGraph_Street_Throttle_ReferenceLine,
    Braking: SkillsGraph_Street_Braking_ReferenceLine,
  },
};
