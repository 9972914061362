import axios from "axios";
import { apiConfiguration } from "plumbing/api";

export const abortMultipartFileUpload = async (fileName: string, uploadId: string) => {
  const url = `${apiConfiguration.apiUrl}/multipart-file-upload/abort`;
  await axios.post(url, {
    fileName,
    uploadId,
  });
}
