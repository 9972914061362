import React from "react";
import { CircularProgress } from "@material-ui/core";

interface LoadingSpinnerProps {
  className?: string;
  small?: boolean;
}

export const LoadingSpinner = ({ className, small }: LoadingSpinnerProps) => {
  return <CircularProgress className={className} size={small ? 20 : undefined} />;
};
