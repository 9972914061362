import React from "react";
import Helmet from "react-helmet";
import { PageGrid } from "components/ui";
import { Card, CardContent } from "@material-ui/core";
import { Form, TextFieldFormItem, FormItem, SubmitButton, Heading, LinkButton } from "components/primitives";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { addRiderToGroup } from "api/group";

export const AddGroupRider = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const groupUrl = `/groups/${slug}?view=Members`;

  const handleFormSubmit = async (formValues: AddGroupRiderFormValues) => {
    await addRiderToGroup(slug, formValues);
    history.push(groupUrl);
  };

  return (
    <>
      <Helmet>
        <title> Digital Academy - Add Rider to Group</title>
      </Helmet>
      <PageGrid>
        <Card>
          <CardContent>
            <Heading>Add Group Member</Heading>
            <Form onSubmit={handleFormSubmit} schema={addGroupRiderSchema}>
              <TextFieldFormItem name="firstName" label="First Name" lg={4} autoFocus />
              <TextFieldFormItem name="lastName" label="Last Name" lg={4} />
              <TextFieldFormItem name="email" label="Email" lg={4} />
              <FormItem>
                <LinkButton to={groupUrl} variant="outlined">
                  Cancel
                </LinkButton>
                <div style={{ display: "inline-block", marginLeft: "8px" }}>
                  <SubmitButton>Add </SubmitButton>
                </div>
              </FormItem>
            </Form>
          </CardContent>
        </Card>
      </PageGrid>
    </>
  );
};

const addGroupRiderSchema = yup.object().shape({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  email: yup.string().email().required().label("Email"),
});

type AddGroupRiderFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};
