import Helmet from "react-helmet";
import { PageGrid, ActivityFeed, RiderGroups, RiderChallenges } from "components/ui";
import { Container } from "@material-ui/core";
import styled from "styled-components";
import { SimpleCard } from "components/primitives";
import React from 'react';

export const Home = () => {
  return (
    <>
      <Helmet>
        <title> Digital Academy - Home</title>
      </Helmet>
      <PageGrid>
        <PageLayout>
          <LeftColumn>
            <SimpleCard>
              <RiderGroups forceSmallSize />
            </SimpleCard>
          </LeftColumn>
          <ActivityFeedContainer fixed maxWidth="md">
            <ActivityFeed showUsersFilter={true} showSkillsFilter={true}  />
          </ActivityFeedContainer>
          <RightColumn>
            <SimpleCard>
              <RiderChallenges forceSmallSize />
            </SimpleCard>
          </RightColumn>
        </PageLayout>
      </PageGrid>
    </>
  );
};


const ActivityFeedContainer = styled(Container)`
  grid-area: activity-feed;
  padding: 0;
`;

const PageLayout = styled.div`
  display: grid;
  grid-template: "left activity-feed right";
  grid-template-columns: 1fr 630px 1fr;
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-template: "activity-feed";
    grid-template-columns: 1fr;
  }
`;

const RightColumn = styled.div`
  grid-area: right;
  margin-top: 56px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    display: none;
  }
`;

const LeftColumn = styled.div`
  grid-area: left;
  margin-top: 56px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    display: none;
  }
`;
