/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import Helmet from "react-helmet";
import { PageGrid, UploadActivityVideosForm } from "components/ui";
import { Heading, LinkButton, LoadingPanel, SimpleCard } from "components/primitives";
import { useParams } from "react-router-dom";
import { getActivityById } from "api/activity";
import { useApiData } from "plumbing/api";
import { uploadVideos } from "api/activity/uploadActivity/uploadVideos";
import { VideoUploadProgress } from "components/ui/UploadActivity/UploadProgress/VideoUploadProgress";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { waitForVideoProcessingToComplete } from "api/activity/uploadActivity/waitForVideoProcessingToComplete";

export const UploadActivityVideosPage = () => {
  const [message, setMessage] = useState("Uploading video");
  const [isUploading, setIsUploading] = useState(false);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [hasUploadFailed, setHasUploadFailed] = useState(false);
  const [isEncodingComplete, setIsEncodingComplete] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data: activity, loadingState } = useApiData(
    () => {
      return getActivityById(id);
    },
    undefined,
    [id]
  );
  const handleSubmit = async (formData: UploadActivityVideosFormData) => {
    setIsUploading(true);
    setHasUploadFailed(false);
    setVideoUploadProgress(0);
    const activityId = activity!.id.toString();

    const videos = Object.entries(formData)
      .filter((entry) => entry[0].startsWith("videos-"))
      .map((entry) => ({
        drillName: entry[0].substring(7),
        dataOnly: false,
        wetWeather: false,
        file: entry[1][0],
      }));

    uploadVideos(activityId, videos, setVideoUploadProgress)
      .then(() => {
        setMessage("Processing video");
        return waitForVideoProcessingToComplete(activityId);
      })
      .then(() => {
        setIsEncodingComplete(true);
        setVideoUploadProgress(100);
      })
      .catch(() => setHasUploadFailed(true));
  };
  return (
    <>
      <Helmet>
        <title> Digital Academy - Upload Activity Videos</title>
      </Helmet>
      <PageGrid>
        <Heading>Upload Activity Videos</Heading>

        <SimpleCard>
          <div style={{ display: isUploading ? "none" : "block" }}>
            <LoadingPanel loadingState={loadingState}>{activity && <UploadActivityVideosForm activity={activity} onSubmit={handleSubmit} />}</LoadingPanel>
          </div>
          {isUploading && (
            <div>
              <VideoUploadProgress progress={videoUploadProgress} message={message} hasFailed={hasUploadFailed} />
              <ViewResultsButtonContainer>
                {hasUploadFailed ? (
                  <Button onClick={() => setIsUploading(false)} variant="outlined">
                    Back
                  </Button>
                ) : (
                  <LinkButton to={`/activities/${activity?.id}`} variant="contained" color="primary" disabled={!isEncodingComplete}>
                    View Results
                  </LinkButton>
                )}
              </ViewResultsButtonContainer>
            </div>
          )}
        </SimpleCard>
      </PageGrid>
    </>
  );
};

export const ViewResultsButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing * 4}px;
`;
