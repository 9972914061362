import React from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import { Caption, SimpleCard, Subheading, TextFieldFormItem } from "components/primitives";
import { useFormContext } from "react-hook-form";

export const RiderDetailsFormSection = () => {
  const formContext = useFormContext();

  const isRiderDetailsValid = !formContext.errors["rider-details-valid"];
  return (
    <Grid item xs={12}>
      <SimpleCard elevation={6}>
        <Subheading>Rider Details</Subheading>
        <Caption>Upload this activity on behalf of someone else or leave blank to upload for yourself</Caption>

        <Grid container spacing={2}>
          <TextFieldFormItem name="riderFirstName" label="First Name" md={4} autoFocus />
          <TextFieldFormItem name="riderLastName" label="Last Name" md={4} />
          <TextFieldFormItem name="riderEmail" label="Email" md={4} />
        </Grid>
        {!isRiderDetailsValid && <FormHelperText error>If any rider details are provided, all fields must be completed</FormHelperText>}
      </SimpleCard>
    </Grid>
  );
};
