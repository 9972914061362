import React, { useState } from "react";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { TextField, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";

type AutocompleteProps<T> = {
  value: T | null;
  label: string;
  onSelected: (newValue: T | null) => void;
  onSearch: (newInputValue: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  getOptionSelected: (a: T, b: T) => boolean;
  disabled?: boolean;
};

export const Autocomplete = <T extends {}>({
  value,
  label,
  onSelected,
  onSearch,
  getOptionLabel,
  getOptionSelected,
  disabled,
}: AutocompleteProps<T>) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<T[] | null>(null);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = async (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    setOptions(null);
    if (!newInputValue) return;
    try {
      setLoading(true);
      const results = await onSearch(newInputValue);
      setOptions(results);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MuiAutocomplete
      style={{ width: 300 }}
      options={options || []}
      getOptionSelected={getOptionSelected}
      autoComplete
      value={value}
      noOptionsText={
        loading
          ? "Searching..."
          : options == null
          ? "Type to search"
          : "No options found"
      }
      getOptionLabel={getOptionLabel}
      onChange={(_event, newValue: T | null) => {
        onSelected(newValue);
      }}
      onInputChange={handleInputChange}
      onClose={() => {
        setOptions(null);
        setInputValue("");
      }}
      inputValue={inputValue}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
                <SearchIcon style={{ position: "absolute", right: 0 }} />
              </React.Fragment>
            ),
          }}
        />
      )}
      disabled={disabled}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiAutocomplete-popupIndicator {
    visibility: hidden;
  }
`;
