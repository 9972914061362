import React, { useEffect } from "react";
import { PageGrid, VideoThumbnail } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, LoadingPanel, Subheading, BodyText } from "components/primitives";
import { useApiData } from "plumbing/api";
import { listTrainingVideos } from "api/trainingVideos";
import { Card, CardContent } from "@material-ui/core";
import styled from "styled-components";
import orderBy from "lodash/orderBy";

export const Training = () => {
  const { data: videoList, loadingState: videoListLoadingState, fetchData: fetchTrainingVideos } = useApiData(
    listTrainingVideos,
    undefined,
    undefined,
    false
  );
  useEffect(() => {
    fetchTrainingVideos();
  }, [fetchTrainingVideos]);

  const fundVids =
    orderBy(
      videoList?.videos.filter((v) => v.category === "Fundamentals"),
      (v) => v.sortOrder
    ) || [];
  const streetVids =
    orderBy(
      videoList?.videos.filter((v) => v.category === "streetSKILLS 101"),
      (v) => v.sortOrder
    ) || [];
  const track101Vids =
    orderBy(
      videoList?.videos.filter((v) => v.category === "trackSKILLS 101"),
      (v) => v.sortOrder
    ) || [];
  const trackVids =
    orderBy(
      videoList?.videos.filter((v) => v.category === "trackSKILLS"),
      (v) => v.sortOrder
    ) || [];
  return (
    <>
      <Helmet>
        <title> Digital Academy - Training Hub</title>
      </Helmet>
      <PageGrid>
        <Heading>Training</Heading>
        <LoadingPanel loadingState={[videoListLoadingState]}>
          <VideoSection heading="Fundamentals" videos={fundVids} />
          <VideoSection heading="streetSKILLS 101" videos={streetVids} />
          <VideoSection heading="trackSKILLS 101" videos={track101Vids} />
          <VideoSection heading="trackSKILLS" videos={trackVids} />
        </LoadingPanel>
      </PageGrid>
    </>
  );
};

const VideoSection = ({ videos, heading }: { videos: TrainingVideoModel[]; heading: string }) => {
  return (
    <VideoSectionCard>
      <CardContent>
        <Subheading gutterBottom>{heading}</Subheading>
        {videos.length === 0 && <BodyText>There are no videos available yet.</BodyText>}
        <VideoList>
          {videos.map((v) => (
            <VideoThumbnail video={v} key={v.name} />
          ))}
        </VideoList>
      </CardContent>
    </VideoSectionCard>
  );
};

const VideoSectionCard = styled(Card)`
  margin-top: ${(props) => props.theme.spacing * 6}px;

  .MuiCardContent-root {
    padding: ${(props) => props.theme.spacing * 4}px;
  }
`;

const VideoList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(props) => props.theme.spacing * 4}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
