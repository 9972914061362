import React from "react";
import { PageGrid } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading } from "components/primitives";

export const Tutorial = () => {
  return (
    <>
      <Helmet>
        <title> Digital Academy - Getting Started</title>
      </Helmet>
      <PageGrid>
        <Heading>Tutorial</Heading>
      </PageGrid>
    </>
  );
};
