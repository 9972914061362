import React from "react";
import { Helmet } from "react-helmet";
import { Heading, Subheading } from "components/primitives";
import { Button, Container, SimplePaletteColorOptions, Divider, ButtonBase } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import heroImage from "assets/motoChampion-banner.jpg";
import plansImage from "assets/Plans.png";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import AllInclusiveOutlinedIcon from "@material-ui/icons/AllInclusiveOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { ReactComponent as MotorcycleIcon } from "assets/motorcycleicon.svg";
import { Link } from "react-router-dom";

export const MotoChampionLanding = () => {
  const { loginWithRedirect } = useAuth0();
  const welcomeRef = React.useRef<HTMLDivElement | null>(null);
  const cameraRef = React.useRef<HTMLDivElement | null>(null);
  const bikesRef = React.useRef<HTMLDivElement | null>(null);
  const ridingAreaRef = React.useRef<HTMLDivElement | null>(null);
  const signUpRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Helmet>
        <title>Digital Academy - motoDNA</title>
      </Helmet>
      <StyledPageGrid>
        <Container fixed maxWidth="md">
          <HeroSection>
            <HeroHeading gutterBottom={false}>motoCHAMPION</HeroHeading>
            <HeroSubheading>Step One to MotoGP</HeroSubheading>
            <HeroImage src={heroImage}></HeroImage>
            <ActionButtons>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (welcomeRef.current) welcomeRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                style={{ textTransform: "none" }}
              >
                EXPLORE motoCHAMPION
              </Button>
              <Button size="large" color="primary" variant="contained" onClick={loginWithRedirect}>
                Get Started
              </Button>
            </ActionButtons>
          </HeroSection>
        </Container>

        <WelcomeSectionContainer ref={welcomeRef}>
          <PageSection>
            <Heading gutterBottom={false}>Welcome to motoCHAMPION</Heading>
            <Subheading>Welcome to motoCHAMPION - Step one to MotoGP powered by motoDNA</Subheading>
            <Subheading>Using a GoPro on your motorbike, ride any bike, on any tyres, anywhere in the world.</Subheading>
            <Subheading>
              Our machine learning software analyses your riding, grade’s your skills, compared to thousands of other riders and places you on our
              competition leader boards. As more riders upload, the algorithms will adapt and your scores may change. Be sure to keep an eye of where
              you stand.
            </Subheading>
            <Subheading>
              Our low barrier to entry championship rewards riders with prizes to help you get to MotoGP including fully sponsored riders in
              MotoGP feeder classes like the bLU cRU Oceania Junior Cup.
            </Subheading>
            <Subheading>
              With the ability to ride in your own environment, motoCHAMPION is the safest way to improve your skill. Open for all people aged 11 to 16, compete as many times as you like between the 6th September and 31st December.
            </Subheading>
            <ActionButtons>
              <Button size="large" color="secondary" variant="contained" onClick={loginWithRedirect}>
                Get Started
              </Button>
            </ActionButtons>
          </PageSection>
        </WelcomeSectionContainer>

        <PageSection>
          <Heading gutterBottom={false}>The items you need to use motoCHAMPION</Heading>
          <ItemsList>
            <InfoItem
              onClick={() => {
                if (cameraRef.current) cameraRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <CameraAltOutlinedIcon />
              <Subheading gutterBottom={false}>GoPro 7+</Subheading>
            </InfoItem>
            <InfoItem
              onClick={() => {
                if (bikesRef.current) bikesRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <StyledMotorcycleIcon />
              <Subheading gutterBottom={false}>Bike & Mates</Subheading>
            </InfoItem>
            <InfoItem
              onClick={() => {
                if (ridingAreaRef.current) ridingAreaRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <AllInclusiveOutlinedIcon />
              <Subheading gutterBottom={false}>Riding Area</Subheading>
            </InfoItem>
            <InfoItem
              onClick={() => {
                if (signUpRef.current) signUpRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <LockOpenIcon />
              <Subheading gutterBottom={false}>Subscribe</Subheading>
            </InfoItem>
          </ItemsList>
        </PageSection>
        <Container fixed maxWidth="md">
          <Divider />
        </Container>
        <PageSection ref={cameraRef}>
          <Heading gutterBottom={false}>GoPro HERO</Heading>
          <Subheading>
            GoPro HERO 7 + offer’s more than just video with great sensors producing awesome data for our motoDNA algorithms. You only need
            one GoPro, so you can share the camera with your mates so everyone can take part.
          </Subheading>
          <Subheading>
            The action camera must be securely mounted to a static portion of the motorcycle, such as the tank or the rear seat. It cannot
            be mounted to your body, or any part connected to the steering. With the bike standing upright, the camera should be upright and
            straight, facing directly forwards.
          </Subheading>
          <ActionButtons>
            <Button color="primary" variant="outlined" component={Link} to="/instructions/camera-set-up">
              More Info
            </Button>
          </ActionButtons>
        </PageSection>
        <Container fixed maxWidth="md">
          <Divider />
        </Container>
        <PageSection ref={bikesRef}>
          <Heading gutterBottom={false}>Bike & Mates</Heading>
          <Subheading>
            With motoDNA you can DIY with your own virtual riding coach. Just grab your bike and a bunch of mates for a fun and learning
            experience. Remember, more skill = more fun and more rewards on our data driven platform.
          </Subheading>
        </PageSection>
        <Container fixed maxWidth="md">
          <Divider />
        </Container>
        <PageSection ref={ridingAreaRef}>
          <Heading gutterBottom={false}>Riding Area</Heading>
          <Subheading>
            motoCHAMPION is designed to be used in a simple Figure 8 track layout. You will need a safe riding area to practise of at least
            60m x 40m to give safe run off for the 35m x 15m Figure 8. You can quickly mark out the track with a tape measure and chalk or
            use cones if you wish. For more info head to our track setup page.
          </Subheading>
          <ActionButtons>
            <Button color="primary" variant="outlined" component={Link} to="/instructions/trackSKILLS-101">
              More Info
            </Button>
          </ActionButtons>
        </PageSection>
        <Container fixed maxWidth="md">
          <Divider />
        </Container>
        <PageSection ref={signUpRef}>
          <Heading gutterBottom={false}>Sign Up</Heading>
          <Subheading>
            To use motoCHAMPION sign up to a subscription plus our motoCHAMPION challenge to access rich sets of features such as video,
            compare to other riders, training and of course the opportunity to get to MotoGP.
          </Subheading>
          <HeroImage src={plansImage}></HeroImage>
          <ActionButtons>
            <Button size="large" color="primary" variant="contained" onClick={loginWithRedirect}>
              Get Started
            </Button>
          </ActionButtons>
        </PageSection>
      </StyledPageGrid>
    </>
  );
};

const StyledPageGrid = styled.div`
  text-align: center;
`;

const PageSection = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => {
  return (
    <Container fixed maxWidth="md" ref={ref}>
      <StyledPageSection>{children}</StyledPageSection>
    </Container>
  );
});

const StyledPageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0;
  & > * + * {
    margin-top: 24px;
  }
`;

const HeroSection = styled(PageSection)`
  height: 900px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    height: 800px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    height: 600px;
  }
`;

const HeroHeading = styled(Heading)`
  @media (min-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    font-size: 3.2em;
    font-weight: 500;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 2.7em;
    font-weight: 500;
  }
`;

const HeroSubheading = styled(Subheading)``;

const HeroImage = styled.img`
  object-fit: contain;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  button {
    padding: 12px 36px;
    @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
      padding: 12px 4px;
      flex: 1;
      font-size: 0.83rem;
    }
  }
  & > * + * {
    margin-left: 24px;
  }
`;

const WelcomeSectionContainer = styled.div`
  background: ${(props) => (props.theme.palette.primary as SimplePaletteColorOptions).main};
  color: ${(props) => (props.theme.palette.secondary as SimplePaletteColorOptions).main};
`;

const ItemsList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  border: 2px solid white;
`;

const InfoItem = styled(ButtonBase)`
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  &:hover {
    background-color: rgba(243, 191, 48, 0.08);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    padding: 8px;
    h5 {
      font-size: 0.9rem;
    }
  }

  & > * + *:not(.MuiTouchRipple-root) {
    margin-top: 8px;
  }
  &:not(:last-child) {
    border-right: 2px solid white;
  }
  svg {
    font-size: 80px;
    position: relative;
    top: 3px;

    @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
      font-size: 35px;
    }
  }
`;

const StyledMotorcycleIcon = styled(MotorcycleIcon)`
  height: 80px;
  transform: scale(1.1);
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    height: 35px;
  }
`;
