import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  PageGrid,
  ActivityDashboardHeader,
  RiderJourneyChart,
  ActivityDrillScores,
  ActivityAdvice,
  ActivityCharts,
  Leaderboard,
  ActivityVideos,
  ActivityLapTimes,
} from "components/ui";
import { LoadingPanel } from "components/primitives";
import { useParams } from "react-router-dom";
import { useApiData } from "plumbing/api";
import { getActivityById } from "api/activity";
import { Card, CardContent } from "@material-ui/core";
import styled from "styled-components";
import { analytics } from "plumbing/analytics";

export const ActivityDashboard = () => {
  const [selectedContent, setSelectedContent] = useState("Overall");

  const { id } = useParams<{ id: string }>();
  const { data: activity, loadingState, setData: setActivity } = useApiData(
    () => {
      setActivity(undefined);
      setSelectedContent("Overall");
      return getActivityById(id);
    },
    undefined,
    [id]
  );

  const handleSelectedContentChange = (skill: string) => {
    analytics.sendEvent({ category: "Activities", action: "Skill Change", label: skill });
    setSelectedContent(skill);
  };

  return (
    <>
      <Helmet>
        <title> Digital Academy - Activity Dashboard</title>
      </Helmet>
      <PageGrid>
        <LoadingPanel loadingState={loadingState}>
          {activity && (
            <ActivityDashboardContainer>
              <HeaderCard>
                <ActivityDashboardHeader
                  activity={activity}
                  currentSkill={selectedContent}
                  onSelectedContentChange={handleSelectedContentChange}
                  selectedContent={selectedContent}
                />
              </HeaderCard>

              <LeaderboardCard>
                <Leaderboard activityType={activity.activityType} activityId={activity.id} />
              </LeaderboardCard>
              <ContentCard>
                {selectedContent === "Overall" && <RiderJourneyChart score={activity.score} />}
                {selectedContent === "Videos" && <ActivityVideos activity={activity} />}
                {selectedContent === "LapTimes" && <ActivityLapTimes activity={activity} />}
                <ActivityCharts activity={activity} selectedContent={selectedContent} />
              </ContentCard>

              <AdviceCard>
                <ActivityAdvice activity={activity} />
              </AdviceCard>

              {activity.activitySkillType !== "Track" && (
                <DrillScoresCard>
                  <ActivityDrillScores activity={activity} />
                </DrillScoresCard>
              )}
            </ActivityDashboardContainer>
          )}
        </LoadingPanel>
      </PageGrid>
    </>
  );
};

const ActivityDashboardSection = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div style={{ height: "100%" }} className={className}>
      <ActivityDashboardSectionCard>
        <CardContent>{children}</CardContent>
      </ActivityDashboardSectionCard>
    </div>
  );
};

const ActivityDashboardContainer = styled.div`
  display: grid;
  grid-template:
    "header header"
    "leaderboard content"
    "advice advice"
    "drill-scores drill-scores";
  grid-gap: ${(props) => props.theme.spacing * 4}px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: 1fr;
    grid-template:
      "header"
      "content"
      "leaderboard"
      "advice"
      "drill-scores";
  }
`;

const HeaderCard = styled(ActivityDashboardSection)`
  grid-area: header;
`;

const LeaderboardCard = styled(ActivityDashboardSection)`
  grid-area: leaderboard;
`;

const ContentCard = styled(ActivityDashboardSection)`
  grid-area: content;
  height: 100%;
`;

const AdviceCard = styled(ActivityDashboardSection)`
  grid-area: advice;
`;

const DrillScoresCard = styled(ActivityDashboardSection)`
  grid-area: drill-scores;
`;

const ActivityDashboardSectionCard = styled(Card)`
  height: 100%;
  .MuiCardContent-root {
    padding: 16px;
    height: 100%;
  }
`;
