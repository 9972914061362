import React from "react";
import ReactDOM from "react-dom";
import styled, { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { theme, muiTheme, GlobalStyles } from "plumbing/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Navbar, Footer } from "components/ui";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "plumbing/auth";
import "plumbing/api";
import { Routes } from "Routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Elements } from "@stripe/react-stripe-js";
import { StripeConfiguration } from "plumbing/stripe";
import { analytics } from "plumbing/analytics";
import { getCLS, getFID, getLCP } from "web-vitals";

(window as any).applicationVersion = process.env.REACT_APP_Version;

const App = () => (
  <Router>
    <AuthProvider>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Elements stripe={StripeConfiguration.stripePromise} options={StripeConfiguration.options}>
              <CssBaseline />
              <GlobalStyles />
              <AppContainer>
                <Navbar />
                <Routes />
                <Footer />
              </AppContainer>
            </Elements>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </AuthProvider>
  </Router>
);

const AppContainer = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(analytics.sendFromWebVitals);
getCLS(analytics.sendFromWebVitals);
getFID(analytics.sendFromWebVitals);
getLCP(analytics.sendFromWebVitals);
