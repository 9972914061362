import React from "react";
import { Checkbox as MuiCheckbox, FormControlLabel, FormControl, FormHelperText, Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { FormItem } from "../FormItem";
import get from "lodash/get";

type CheckboxFieldProps = { label: React.ReactNode; name: string; disabled?: boolean };

export const CheckboxField = ({ label, name, disabled }: CheckboxFieldProps) => {
  const { register, errors, formState } = useFormContext();
  return (
    <FormControl error={get(errors, name)?.message !== undefined}>
      <FormControlLabel
        control={<MuiCheckbox color="primary" />}
        label={label}
        name={name}
        inputRef={register()}
        disabled={formState.isSubmitting || disabled}
      />
      <FormHelperText>{get(errors, name)?.message}</FormHelperText>
    </FormControl>
  );
};

export const CheckboxFieldFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: CheckboxFieldProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <CheckboxField {...props} />
    </FormItem>
  );

  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
