import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useInterval } from "plumbing/hooks";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";
import "videojs-youtube/dist/Youtube.min.js";

type VideoPlayerProps = {
  onPlay?: (currentTime?: number) => void;
  onPause?: (currentTime?: number) => void;
  onEnd?: (currentTime?: number) => void;
  onTimeUpdate?: (currentTime?: number) => void;
  src: string;
  children?: React.ReactNode;
  className?: string;
  youtube?: boolean;
};

const options: VideoJsPlayerOptions = {
  autoplay: false,
  controls: true,
  bigPlayButton: true,
  fluid: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
};

export const VideoPlayer = ({ src, className, children, onTimeUpdate, youtube }: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoPlayerRef = useRef<VideoJsPlayer | null>(null);

  useInterval(() => {
    if (!videoPlayerRef.current || !videoPlayerRef.current.paused || videoPlayerRef.current.paused()) return;

    if (onTimeUpdate) onTimeUpdate(videoPlayerRef.current.currentTime());
  }, 1000 / 30);

  useEffect(() => {
    const p = videojs(videoRef.current, options);
    videoPlayerRef.current = p;
    return () => {
      p.dispose();
      videoPlayerRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (videoPlayerRef.current && src) {
      videoPlayerRef.current.src([
        {
          type: youtube ? "video/youtube" : "video/mp4",
          src,
        },
      ]);
    }
  }, [videoPlayerRef, src, youtube]);

  useEffect(() => {
    if (!videoPlayerRef.current) return;
    const handleTimeUpdate = () => {
      if (onTimeUpdate && videoPlayerRef.current) onTimeUpdate(videoPlayerRef.current.currentTime());
    };

    videoPlayerRef.current.on("timeupdate", handleTimeUpdate);

    return () => {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.off("timeupdate", handleTimeUpdate);
      }
    };
  }, [onTimeUpdate, videoPlayerRef]);

  const [overlayTopOffset, setOverlayTopOffset] = useState(0);
  const [overlayHeight, setOverlayHeight] = useState("100%");
  const [overlayLeftOffset, setOverlayLeftOffset] = useState(0);
  const [overlayWidth, setOverlayWidth] = useState("100%");
  useInterval(() => {
    if (!videoPlayerRef.current || !videoRef.current) return;

    const playerHeight = videoPlayerRef.current.currentDimension("height");
    const playerWidth = videoPlayerRef.current.currentDimension("width");
    let playerRatio = playerWidth / playerHeight;
    if (videoRef.current.videoHeight === 0) return;

    const videoHeight = videoRef.current.videoHeight;
    const videoWidth = videoRef.current.videoWidth;
    const videoRatio = videoWidth / videoHeight;

    const ratioRatio = playerRatio / videoRatio;

    if (ratioRatio < 1) {
      const newOverlayHeight = ratioRatio * playerHeight;

      setOverlayHeight(`${newOverlayHeight}px`);
      setOverlayTopOffset((playerHeight - newOverlayHeight) / 2);
      setOverlayWidth("100%");
      setOverlayLeftOffset(0);
    } else {
      const newOverlayWidth = playerWidth / ratioRatio;
      setOverlayWidth(`${newOverlayWidth}px`);
      setOverlayLeftOffset((playerWidth - newOverlayWidth) / 2);
      setOverlayHeight("100%");
      setOverlayTopOffset(0);
    }
  }, 500);

  return (
    <VideoContainer className={className}>
      <div data-vjs-player style={{ height: "100%", width: "100%" }}>
        <video ref={videoRef} playsInline className="video-js vjs-big-play-centered" disablePictureInPicture controlsList="nodownload" />
        {children && !youtube && <VideoOverlay style={{ top: overlayTopOffset, height: overlayHeight, left: overlayLeftOffset, width: overlayWidth }}>{children}</VideoOverlay>}
      </div>
    </VideoContainer>
  );
};
const VideoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const VideoPlaceholderOuter = styled.div`
  position: relative;
  display: table;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  background-color: black;
`;

const VideoPlaceholderInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center;
  align-items: center;
  display: flex;
  vertical-align: middle;
  justify-content: center;
`;

const VideoPlaceholder = ({ children }: any) => (
  <VideoPlaceholderOuter>
    <VideoPlaceholderInner>{children}</VideoPlaceholderInner>
  </VideoPlaceholderOuter>
);

export const VideoBlock = ({ src }: { src: string; }) => {
  return (
    <>
      {!src ? (
        <VideoPlaceholder>{src ? "" : "Video is not currently available"}</VideoPlaceholder>
      ) : (
        <VideoPlayer src={src} />
      )}
    </>
  );
};
