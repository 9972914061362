import axios from "axios";
import { apiConfiguration } from "plumbing/api";
import { serialize } from "object-to-formdata";

export const editChallenge = (slug: string, request: EditChallengeRequest) => {
  const url = `${apiConfiguration.apiUrl}/challenges/${slug}`;
  const formData = serialize(request);

  return axios.post<ChallengeModel>(url, formData);
};
