import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polyline, ZoomControl } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import { satelliteTileUrl } from "plumbing/maps";
import styled from "styled-components";
import { max, min } from "lodash";

export const ActivityCompareMap = ({ activity1Path, activity2Path }: { activity1Path: string; activity2Path: string }) => {
  const [isMapReady, setIsMapReady] = useState(false);
  const [bounds, setBounds] = useState<LatLngTuple[] | undefined>();

  const [gpsPoints1, setGpsPoints1] = useState<LatLngTuple[]>([]);
  const [gpsPoints2, setGpsPoints2] = useState<LatLngTuple[]>([]);

  useEffect(() => {
    const calcuatePath = (gpsPath: string) => {
      const path = JSON.parse(gpsPath) as { Name: string; DataPoints: { X: number; Y: number; Label: string }[] }[];
      const points = path[0].DataPoints
        .filter(dp => !isNaN(dp.X) && !isNaN(dp.Y))
        .map((dp) => [dp.Y, dp.X] as LatLngTuple);

      const lats = points.map((p) => p[0]);
      const longs = points.map((p) => p[1]);
      const maxLat = max(lats) || 0;
      const minLat = min(lats) || 0;
      const maxLong = max(longs) || 0;
      const minLong = min(longs) || 0;
      return {
        points,
        minLat,
        maxLat,
        minLong,
        maxLong,
      };
    };

    const activity1 = calcuatePath(activity1Path);
    setGpsPoints1(activity1.points);
    const activity2 = calcuatePath(activity2Path);
    setGpsPoints2(activity2.points);

    setBounds([
      [Math.min(activity1.minLat || 0, activity2.minLat || 0), Math.min(activity1.minLong || 0, activity2.minLong || 0)],
      [Math.max(activity1.maxLat || 0, activity2.maxLat || 0), Math.max(activity1.maxLong || 0, activity2.maxLong || 0)],
    ]);

    setIsMapReady(true);
  }, [activity1Path, activity2Path]);

  const boundsPadding = 0.001;
  return (
    <>
      {isMapReady && bounds && (
        <ActivityMapContainer
          attributionControl={false}
          zoomControl={false}
          scrollWheelZoom={true}
          bounds={bounds}
          maxBounds={[
            [bounds[0][0] - boundsPadding, bounds[0][1] - boundsPadding],
            [bounds[1][0] + boundsPadding, bounds[1][1] + boundsPadding],
          ]}
          dragging={true}
        >
          <ZoomControl position="bottomright" />
          <TileLayer url={satelliteTileUrl} maxNativeZoom={17} maxZoom={19} minZoom={16} />

          {!!gpsPoints2.length && <Polyline positions={gpsPoints2} smoothFactor={0} pathOptions={{ weight: 2, color: "#fff" }} />}
          {!!gpsPoints1.length && <Polyline positions={gpsPoints1} smoothFactor={0} pathOptions={{ weight: 2, color: "#F3BF30" }} />}
        </ActivityMapContainer>
      )}
    </>
  );
};

const ActivityMapContainer = styled(MapContainer)`
  height: 500px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    height: 300px;
  }
`;
