import React from "react";
import { Subheading, BodyText } from "components/primitives";
import {
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useApiData } from "plumbing/api";
import { listRiderChallenges } from "api/riderChallenges";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const ChallengeInfo = ({ challenge, size }: { challenge: ChallengeModel; size: "sm" | "lg" }) => {
  if (size === "lg")
    return (
      <ChallengeCard>
        <ChallengeImage image={challenge.imageUrl} title="Challenge Image" />
        <ChallengeCardContent>
          <BodyText>{challenge.name}</BodyText>
          <Chip variant="outlined" color="primary" label={`${challenge.riderCount} members`} size="small" style={{ marginTop: "4px" }} />
        </ChallengeCardContent>
        <ChallengeCardActions>
          <Button size="small" color="primary" variant="contained" fullWidth component={Link} to={`challenges/${challenge.slug}`}>
            View Challenge
          </Button>
        </ChallengeCardActions>
      </ChallengeCard>
    );

  return (
    <ChallengeInfoListItemContainer>
      <ListItem button component={Link} to={`challenges/${challenge.slug}`}>
        <ListItemAvatar>
          <ChallengeAvatar alt="Challenge Logo" src={challenge.imageUrl} variant="rounded" />
        </ListItemAvatar>
        <ListItemText>
          <BodyText>{challenge.name}</BodyText>
        </ListItemText>
      </ListItem>
    </ChallengeInfoListItemContainer>
  );
};

const ChallengeInfoLoading = ({ size }: { size: "sm" | "lg" }) => {
  if (size === "lg")
    return (
      <ChallengeCard>
        <ChallengeImage>
          <Skeleton variant="rect" height={200} />
        </ChallengeImage>
        <ChallengeCardContent>
          <BodyText>
            <Skeleton />
          </BodyText>
          <Skeleton width={100} />
        </ChallengeCardContent>
        <ChallengeCardActions>
          <Skeleton variant="rect" height={30} width="100%" />
        </ChallengeCardActions>
      </ChallengeCard>
    );

  return (
    <ChallengeInfoListItemContainer>
      <ListItem>
        <ListItemAvatar>
          <Skeleton height={40} width={40} variant="rect" style={{ borderRadius: "4px" }} />
        </ListItemAvatar>
        <ListItemText>
          <Skeleton />
        </ListItemText>
      </ListItem>
    </ChallengeInfoListItemContainer>
  );
};

export const RiderChallenges = ({ forceSmallSize }: { forceSmallSize?: boolean }) => {
  const { data, loadingState } = useApiData(() => listRiderChallenges({}));

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const challengeInfoSize = isSmall || forceSmallSize ? "sm" : "lg";
  return (
    <>
      <Subheading gutterBottom>My Challenges</Subheading>
      <ChallengeList forceSmall={forceSmallSize}>
        {loadingState.isLoading && <ChallengeInfoLoading size={challengeInfoSize} />}
        {data?.riderChallenges.map((g) => (
          <ChallengeInfo challenge={g} key={g.slug} size={challengeInfoSize} />
        ))}
      </ChallengeList>
      {data?.riderChallenges.length === 0 && <BodyText>You're not a member of any challenges yet</BodyText>}
      <Divider style={{ margin: "16px 0" }} />

      <Subheading gutterBottom>Other Challenges</Subheading>
      <ChallengeList forceSmall={forceSmallSize}>
        {loadingState.isLoading && <ChallengeInfoLoading size={challengeInfoSize} />}
        {data?.otherChallenges.map((g) => (
          <ChallengeInfo challenge={g} key={g.slug} size={challengeInfoSize} />
        ))}
      </ChallengeList>
      {data?.otherChallenges.length === 0 && <BodyText>There are no other challenges to join</BodyText>}
    </>
  );
};

const ChallengeAvatar = styled(Avatar)`
  img.MuiAvatar-img {
    object-fit: contain;
  }
`;

const ChallengeInfoListItemContainer = styled.div`
  .MuiListItem-root {
    margin: 0 -4px;
    padding: 4px;
    border-radius: 4px;
    height: 64px;
  }
`;

const ChallengeList = styled(({ forceSmall, children, ...props }) => <div {...props}>{children}</div>)<{ forceSmall: boolean }>`
  display: ${(props) => (props.forceSmall ? "block" : "grid")};
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`;

const ChallengeImage = styled(CardMedia)`
  height: 200px;
  background-size: contain;
  margin: ${(props) => props.theme.spacing * 2}px;
`;

const ChallengeCardContent = styled(CardContent)`
  padding: ${(props) => props.theme.spacing * 2}px;
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex: 1;
  }

  .MuiChip-root {
    align-self: flex-start;
  }
`;

const ChallengeCardActions = styled(CardActions)`
  flex: 1;
  align-items: flex-end;
`;

const ChallengeCard = styled(Card)`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
