import React from "react";
import { Typography } from "@material-ui/core";

export const Caption = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <Typography variant="caption" className={className}>
      {children}
    </Typography>
  );
};
