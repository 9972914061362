import React from "react";
import { Helmet } from "react-helmet";
import { PageGrid, CreateEditChallengeForm, ChallengeFormData } from "components/ui";
import { Heading } from "components/primitives";
import { createChallenge } from "api/challenge";
import { useHistory } from "react-router-dom";

export const CreateChallenge = () => {
  let history = useHistory();

  const handleSubmit = async (data: ChallengeFormData) => {
    const request: CreateChallengeRequest = {
      name: data.name,
      description: data.description,
      activitySkillType: data.activitySkillType,
      startDate: data.startDate,
      endDate: data.endDate,
      image: data.photo![0],
      youtubeVideoUrl: data.youtubeVideoUrl,
      prizes: data.prizes,
      rules: data.rules,
      signUpText: data.signupText,
      termsAndConditions: data.termsAndConditions!.length ? data.termsAndConditions![0] : undefined,
      signUpPrice: data.cost,
    };

    const response = await createChallenge(request);

    history.push(`/challenges/${response.data.slug}`);
  };
  return (
    <>
      <Helmet>
        <title>Digital Academy - Create Challenge</title>
      </Helmet>
      <PageGrid>
        <Heading>Create Challenge</Heading>
        <CreateEditChallengeForm onSubmit={handleSubmit} />
      </PageGrid>
    </>
  );
};
