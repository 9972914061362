import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { FormItem } from "./FormItem";

type SubmitButtonProps = { children: React.ReactNode; disabled?: boolean };

export const SubmitButton = ({ children, disabled }: SubmitButtonProps) => {
  const { formState } = useFormContext();
  return (
    <>
      <Button variant="contained" color="primary" type="submit" disabled={formState.isSubmitting || disabled}>
        {children}
      </Button>
    </>
  );
};

export const SubmitButtonFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: SubmitButtonProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <SubmitButton {...props} />
    </FormItem>
  );
  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
