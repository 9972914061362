import React from "react";
import { Typography } from "@material-ui/core";
import { UploadLinearProgressBar } from "./UploadLinearProgressBar";

export const DataUploadProgress = ({ progress, message, hasFailed }: ProgressProps) => {
  return (
    <>
      <Typography variant="h5" color={hasFailed ? "error" : undefined}>
        {hasFailed ? "Data Upload Failed" : message}
      </Typography>
      {hasFailed && (
        <Typography variant="body1" color="error">
          {hasFailed ? "We weren't able to complete uploading your data. Please try again and contact us if the error continues." : ""}
        </Typography>
      )}
      <UploadLinearProgressBar variant="determinate" value={progress} />
    </>
  );
};
