import React from "react";
import { PageGrid, ContentPageSection, Accordion, AccordionSummaryWithIcon } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, BodyText } from "components/primitives";
import { AccordionDetails, Typography, Divider } from "@material-ui/core";
import figure8Setup from "assets/instructions/Figure 8 setup.jpg";
import ridingTips from "assets/instructions/Riding Tips.jpg";
import figure8Drill from "assets/instructions/Figure 8 drill.jpg";
import avoidanceSetup from "assets/instructions/Avoidance setup.jpg";
import avoidanceDrill from "assets/instructions/Avoidance drill.jpg";
import emergencyStop from "assets/instructions/Emergency Stop drill.jpg";
import cornerAvoidanceSetup from "assets/instructions/Corner avoidance setup.jpg";
import cornerAvoidanceDrill from "assets/instructions/Corner avoidance drill.jpg";
import { Alert } from "@material-ui/lab";

export const StreetSkills101 = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - streetSKILLS 101</title>
      </Helmet>
      <PageGrid>
        <Heading>streetSKILLS 101</Heading>
        <div>
          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Setup</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Figure 8 Set Up" imageUrl={figure8Setup} imageAlt="Figure 8 Set Up Image">
                  <BodyText>Requirements:</BodyText>

                  <ul>
                    <li>70m (230ft) x 40m (130ft) flat and clean riding area, with landowners permission</li>
                    <li>Tape measure & chalk (Low profile cones are optional)</li>
                  </ul>

                  <BodyText>Set Up:</BodyText>

                  <ul>
                    <li>Accurately mark out the riding area as shown</li>
                    <li>You can use chalk or low profile cones to mark the 5 corners</li>
                  </ul>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Avoidance Set Up" imageUrl={avoidanceSetup} imageAlt="Avoidance Set Up Image">
                  <BodyText>Requirements:</BodyText>
                  <ul>
                    <li>Tape measure & chalk (Low profile cones are optional)</li>
                  </ul>

                  <BodyText>Set Up:</BodyText>

                  <ul>
                    <li>Accurately mark out the riding area as shown</li>
                    <li>You can use chalk or low profile cones to mark the 12 corners of the boxes</li>
                  </ul>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Emergency Stop Set Up" imageUrl={emergencyStop} imageAlt="Emergency Stop Set Up Image">
                  <BodyText>Set Up:</BodyText>
                  <ul>
                    <li>Mark emergency stop, start and finish points as illustrated</li>
                  </ul>
                </ContentPageSection>

                <Divider />

                <ContentPageSection
                  title="Corner Avoidance Set Up"
                  imageUrl={cornerAvoidanceSetup}
                  imageAlt="Corner Avoidance Set Up Image"
                >
                  <BodyText>Set Up:</BodyText>

                  <ul>
                    <li>Mark out 3 circles with chalk as illustrated, centreline is dashed</li>
                    <li>Measurements are the circle diameter</li>
                  </ul>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Riding Tips</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Riding Tips" imageUrl={ridingTips} imageAlt="Riding Tips Image">
                  <ul>
                    <li>streetSKILLS 101 is a tool to develop riding skills as part of the 3 pillars of SKILL, CRAFT and MIND</li>
                    <li>High SKILL reduces your risk on the street by having the correct and intuitive reactions to potential hazards up your sleeve</li>
                    <li>Don't wait until you have a problem on the road to learn, it's often too late</li>
                    <li>Your goal is to emulate the best riders scores, improving step by step using FLOW+</li>
                    <li>Riding with gusto, up to 95% of your ability, will increase your score</li>
                    <li>Most motorcycles can accelerate and brake at over 1g, however many riders fail to reach the motorcycles potential</li>
                    <li>A good rider will also be able to demonstrate the skill by optimising control inputs</li>
                    <li>This means Throttle, Braking and Steering with a high rate of application, smoothly and progressively</li>
                    <li>Short transition times, for example throttle to brake are rewarded as is consistency</li>
                    <li>Good vision and some body position will also increase your score</li>
                    <li>Of course, you don't ride like this on the street, streetSKILLS 101 is a tool to improve your skills and demonstrate your ability in a controlled environment</li>
                  </ul>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>


          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Riding Instructions</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Figure 8" imageUrl={figure8Drill} imageAlt="Figure 8 Instructions Image">
                  <ul>
                    <li>With the bike at the (green) start/finish line, begin recording with the GoPro</li>
                    <li>
                      Complete two laps of the Figure 8 as illustrated, riding at 95% of your ability, coming to a quick stop at the start
                      finish line at the end of the second lap. Stop recording
                    </li>
                    <li>
                      The goal is to show your throttle, braking and steering skills. Good vision, body position and keeping tight to the
                      cones will help your skill scores
                    </li>
                  </ul>
                  <Alert severity="warning" style={{ marginTop: "12px" }}>
                    <BodyText>Important:</BodyText>
                    <ul>
                      <li>
                        Only one rider at a time should be inside the riding area 70m (230ft) x 40m (130ft)
                      </li>
                      <li>
                        Ensure that you are wearing appropriate riding gear at all times
                      </li>
                      <li>
                        Remember to ride within your skill level
                      </li>
                    </ul>
                  </Alert>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Avoidance" imageUrl={avoidanceDrill} imageAlt="Avoidance Instructions Image">
                  <ul>
                    <li>With the bike at the start/finish line, begin recording with the GoPro</li>
                    <li>
                      Complete one lap of the Figure 8 riding at 95% of your ability, by taking the alternate route through the avoidance
                      box
                    </li>
                    <li>
                      Ride through the orange entry gate, then swerve left or right to avoid the black box while remaining within the blue
                      box
                    </li>
                    <li>Come to a quick stop at the start/finish line immediately after the swerve</li>
                    <li>Stop recording</li>
                    <li>This drill simulates avoiding a car (the black box) while remaining under control</li>
                    <li>The quicker you can swerve and stop, the higher your score</li>
                  </ul>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Balance" imageUrl={figure8Drill} imageAlt="Balance Instructions Image">
                  <ul>
                    <li>With the bike at the start/finish cone, begin recording with the GoPro</li>
                    <li>Complete one lap of the Figure 8 staying as close to 10kph (6mph) as you can</li>
                    <li>Come to a stop at the start/finish cone and stop recording</li>
                  </ul>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Emergency Stop" imageUrl={emergencyStop} imageAlt="Emergency Stop Instructions Image">
                  <ul>
                    <li>With the bike at the start point, begin recording with the GoPro</li>
                    <li>Accelerate to a minimum of 40kph (25mph) and complete an emergency stop</li>
                    <li>Stop recording before moving the bike</li>
                  </ul>
                  <Alert severity="info" style={{ marginTop: "12px" }}>
                    <BodyText>Tips</BodyText>
                    <ul>
                      <li>
                        Practise a few eStops, building up your speed each time to ensure you do not enter the safety margin zone (Blue)
                      </li>
                      <li>
                        To get a good score you will have a short transition time from throttle to brakes, a high rate of brake pressure
                        application and a high G force. Most street bikes are capable for 1G
                      </li>
                      <li>
                        As with all motorcycle skills, practise step by step, increasing speed and brake pressure in 5% increments each time
                      </li>
                      <li>
                        Be especially careful if your bike does not have ABS as the front tyre may lock up, be ready to release and reapply the
                        brake
                      </li>
                    </ul>
                  </Alert>
                </ContentPageSection>

                <Divider />

                <ContentPageSection title="Corner Avoidance" imageUrl={cornerAvoidanceDrill} imageAlt="Corner Avoidance Instructions Image">
                  <ul>
                    <li>With the bike facing counter clockwise (Blue arrow) on the black dotted line, begin recording with the GoPro</li>
                    <li>
                      Ride along the black dotted line until you have reached 95% of your ability. Swerve to the inner red circle
                      and come to a complete emergency stop
                    </li>
                    <li>Stop recording</li>
                    <li>
                      Repeat in opposite direction, with the bike facing clockwise (Green arrow) on the black dotted line, begin recording
                      with the GoPro. Ride along the black dotted line until you have reached 95% of your ability. Swerve to the
                      outer red circle and come to a complete emergency stop. Stop recording
                    </li>
                  </ul>
                  <Alert severity="info" style={{ marginTop: "12px" }}>
                    <BodyText>Tips</BodyText>
                    <ul>
                      <li>
                        This drill is designed for you to practise avoiding a head on crash with another vehicle (Black rectangle) in a
                        corner
                      </li>
                      <li>
                        It’s very important that you don’t look directly at the oncoming vehicle as you will likely target fixate and ride
                        into the vehicle. Instead focus on looking for an escape path on the side of the road. Repeat this though in your
                        brain so it becomes intuitive
                      </li>
                      <li>To achieve a high score you will be riding at a realistic speed and then steer and stop quickly</li>
                      <li>Stay within the red lines as they indicate the side of the road where there may be obstacles</li>
                      <li>
                        As with all motorcycle skills, practise step by step, increasing speed and brake pressure in 5% increments each time
                      </li>
                      <li>
                        Be especially careful if your bike does not have ABS as the front tyre may lock up, be ready to release and reapply
                        the brake
                      </li>
                    </ul>
                  </Alert>
                  <Alert severity="warning" style={{ marginTop: "12px" }}>
                    <BodyText>
                      Important: For USA and European countries that ride on the right-hand side of the road you will swerve to the right,
                      instead of left
                    </BodyText>
                  </Alert>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageGrid>
    </>
  );
};
