import React, { useState } from "react";
import { Card, CardContent, Typography, CardActionArea, useMediaQuery, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { LabelGauge } from "components/primitives/Gauges";
import { DateAtTime, ActivityMap } from "..";
import styled, { useTheme } from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";
import { skillNameComparer } from "plumbing/sorting";
import * as htmlToImage from "html-to-image";
import { createActivityShare } from "api/activityShare";
import ShareIcon from "@material-ui/icons/Share";
import { saveAs } from "file-saver";
import logoAlt from "assets/logo white gold.png";
import { apiConfiguration } from "plumbing/api";
import { observer } from "mobx-react";
import { userStore } from "plumbing/auth";
import { analytics } from "plumbing/analytics";
import { Caption } from "components/primitives";

type ActivityCardProps = {
  activity: ActivityFeedItemModel;
  elevation?: number;
};

export const ActivityCard = observer(({ activity, elevation }: ActivityCardProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const cardRef = React.useRef<HTMLDivElement | null>(null);

  const [isShareInProgress, setIsShareInProgress] = useState(false);

  const createShare = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (!cardRef.current) return;
    setIsShareInProgress(true);

    const isLocalDev = process.env.REACT_APP_Version?.indexOf("local") !== -1;

    const node = cardRef.current.cloneNode(true) as HTMLElement;
    const container = document.createElement("div");
    container.style.cssText = "position: fixed; top: 0; left: 100%; display: flex; align-items: center; justify-content: center;";
    container.appendChild(node);
    cardRef.current.appendChild(container);

    const windowConfig: { [key: string]: any } = {
      height: 400,
      width: 550,
      location: "no",
      toolbar: "no",
      status: "no",
      directories: "no",
      menubar: "no",
      scrollbars: "yes",
      resizable: "no",
      centerscreen: "yes",
      chrome: "yes",
    };

    let w: Window | null;

    w = window.open(
      "loading",
      "",
      Object.keys(windowConfig)
        .map((key) => `${key}=${windowConfig[key]}`)
        .join(", ")
    );

    setTimeout(async () => {
      if (!cardRef.current) return;
      const shareButton = container.getElementsByClassName("MuiIconButton-root")[0];
      shareButton.parentElement!.removeChild(shareButton);

      const img = container.getElementsByTagName("img")[0];
      img.style.display = "block";

      const card = container.getElementsByClassName("MuiCard-root")[0] as HTMLDivElement;
      card.style.cssText += "min-width: 400px;";

      const focusHighlight = container.getElementsByClassName("MuiCardActionArea-focusHighlight")[0] as HTMLSpanElement;
      focusHighlight.style.cssText += "display: none;";
      const touchRipple = container.getElementsByClassName("MuiTouchRipple-root")[0] as HTMLSpanElement;
      touchRipple.style.cssText += "display: none;";

      const size = Math.max(cardRef.current.offsetWidth, cardRef.current.offsetHeight);
      try {
        const options: htmlToImage.Options = {
          height: size,
          width: size * 1.8,
          quality: 1,
          backgroundColor: "white",
          style: { display: "flex", alignItems: "center", justifyContent: "center" },
        };
        const blob = await htmlToImage.toBlob(node, options);

        await createActivityShare(activity.activityId, { image: blob! });
        if (isLocalDev) {
          w?.close();
          saveAs(blob!, "my-node.png");
        } else {
          const shareUrl = `${apiConfiguration.apiUrl}/activities/${activity.activityId}/shares`;
          analytics.sendSocial({
            socialNetwork: "Facebook",
            socialAction: "share",
            socialTarget: shareUrl,
          });
          (w as any).location = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(`${apiConfiguration.apiUrl}/activities/${activity.activityId}/shares`)}`;
        }
        cardRef.current.removeChild(container);
      } catch (error) {
        console.error("oops, something went wrong!", error);
      } finally {
        setIsShareInProgress(false);
      }
    }, 300);
  };

  return (
    <div>
      <div ref={cardRef}>
        <ActivityItem key={activity.activityId} elevation={elevation}>
          <CardActionArea component={Link} to={`/activities/${activity.activityId}`}>
            <CardContent>
              <ActivityHeader>
                <ActivityDetails>
                  <RiderName>
                    <Typography variant="h5">{`${activity.riderFirstName} ${activity.riderLastName}`}</Typography>
                  </RiderName>
                  <ActivityDate>
                    <Typography variant="caption">
                      <DateAtTime time={activity.dateTime} />
                    </Typography>
                  </ActivityDate>
                  <MotoDNALogo>
                    <img src={logoAlt} alt="logo" style={{ height: "21px" }} />
                  </MotoDNALogo>
                  <ActivityName>
                    <Typography variant="h5">{activity.activityType}</Typography>
                    {userStore.userDetails?.given_name === activity.riderFirstName && userStore.userDetails?.family_name === activity.riderLastName && (
                      <ShareIconButton edge="end" onClick={createShare} disabled={isShareInProgress}>
                        <ShareIcon />
                      </ShareIconButton>
                    )}
                  </ActivityName>
                  <ActivityWeather>
                    <Caption>Weather - {activity.wetWeather ? "Wet" : "Dry"}</Caption>
                  </ActivityWeather>
                </ActivityDetails>
                <Scores>
                  <LabelGauge value={activity.overallScore} label="Overall" size={isSmall ? 50 : 80} />
                  {activity.skillScores
                    .sort((a, b) => skillNameComparer(a.key, b.key))
                    .map((score, i) => (
                      <LabelGauge key={i} value={score.value} label={score.key} size={isSmall ? 40 : 70} />
                    ))}
                </Scores>
              </ActivityHeader>
              <ActivityMapContainer>
                <ActivityMap activity={activity} />
              </ActivityMapContainer>
            </CardContent>
          </CardActionArea>
        </ActivityItem>
      </div>
    </div>
  );
});

export const ActivityCardLoading = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ActivityItem>
      <CardContent>
        <ActivityHeader>
          <ActivityDetails>
            <ActivityName>
              <Typography variant="h5">
                <Skeleton />
              </Typography>
            </ActivityName>
            <ActivityWeather>
              <Skeleton />
            </ActivityWeather>
            <RiderName>
              <Typography variant="h5">
                <Skeleton />
              </Typography>
            </RiderName>
            <ActivityDate>
              <Skeleton />
            </ActivityDate>
          </ActivityDetails>
          <Scores>
            <Skeleton variant="circle" width={isSmall ? 60 : 80} height={isSmall ? 60 : 80} />
            {Array.from(new Array(3)).map((_, i) => (
              <Skeleton key={i} variant="circle" width={isSmall ? 55 : 70} height={isSmall ? 55 : 70} />
            ))}
          </Scores>
        </ActivityHeader>
        <ActivitySkeletonMap variant="rect" />
      </CardContent>
    </ActivityItem>
  );
};

const ActivityItem = styled(Card)`
  margin-bottom: ${(props) => props.theme.spacing * 6}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-bottom: ${(props) => props.theme.spacing * 4}px;
  }
`;

const ActivityHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.spacing * 2}px;
  justify-content: space-between;
`;

const ActivityDetails = styled.div`
  display: grid;
  grid-template:
    "rider-name logo activity-name"
    "activity-date logo activity-weather";
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template:
      "rider-name logo"
      "activity-name logo"
      "activity-date logo"
      "activity-weather logo";
  }
`;

const ActivityDate = styled.div`
  grid-area: activity-date;
  white-space: nowrap;
`;

const RiderName = styled.div`
  grid-area: rider-name;
  min-width: 150px;
`;

const ActivityName = styled.div`
  grid-area: activity-name;
  white-space: nowrap;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    * {
      text-align: left;
    }
  }
`;

const ActivityWeather = styled.div`
  grid-area: activity-weather;
  white-space: nowrap;
  text-align: right;

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    text-align: left;
    * {
      text-align: left;
    }
  }
`;

const ShareIconButton = styled(IconButton)`
  position: relative;
  top: -12px;
  margin-bottom: -16px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    position: absolute;
    top: 0px;
    right: 12px;
  }
`;

const MotoDNALogo = styled.div`
  grid-area: logo;
  padding-top: 6px;
  img {
    display: none;
  }
`;

const ActivityMapContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing * 4}px;
  height: 300px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: ${(props) => props.theme.spacing * 2}px;
    height: 200px;
  }
`;

const ActivitySkeletonMap = styled(Skeleton)`
  height: 300px;
  margin-top: ${(props) => props.theme.spacing * 4}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: ${(props) => props.theme.spacing * 2}px;
    height: 200px;
  }
`;

const Scores = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.spacing * 4}px;
  grid-auto-flow: column;
  align-items: flex-end;
  grid-auto-columns: auto;
  justify-content: space-around;
  margin: 0 15%;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-gap: 0;
    margin: 0;
  }
`;
