import React, { useState } from "react";
import { userStore } from "./userStore";
import { observer } from "mobx-react";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { PageGrid } from "components/ui";
import { BodyText, Heading, Link } from "components/primitives";
import { useAuth0 } from "@auth0/auth0-react";
import { resendEmailVerification } from "api/rider";
import { Alert } from "@material-ui/lab";

export const EnsureEmailVerified = observer(({ children }: { children: React.ReactNode }) => {
  const [resendEmailStatus, setResendEmailStatus] = useState<"Sending" | "Sent" | "Failed" | undefined>(undefined);
  const { logout } = useAuth0();

  if (!userStore.userDetails?.email_verified) {
    return (
      <PageGrid>
        <Dialog open>
          <DialogContent>
            <Heading>Please verify your email</Heading>
            <BodyText gutterBottom>Click the link in the verification email sent to {userStore.userDetails?.email}.</BodyText>
            <BodyText gutterBottom>Once you have done this, log back in to continue.</BodyText>
            <BodyText gutterBottom>
              Be sure to check all email folders including spam. If you have not received your email within 10 minutes, please <Link href="https://motodna.com/contact-us/">contact us</Link> to resolve the issue.
            </BodyText>

            {resendEmailStatus === "Failed" && (
              <Alert severity="error" style={{ marginBottom: "16px" }}>
                Unable to re-send verification email. Please try again.
              </Alert>
            )}
            {resendEmailStatus === "Sent" && (
              <Alert severity="success" style={{ marginBottom: "16px" }}>
                Verification email sent
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                logout({ returnTo: window.location.origin });
              }}
            >
              Continue
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={resendEmailStatus === "Sent" || resendEmailStatus === "Sending"}
              onClick={async () => {
                try {
                  setResendEmailStatus("Sending");
                  await resendEmailVerification();
                  setResendEmailStatus("Sent");
                } catch {
                  setResendEmailStatus("Failed");
                }
              }}
              style={{ marginLeft: "8px" }}
            >
              Re-Send Email
            </Button>
          </DialogContent>
        </Dialog>
      </PageGrid>
    );
  }

  return <>{children}</>;
});
