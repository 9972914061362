import axios from "axios";
import { initTasks, progressUpdater } from "./taskProgress";
import { uploadFile } from "./uploadFile";
import { apiConfiguration } from "plumbing/api";

export const uploadVideos = async (activityId: string, videos: DrillVideo[], onVideoUploadProgress: (percentComplete: number) => any) => {
  const videoUploadTasks = initTasks(videos, (v) => v.drillName);
  const updateProgress = () => progressUpdater(onVideoUploadProgress, 0.9)(videoUploadTasks);

  for (let i = 0; i < videos.length; i++) {
    const video = videos[i];

    const uploadFileInfo = {
      name: `${activityId}/${video.drillName}.mp4`,
      fileType: video.file.type,
      file: video.file
    };

    const metadata = {
      drillName: video.drillName,
      activityId: activityId,
    };

    const onUploadProgress = (percentComplete: number): void => {
      videoUploadTasks[video.drillName] = percentComplete;
      updateProgress();
      console.log(`${video.drillName} video: ${Math.round(percentComplete)}%`);
    };

    await uploadFile(uploadFileInfo, metadata, onUploadProgress);
  }

  await axios.post(`${apiConfiguration.apiUrl}/activities/${activityId}/encode-videos`, {
    drillNames: videos.map((v) => v.drillName),
  });
};
