import { BodyText, Link } from "components/primitives";
import React from "react";

export const DropboxVideoUploadInstructions = () => {
  return (
    <BodyText>
      If your track is not listed, please upload your unedited video to the link below and submit the following information via our{" "}
      <Link href="https://motodna.com/contact-us/">contact form</Link>. Videos are normally processed within a week.
      <pre>
        <BodyText>
          Uploaded Video Name
          <br />
          Rider Name
          <br />
          Rider Email
          <br />
          Track & Configuration
          <br />
          Bike Make and Model
          <br />
          GoPro Version
        </BodyText>
      </pre>
      <BodyText>
        <Link href="https://www.dropbox.com/request/XDXwnvGc9HsKIvkLGLki">Upload Video</Link>.
      </BodyText>
    </BodyText>
  );
};
