import axios from "axios";
import { apiConfiguration } from "plumbing/api";

type InitiateMultipartFileUpload = {
  uploadId: string
};

export const initiateMultipartFileUpload = async (fileName: string, contentType: string) => {
  const url = `${apiConfiguration.apiUrl}/multipart-file-upload/initiate`;
  const response = await axios.post<InitiateMultipartFileUpload>(url, {
    fileName,
    contentType
  });
  return response.data.uploadId;
}
