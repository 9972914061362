import React from "react";
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Hidden, ListSubheader } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Upload from "@material-ui/icons/CloudUpload";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "assets/logo.png";
import logoAlt from "assets/logo white gold.png";
import styled, { css } from "styled-components";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { Link } from "components/primitives";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const Navbar = observer(() => {
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
  const accountMenu = usePopupState({ variant: "popover", popupId: "accountMenu" });
  const siteMenu = usePopupState({ variant: "popover", popupId: "siteMenu" });
  const SiteMenuItem = React.forwardRef(({ to, children }: { to: string; children: React.ReactNode }, ref: any) => {
    return (
      <MenuItem component={RouterLink} to={to} onClick={siteMenu.close} ref={ref}>
        {children}
      </MenuItem>
    );
  });

  if (window.location.pathname === "/loading") return null;

  return (
    <StyledAppBar position="static" color="primary" isAuthenticated={isAuthenticated}>
      <Toolbar>
        <Left>
          {!isAuthenticated && (
            <LogoContainer>
              <Link
                to="/"
                style={{
                  display: "flex",
                  textDecoration: "none",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "flex-start",
                }}
              >
                <Logo src={logoAlt} alt="logo" style={{ height: "21px" }} />
                <LogoTitle style={{ color: "white" }}>Digital Academy</LogoTitle>
              </Link>
            </LogoContainer>
          )}
          {isAuthenticated && (
            <>
              <IconButton edge="start" aria-label="Main Menu" aria-haspopup="true" {...bindTrigger(siteMenu)} color="secondary">
                <MenuIcon />
              </IconButton>
              <Menu {...bindMenu(siteMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                <SiteMenuItem to="/">Activity Feed</SiteMenuItem>
                <SiteMenuItem to="/training">Training</SiteMenuItem>
                <SiteMenuItem to="/upload-activity">Upload</SiteMenuItem>
                <SiteMenuItem to="/groups">Groups</SiteMenuItem>
                <SiteMenuItem to="/challenges">Challenges</SiteMenuItem>
                <SiteMenuItem to="/challenges/easbk">eASBK</SiteMenuItem>

                <StyledListSubheader>Instructions</StyledListSubheader>
                <SiteMenuItem to="/instructions/camera-set-up">Camera Set-Up & Upload</SiteMenuItem>
                <SiteMenuItem to="/instructions/streetSKILLS-101">streetSKILLS 101</SiteMenuItem>
                <SiteMenuItem to="/instructions/trackSKILLS-101">trackSKILLS 101</SiteMenuItem>
                <SiteMenuItem to="/instructions/trackSKILLS">trackSKILLS</SiteMenuItem>
              </Menu>
            </>
          )}
        </Left>
        {isAuthenticated && (
          <LogoContainer>
            <Link to="/" style={{ display: "flex", textDecoration: "none", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Logo src={logo} alt="logo" />
              <LogoTitle>Digital Academy</LogoTitle>
            </Link>
          </LogoContainer>
        )}
        {isAuthenticated ? (
          <Right>
            <Hidden smDown>
              <Button component={RouterLink} variant="outlined" color="secondary" startIcon={<Upload />} to="/upload-activity">
                Upload Activity
              </Button>
            </Hidden>
            <IconButton edge="end" aria-label="Account Menu" {...bindTrigger(accountMenu)} color="secondary">
              <AccountCircle />
            </IconButton>
            <Menu {...bindMenu(accountMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
              <MenuItem component={RouterLink} to="/manage-subscription" onClick={accountMenu.close}>
                Manage Subscription
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                  accountMenu.close();
                }}
              >
                Log Out
              </MenuItem>
            </Menu>
          </Right>
        ) : (
          <Right style={{ display: "flex", gap: "24px", alignItems: "center", justifyContent: "flex-end" }}>
            <Hidden xsDown>
              <Link to="/">Home</Link>
              <Link href="https://motodna.com/story/">About</Link>
              <Link href="https://motodna.com/contact-us/">Contact</Link>
            </Hidden>
            <Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          </Right>
        )}
      </Toolbar>
    </StyledAppBar>
  );
});

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.img`
  height: 32px;
`;

const LogoTitle = styled.div`
  color: #545454;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const Left = styled.div`
  flex: 1;
`;
const Right = styled.div`
  flex: 1;
  text-align: right;
`;

const StyledListSubheader = styled(ListSubheader)`
  line-height: 1;
  margin-top: ${(props) => props.theme.spacing * 3}px;
  margin-bottom: ${(props) => props.theme.spacing}px;
`;

const StyledAppBar = styled(({ isAuthenticated, ...props }) => <AppBar {...props} />)<{ isAuthenticated: boolean }>`
  transition: background-color 0.5s ease;
  ${(props) =>
    !props.isAuthenticated &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}
`;
