import { ClickAwayListener, Link, Tooltip } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";

type InfoToolTipProps = {
  streetText: React.ReactElement;
  trackText: React.ReactElement;
  activitySkillType: string;
};

export const InfoTooltip = ({ streetText: streetSkills, trackText: trackSkills, activitySkillType }: InfoToolTipProps) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tip = activitySkillType === "Street" ? streetSkills : trackSkills;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip placement="right-end" title={tip} onClose={handleTooltipClose} open={open}>
        <Link color="textPrimary" onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen}>
          <InfoIcon style={{ fontSize: "inherit", verticalAlign: "-10%" }} />
        </Link>
      </Tooltip>
    </ClickAwayListener>
  );
};
