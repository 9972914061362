const gpmfExtract = require("gpmf-extract");
const goproTelemetry = require("gopro-telemetry");

export const extractGoProMetadata = async (file: Blob, onProgress: any): Promise<any> => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      return await gpmfExtract(file, true, onProgress).then((result: any) => {
        goproTelemetry(result).then((data: any) => {
          resolve({
            result,
            data,
          });
        });
      });
    } catch (error) {
      reject();
      console.log(error);
    }
  });
};
