import React, { useState, useEffect } from "react";
import { LoadingPanel, BodyText, ConfirmDialog } from "components/primitives";
import { ListItem, ListItemText, Button, ListItemAvatar, Avatar, Card, ListItemSecondaryAction, Menu, MenuItem, ListItemIcon, IconButton } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { LONG_DATE_FORMAT } from "DateFormats";
import { useApiData } from "plumbing/api";
import styled from "styled-components";
import { usePopupState, bindMenu, bindTrigger } from "material-ui-popup-state/hooks";
import RemoveIcon from "@material-ui/icons/Remove";
import MoreIcon from "@material-ui/icons/MoreVert";
import { observer } from "mobx-react";
import { userStore } from "plumbing/auth";
import uniqBy from "lodash/uniqBy";

export type RiderListRiderModel = {
  id: number;
  firstName: string;
  lastName: string;
  joined: string;
  email?: string;
};

type RiderListProps = {
  query: (pagedRequest: PagedRequest) => Promise<{ data: { riders: RiderListRiderModel[]; totalRiders: number } }>;
  noRidersMessage: string;
  removeRider?: (riderId: number) => Promise<any>;
  removeRiderConfirmText?: string;
  onChange?: () => void;
  addRider?: () => void;
  showJoinedDate?: boolean;
};

export const RiderList = ({ query, noRidersMessage, removeRider, onChange, removeRiderConfirmText, addRider, showJoinedDate = true }: RiderListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [riders, setRiders] = useState<RiderListRiderModel[]>([]);

  const { data, loadingState, fetchData, setData } = useApiData(
    () => {
      return query({ pageNumber: currentPage, pageSize: 8 });
    },
    undefined,
    [currentPage]
  );

  useEffect(() => {
    if (!data) return;
    setRiders((r) => uniqBy([...r, ...data.riders], "id"));
  }, [data]);

  const [isConfirmRemoveRiderDialogOpen, setIsConfirmRemoveRiderDialogOpen] = useState(false);
  const [selectedRider, setSelectedRider] = useState<RiderListRiderModel | undefined>();
  const handleRemoveRider = (rider: RiderListRiderModel) => async () => {
    setIsConfirmRemoveRiderDialogOpen(true);
    setSelectedRider(rider);
  };

  return (
    <LoadingPanel loadingState={loadingState}>
      {loadingState.hasLoaded && addRider && (
        <div style={{ paddingBottom: "20px" }}>
          <Button onClick={addRider} variant="contained" color="primary">
            Add Member
          </Button>
        </div>
      )}
      {data && loadingState.hasLoaded && riders.length > 0 && (
        <RiderListContainer>
          {riders.map((rider) => (
            <RiderCard
              key={`${rider.firstName} ${rider.lastName}`}
              rider={rider}
              removeRider={removeRider ? handleRemoveRider(rider) : undefined}
              showJoinedDate={showJoinedDate}
            />
          ))}
        </RiderListContainer>
      )}
      {data && riders.length < data.totalRiders && (
        <div style={{ paddingTop: "24px" }}>
          <Button disabled={loadingState.isLoading} onClick={() => setCurrentPage(currentPage + 1)} variant="outlined">
            Load More
          </Button>
        </div>
      )}
      {data && !loadingState.isLoading && riders.length === 0 && <BodyText>{noRidersMessage}</BodyText>}
      {selectedRider && (
        <ConfirmDialog
          title="Are you sure?"
          open={isConfirmRemoveRiderDialogOpen}
          onClose={async (confirmed) => {
            if (confirmed) {
              if (removeRider) await removeRider(selectedRider.id);
              setRiders([]);
              setData({ riders: [], totalRiders: 0 });
              setCurrentPage(1);
              if (onChange) onChange();
              await fetchData();
            }
            setIsConfirmRemoveRiderDialogOpen(false);
          }}
        >
          {removeRiderConfirmText}
        </ConfirmDialog>
      )}
    </LoadingPanel>
  );
};

const RiderListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const RiderCard = observer(({ rider, removeRider, showJoinedDate }: { rider: RiderListRiderModel; removeRider?: (riderId: number) => Promise<any>; showJoinedDate: boolean }) => {
  const riderMenu = usePopupState({
    variant: "popover",
    popupId: `riderMenu-${rider.id}`,
  });

  return (
    <StyledCard elevation={6}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {rider.firstName.substring(0, 1)}
            {rider.lastName.substring(0, 1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${rider.firstName} ${rider.lastName}`}
          secondary={
            <div>
              {rider.email && <div>{rider.email}</div>}
              {showJoinedDate && <div>Joined {format(parseISO(rider.joined), LONG_DATE_FORMAT)}</div>}
            </div>
          }
        />
        {userStore.isAdmin && removeRider && (
          <ListItemSecondaryAction>
            <IconButton aria-label="Rider Menu" {...bindTrigger(riderMenu)}>
              <MoreIcon />
            </IconButton>
            <Menu {...bindMenu(riderMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
              <MenuItem
                onClick={async () => {
                  riderMenu.close();
                  await removeRider(rider.id);
                }}
              >
                <ListItemIcon>
                  <RemoveIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Remove Rider" />
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </StyledCard>
  );
});

const StyledCard = styled(Card)`
  li {
    list-style-type: none !important;
  }
`;
