import React from "react";
import { Helmet } from "react-helmet";
import { PageGrid, CreateEditGroupForm, GroupFormData } from "components/ui";
import { Heading } from "components/primitives";
import { createGroup } from "api/group";
import { useHistory } from "react-router-dom";

export const CreateGroup = () => {
  let history = useHistory();
  const handleSubmit = async (data: GroupFormData) => {
    const request: CreateGroupRequest = {
      name: data.name,
      description: data.description,
      activitySkillType: data.activitySkillType,
      image: data.photo![0],
      signUpText: data.signupText,
      questions: data.signUpQuestions?.map((q) => ({ questionType: q.questionType, label: q.label, options: q.options })) || [],
      coaches: data.coaches?.map((c) => ({ id: c.id! })) || [],
    };

    const response = await createGroup(request);

    history.push(`/groups/${response.data.slug}`);
  };
  return (
    <>
      <Helmet>
        <title>Digital Academy - Create Group</title>
      </Helmet>
      <PageGrid>
        <Heading>Create Group</Heading>
        <CreateEditGroupForm onSubmit={handleSubmit} />
      </PageGrid>
    </>
  );
};
