import React from "react";
import { Typography } from "@material-ui/core";

export const BodyText = ({
  children,
  align,
  variant = "body1",
  gutterBottom = false,
  ...props
}: {
  children: React.ReactNode;
  align?: "center" | "left" | "right";
  className?: string;
  variant?: "body1" | "body2";
  gutterBottom?: boolean;
}) => {
  return (
    <Typography variant={variant} {...props} align={align} style={{ marginBottom: gutterBottom ? "12px" : "0" }}>
      {children}
    </Typography>
  );
};
