import React, { useState } from "react";
import { LoadingSpinner } from "..";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import styled from "styled-components";

interface ConfirmDialogProps {
  title: string;
  open: boolean;
  onClose: (confirmed: boolean) => Promise<any>;
  confirmText?: string;
  cancelText?: string;
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const ConfirmDialog = ({
  title,
  open,
  onClose,
  confirmText = "Confirm",
  cancelText = "Cancel",
  loading,
  disabled,
  children,
}: ConfirmDialogProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const handleClose = (confirmed: boolean) => async () => {
    setIsClosing(true);
    await onClose(confirmed);
    setIsClosing(false);
  };
  return (
    <Dialog open={open} onClose={handleClose(false)}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={loading || disabled || isClosing} onClick={handleClose(true)}>
          {loading && <StyledLoadingSpinner small />} {confirmText}
        </Button>

        <Button variant="outlined" onClick={handleClose(false)} disabled={loading || disabled || isClosing}>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-right: 8px;
`;
