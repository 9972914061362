import React from "react";
import styled from "styled-components";
import { DateAtTime } from "components/ui";
import { Subheading, Caption } from "components/primitives";

export const ActivityDetailsHeader = ({ activity }: { activity: ActivityModel }) => {
  const wetWeather = activity.drills.filter(x => x.wetWeather).length > 0;

  return (
    <ActivityDetails>
      <ActivityName>
        <Subheading>{activity.activityType}</Subheading>
      </ActivityName>
      <ActivityWeather>Weather - {wetWeather ? "Wet" : "Dry"}</ActivityWeather>
      <RiderAvatar></RiderAvatar>
      <RiderName>
        <Subheading>
          {activity.riderFirstName} {activity.riderLastName}
        </Subheading>
      </RiderName>
      <ActivityDate>
        <Caption>
          <DateAtTime time={activity.dateTime} />
        </Caption>
      </ActivityDate>
    </ActivityDetails>
  );
};

const ActivityDetails = styled.div`
  grid-area: activity-details;
  display: grid;
  grid-template:
    "rider-name activity-name"
    "activity-date activity-weather";
  grid-template-columns: auto 1fr;
  align-content: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "rider-name"
      "activity-name"
      "activity-date"
      "activity-weather";
    grid-template-columns: 1fr;
  }
`;

const ActivityName = styled.span`
  grid-area: activity-name;
  text-align: right;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    text-align: left;
  }
`;

const ActivityWeather = styled.div`
  grid-area: activity-weather;
  text-align: right;
`;

const ActivityDate = styled.span`
  grid-area: activity-date;
`;
const RiderAvatar = styled.div`
  grid-area: rider-avatar;
  align-self: center;
`;
const RiderName = styled.div`
  grid-area: rider-name;
`;
