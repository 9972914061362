import React from "react";
import { Grid } from "@material-ui/core";

type FormItemProps = { children?: React.ReactNode } & GridItemProps;

export const FormItem = ({ children, ...gridItemProps }: FormItemProps) => {
  return (
    <Grid item xs={12} {...gridItemProps} alignItems="flex-end" style={{ display: "flex" }}>
      {children}
    </Grid>
  );
};
