import React from "react";

export const CoastingIndicator = ({ value }: { value: boolean }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="100%" width="100%" style={{ fontFamily: "Work Sans" }}>
      <text x="50" y="10" textAnchor="middle" dominantBaseline="central" alignmentBaseline="central" fontSize="15pt" fill="#fff">
        COASTING
      </text>
      <rect x="30" y="25" height="35" width="35" stroke="#fff" strokeWidth="3px" rx="2" fill={value ? "#f3bf30" : "transparent"} />
    </svg>
  );
};
