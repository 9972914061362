import React from "react";
import { Helmet } from "react-helmet";
import { Heading } from "components/primitives";
import { Button, Container } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import heroImage from "assets/iPhone collage.png";

export const Landing = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Helmet>
        <title>Digital Academy - motoDNA</title>
      </Helmet>
      <StyledPageGrid>
        <Container fixed maxWidth="md">
          <HeroSection>
            <HeroHeading gutterBottom={false}>Your Virtual Motorcycle Coach</HeroHeading>
            <HeroImage src={heroImage}></HeroImage>
            <ActionButtons>
              <Button size="large" color="primary" variant="contained" onClick={loginWithRedirect}>
                Get Started
              </Button>
            </ActionButtons>
          </HeroSection>
        </Container>
      </StyledPageGrid>
    </>
  );
};

const StyledPageGrid = styled.div`
  text-align: center;
`;

const PageSection = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => {
  return (
    <Container fixed maxWidth="md" ref={ref}>
      <StyledPageSection>{children}</StyledPageSection>
    </Container>
  );
});

const StyledPageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0;
  & > * + * {
    margin-top: 24px;
  }
`;

const HeroSection = styled(PageSection)`
  height: 900px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    height: 800px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    height: 600px;
  }
`;

const HeroHeading = styled(Heading)`
  @media (min-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    font-size: 3.2em;
    font-weight: 500;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 2.7em;
    font-weight: 500;
  }
`;

const HeroImage = styled.img`
  object-fit: contain;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  button {
    padding: 12px 36px;
    @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
      padding: 12px 4px;
      flex: 1;
      font-size: 0.83rem;
    }
  }
  & > * + * {
    margin-left: 24px;
  }
`;
