import React from "react";
import { Helmet } from "react-helmet";
import { PageGrid, CreateEditGroupForm, GroupFormData } from "components/ui";
import { Heading, LoadingPanel } from "components/primitives";
import { getGroupBySlug, editGroup } from "api/group";
import { useHistory, useParams } from "react-router-dom";
import { useApiData } from "plumbing/api";

export const EditGroup = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();

  const { data: groupDetails, loadingState } = useApiData(() => getGroupBySlug(slug));

  const handleSubmit = async (data: GroupFormData) => {
    const request: EditGroupRequest = {
      name: data.name,
      description: data.description,
      activitySkillType: data.activitySkillType,
      image: data.photo![0],
      signUpText: data.signupText,
      questions: data.signUpQuestions?.map((q) => ({ ...q, id: q.questionId || 0, questionId: undefined })) || [],
      coaches: data.coaches?.map((c) => ({ id: c.id! })) || [],
    };

    const response = await editGroup(groupDetails?.slug!, request);

    history.push(`/groups/${response.data.slug}`);
  };

  const initialFormValues: GroupFormData | undefined = groupDetails
    ? {
        name: groupDetails?.name,
        activitySkillType: groupDetails?.activitySkillType,
        description: groupDetails?.description,
        imageUrl: groupDetails?.imageUrl,
        signupText: groupDetails?.signUpText,
        signUpQuestions: groupDetails?.signUpQuestions.map((q) => ({
          questionId: q.id,
          label: q.label,
          questionType: q.questionType,
          options: q.optionsValues?.join(",") || "",
        })),
        coaches: groupDetails?.coaches,
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title>Digital Academy - Edit Group</title>
      </Helmet>
      <PageGrid>
        <Heading>Edit Group</Heading>
        <LoadingPanel loadingState={loadingState}>{initialFormValues && <CreateEditGroupForm onSubmit={handleSubmit} initialValues={initialFormValues} />}</LoadingPanel>
      </PageGrid>
    </>
  );
};
