import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useForm, FieldValues, FormProvider, UnpackNestedValue, DeepPartial, UseFormMethods } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isEqual } from "lodash";
import { Alert } from "@material-ui/lab";

type FormProps<T extends FieldValues> = {
  children: React.ReactNode;
  onSubmit: (data: T) => Promise<any>;
  schema?: yup.AnyObjectSchema;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  debug?: boolean;
  onChange?: (data: T, formMethods: UseFormMethods<T>) => void;
};

export const Form = <T extends FieldValues>({ children, onSubmit, schema, defaultValues, onChange, debug }: FormProps<T>) => {
  const formMethods = useForm<T>({
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues: defaultValues,
    shouldFocusError: false, // Since not all our components are registered using the register function, this does not function as expected
  });
  const watchAllFields = formMethods.watch();
  const [formValues, setFormValues] = useState<UnpackNestedValue<T> | undefined>(undefined);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  useEffect(() => {
    if (isEqual(watchAllFields, formValues) || !watchAllFields) return;

    setFormValues(watchAllFields);
    if (onChange) onChange(watchAllFields as T, formMethods);
  }, [formValues, onChange, watchAllFields, formMethods]);

  const handleSubmit = async (data: UnpackNestedValue<T>) => {
    setHasSubmitError(false);
    try {
      await onSubmit(data as T);
    } catch (error) {
      console.error(error);
      setHasSubmitError(true);
    }
  };

  return (
    <FormProvider {...formMethods}>
      {hasSubmitError && (
        <Alert severity="error" style={{ marginBottom: "16px" }}>
          There was an error submitting the form. Please try again and contact support if the issue persists.
        </Alert>
      )}
      <form onSubmit={formMethods.handleSubmit(handleSubmit, console.dir)}>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </form>
      {(debug || (window as any).debugForms) && (
        <>
          Errors: <pre>{JSON.stringify(formMethods.errors, null, 2)}</pre>
        </>
      )}
      {(debug || (window as any).debugForms) && (
        <>
          Form Values: <pre> {JSON.stringify(formValues, null, 2)}</pre>
        </>
      )}
    </FormProvider>
  );
};
