import React from "react";
import { PageGrid, ContentPageSection, Accordion, AccordionSummaryWithIcon } from "components/ui";
import { Helmet } from "react-helmet";
import { Heading, BodyText } from "components/primitives";
import { AccordionDetails, Typography } from "@material-ui/core";
import figure8Setup from "assets/instructions/Figure 8 setup.jpg";
import figure8Drill from "assets/instructions/Figure 8 drill.jpg";
import { Alert } from "@material-ui/lab";

export const TrackSkills101 = () => {
  return (
    <>
      <Helmet>
        <title>Digital Academy - trackSKILLS 101</title>
      </Helmet>
      <PageGrid>
        <Heading>trackSKILLS 101</Heading>
        <div>
          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Setup</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Figure 8 Set Up" imageUrl={figure8Setup} imageAlt="Figure 8 Set Up Image">
                  <BodyText>Requirements:</BodyText>

                  <ul>
                    <li>70m (230ft) x 40m (130ft) flat and clean riding area, with landowners permission</li>
                    <li>Tape measure & chalk (Cones are optional)</li>
                  </ul>

                  <BodyText>Set Up:</BodyText>

                  <ul>
                    <li>Accurately mark out the riding area as shown</li>
                    <li>You can use chalk or cones to mark the 5 corners</li>
                  </ul>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummaryWithIcon>
              <Typography variant="h3">Riding instructions</Typography>
            </AccordionSummaryWithIcon>
            <AccordionDetails>
              <div>
                <ContentPageSection title="Figure 8" imageUrl={figure8Drill} imageAlt="Figure 8 Instructions Image">
                  <ul>
                    <li>With the bike at the (green) start/finish line, begin recording with the GoPro</li>
                    <li>
                      Complete two laps of the Figure 8 as illustrated, riding at 95% of your ability, coming to a quick stop at the start
                      finish line at the end of the second lap. Stop recording
                    </li>
                    <li>
                      The goal is to show your throttle, braking and steering skills. Good vision, body position and keeping tight to the
                      cones will help your skill scores
                    </li>
                  </ul>
                  <Alert severity="warning" style={{ marginTop: "12px" }}>
                    <BodyText>Important:</BodyText>
                    <ul>
                      <li>
                        Only one rider at a time should be inside the riding area 70m (230ft) x 40m (130ft)
                      </li>
                      <li>
                        Ensure that you are wearing appropriate riding gear at all times
                      </li>
                      <li>
                        Remember to ride within your skill level
                      </li>
                    </ul>
                  </Alert>
                </ContentPageSection>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageGrid>
    </>
  );
};
