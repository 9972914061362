import axios from "axios";
import {apiConfiguration} from "plumbing/api";
import {initTasks, progressUpdater} from "./taskProgress";
import {VideoData} from "./extractData";
import {uploadFile} from "./uploadFile";

export const uploadData = async (activityId: string, videoData: VideoData, onDataUploadProgress: (percentComplete: number) => any) => {
  const dataUploadTasks = initTasks(Object.keys(videoData), (v) => v);
  const updateProgress = () => progressUpdater(onDataUploadProgress, 0.9)(dataUploadTasks);

  const drillNames = Object.keys(videoData);
  const wetWeathers: { [key: string]: boolean } = {};
  for (let i = 0; i < drillNames.length; i++) {
    const drillName = drillNames[i];
    const data = JSON.stringify(videoData[drillName].data);
    wetWeathers[drillName] = JSON.parse(JSON.stringify(videoData[drillName].wetWeather));

    const blob = new Blob([data], { type: "application/json" });

    const uploadFileInfo = { name: `${activityId}/${drillName}.json`, file: blob };
    const metadata = {
      drillName: drillName,
      activityId: activityId,
    };
    const onUploadProgress = (percentComplete: number): void => {
      dataUploadTasks[drillName] = percentComplete;
      updateProgress();
      console.log(`${drillName} data: ${Math.round(percentComplete)}%`);
    };

    await uploadFile(uploadFileInfo, metadata, onUploadProgress);
  }

  await axios.post(`${apiConfiguration.apiUrl}/activities/${activityId}/process-json`, {
    drillNames: drillNames,
    wetWeathers: wetWeathers,
  });
};
