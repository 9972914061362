// Ideally this would be done for us in the database, but for now we hard-code the required order here
// const drillOrder = ['FIGURE 8', 'EMERGENCY STOP', 'AVOIDANCE', 'CORNER AVOIDANCE', 'BALANCE',  'U TURN', 'TAKE OFF'];
const drillOrder = ['FIGURE 8', 'EMERGENCY STOP', 'AVOIDANCE', 'CORNER AVOIDANCE', 'BALANCE'];

export function drillNameComparer(a: string, b: string) {
  return drillOrder.indexOf(a.trim().toUpperCase()) - drillOrder.indexOf(b.trim().toUpperCase());
}

export function drillNameDisplay(a: string) {
  return drillOrder.indexOf(a.trim().toUpperCase()) >= 0;
}