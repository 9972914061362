interface ApiConfiguration {
  url: string;
  apiUrl: string;
  fileUploadUrl: string;
}

let baseUrl = process.env.REACT_APP_ApiUrl;

if (baseUrl === undefined) {
  throw new Error("API Url is undefined");
}

if (baseUrl.substr(-1) !== "/") {
  baseUrl += "/";
}

export const apiConfiguration: ApiConfiguration = {
  url: baseUrl,
  apiUrl: baseUrl + "api",
  fileUploadUrl: baseUrl + "files",
};
