import React from "react";
import { FormControl, InputLabel, Select, FormHelperText, Grid, MenuItem } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { FormItem } from "../FormItem";
import get from "lodash/get";

type SelectFieldProps = {
  label: string;
  name: string;
  fullWidth?: boolean;
  multiple?: boolean;
  renderValue?: (value: unknown) => React.ReactNode;
  disabled?: boolean;
  autoFocus?: boolean;
  defaultValue?: string | string[];
} & (
  | {
      children: React.ReactNode;
    }
  | { items: string[] }
);

export const SelectField = ({ label, name, multiple, renderValue, defaultValue, disabled, autoFocus, fullWidth = true, ...props }: SelectFieldProps) => {
  const { control, errors, formState } = useFormContext();

  if (!defaultValue) defaultValue = multiple ? [] : "";

  const menuItems = (props as any).items
    ? (props as any).items.map((i: string) => (
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      ))
    : [];
  return (
    <FormControl fullWidth error={get(errors, name)?.message !== undefined}>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        as={
          <Select fullWidth={fullWidth} multiple={multiple} renderValue={renderValue} disabled={formState.isSubmitting || disabled} autoFocus={autoFocus}>
            {(props as any).children ? (props as any).children : menuItems}
          </Select>
        }
      />
      <FormHelperText>{get(errors, name)?.message}</FormHelperText>
    </FormControl>
  );
};

export const SelectFieldFormItem = ({ xs = 12, sm, md, lg, fullRow, ...props }: SelectFieldProps & FormItemSimpleProps) => {
  const formItem = (
    <FormItem xs={xs} sm={sm} md={md} lg={lg}>
      <SelectField {...props} />
    </FormItem>
  );

  return fullRow ? (
    <Grid item container xs={12}>
      {formItem}
    </Grid>
  ) : (
    formItem
  );
};
