import React from "react";
import { FormItem, CheckboxField, FileInputField } from "components/primitives";
import { useWatch } from "react-hook-form";
import styled from "styled-components";

export const VideoFormItems = ({ activityTypes, allowDataOnly = true }: { activityTypes: ActivityType[]; allowDataOnly?: boolean }) => {
  const selectedActivity = useWatch({ name: "activityType", defaultValue: "" });
  return (
    <>
      {activityTypes
        .find((at) => at.name === selectedActivity)
        ?.drills.map((d) => (
          <React.Fragment key={d.name}>
            <FormItem sm={4}>
              <FileInputField name={`videos-${d.name}`} label={d.name} accept=".mp4" />
            </FormItem>
            <FormItem sm={8}>
              {allowDataOnly && (
                <CenterVertically>
                  <CheckboxField name={`dataonly-${d.name}`} label="Data Upload Only" />
                  <CheckboxField name={`wetweather-${d.name}`} label="Wet Weather" />
                </CenterVertically>
              )}
            </FormItem>
          </React.Fragment>
        ))}
    </>
  );
};

const CenterVertically = styled.div`
  flex-direction: row;
  display: flex;
  align-items: self-end;
  height: 100%;
`;
