import { Card, CardContent, Grid, Snackbar, Typography, Hidden } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { submitContact } from "api/contact";
import { Form, FormItem, Heading, SubmitButton, TextField } from "components/primitives";
import { PageGrid } from "components/ui";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import * as yup from "yup";

import groupPhoto from "assets/group-off-track-woman-youth.jpg";

interface ContactFormData {
  firstName: string;
  lastName: string;
  liame: string;
  subject: string;
  message: string;
}

export const Contact = () => {
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = async (data: ContactFormData) => {
    try {
      await submitContact({
        email: data.liame,
        firstName: data.firstName,
        lastName: data.lastName,
        message: data.message,
        subject: data.subject,
      });

      setIsSent(true);
    } catch {
      setIsError(true);
    }
  };

  const handleClose = (event: React.SyntheticEvent) => {
    setIsError(false);
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    LastName: yup.string(),
    liame: yup.string().required("Email is required").email("Must be a valid email address"),
    subject: yup.string().required("Subject is required"),
    message: yup.string().required("Message is required"),
  });

  return (
    <>
      <Helmet>
        <title>Digital Academy - Contact us</title>
      </Helmet>

      <PageGrid>
        <Card>
          <CardContent>
            <Heading>Contact Us</Heading>

            <Grid container spacing={4}>
              <Hidden smDown>
                <Grid item md={6}>
                  <Photo src={groupPhoto} alt="Four bike riders chatting with a race track in the background" />
                </Grid>
              </Hidden>
              <Grid item md={6} xs={12}>
                <FormContainer visible={!isSent}>
                  <Form onSubmit={onSubmit} schema={schema}>
                    <FormItem>
                      <TextField label="First name" name="firstName" />
                    </FormItem>

                    <FormItem>
                      <TextField label="Last name" name="lastName" />
                    </FormItem>

                    <FormItem>
                      {/* If we name this field 'email' then autofill always kicks in, so we trick the browser */}
                      <TextField label="Email" name="liame" />
                    </FormItem>

                    <FormItem>
                      <TextField label="Subject" name="subject" />
                    </FormItem>

                    <FormItem>
                      <TextField label="Message" name="message" multiline={true} />
                    </FormItem>

                    <FormItem>
                      <SubmitButton>Send message</SubmitButton>
                    </FormItem>

                    <Snackbar open={isError} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="error">
                        There was a problem sending your message. Please try again later
                      </Alert>
                    </Snackbar>
                  </Form>
                </FormContainer>

                {isSent && <Typography>Thanks for your message. We'll get back to you soon.</Typography>}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </PageGrid>
    </>
  );
};

const FormContainer = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
