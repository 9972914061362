import React from "react";
import styled, { css } from "styled-components";
import { Container, Grid } from "@material-ui/core";
import { BodyText, Heading } from "components/primitives";
import { Link } from "components/primitives";

export const Footer = () => {
  if (window.location.pathname === "/loading") return null;
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={6} xs={12}>
            <Heading>Data Driven by motoDNA</Heading>
            <BodyText>Launched 12 JULY 2021</BodyText>
          </Grid>
          <FooterContent item md={6} xs={12}>
            <AboutSection>
              <BodyText>About</BodyText>
              <Link href="https://motodna.com/story/">About Us</Link>
              <Link href="https://digitalacademyprod.blob.core.windows.net/assets/Privacy%20Policy.pdf?sv=2019-12-12&st=2021-02-14T21%3A55%3A22Z&se=2099-02-15T21%3A55%3A00Z&sr=b&sp=r&sig=WDm1iD0mUrB3zwRBXtVu5KzFmacrMufd%2FZsq6gpB3gQ%3D">
                Privacy Policy
              </Link>
              <Link href="https://digitalacademyprod.blob.core.windows.net/assets/Terms%20and%20Conditions.pdf?sv=2019-12-12&st=2021-02-14T21%3A55%3A57Z&se=2099-02-15T21%3A55%3A00Z&sr=b&sp=r&sig=aX3fzGZhsv81CgqmEmfhh1X1%2F10BMtoJuw%2FypDM1Mr8%3D">
                Terms and Conditions
              </Link>
            </AboutSection>

            <FollowSection>
              <BodyText>Follow</BodyText>
              <Link href="https://www.facebook.com/motodna">Facebook</Link>
              <Link href="https://www.instagram.com/motodna_">Instagram</Link>
              <Link href="https://www.youtube.com/channel/UCWx6soN6_J01oxbLdkA9KzA">YouTube</Link>
              <Link href="https://motodna.com/motofeed/">Blog</Link>
            </FollowSection>

            <SupportSection>
              <BodyText>Support</BodyText>
              <Link to="/faq">FAQ</Link>
              <Link href="https://motodna.com/contact-us/">Contact Us</Link>
            </SupportSection>
          </FooterContent>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  padding: ${(props) => props.theme.spacing * 6}px 0;
  background-color: #424242;
`;

const FooterContent = styled(Grid)`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: ${(props) => props.theme.spacing * 4}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values["sm"]}px) {
    grid-template-columns: 1fr;
    grid-gap: ${(props) => props.theme.spacing * 4}px;
  }
`;

const sectionCss = css`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing * 2}px;
`;

const AboutSection = styled.div`
  ${sectionCss}
`;

const FollowSection = styled.div`
  ${sectionCss}
`;

const SupportSection = styled.div`
  ${sectionCss}
`;
