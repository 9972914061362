import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DataExtractProgress } from "./DataExtractProgress";
import { VideoUploadProgress } from "./VideoUploadProgress";
import { DataUploadProgress } from "./DataUploadProgress";
import styled from "styled-components";
import { Subheading } from "components/primitives";

export type UploadProgressPanelProps = {
  hasVideoUpload: boolean;
  extractProgress: number;
  dataUploadProgress: number;
  videoUploadProgress: number;
  activity?: ActivityModel | null;
  onBack: () => void;
  errorMessage?: string;
  riderDetails: RiderModel | null;
  activityType: string;
};

export const UploadProgressPanel = ({
  hasVideoUpload,
  extractProgress,
  dataUploadProgress,
  videoUploadProgress,
  activity,
  onBack,
  errorMessage,
  riderDetails,
  activityType,
}: UploadProgressPanelProps) => {
  const allowPreview = activity?.status === "Complete";

  const extractHasFailed = extractProgress < 0;
  const dataUploadHasFailed = dataUploadProgress < 0;
  const videoUploadHasFailed = videoUploadProgress < 0;
  const extractMessage = extractHasFailed ? "Failed" : "Extract";
  const dataUploadMessage = dataUploadHasFailed ? "Failed" : "Data";
  const videoUploadMessage = videoUploadHasFailed ? "Failed" : "Video";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {riderDetails && (
          <Subheading gutterBottom>
            {riderDetails?.firstName} {riderDetails?.lastName} - {activityType}
          </Subheading>
        )}
        {!errorMessage && <Typography variant="body2">This might take a while. Please don't close your browser window until all activities have completed.</Typography>}
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <DataExtractProgress progress={extractProgress} message={extractMessage} hasFailed={extractHasFailed} />
      </Grid>
      <Grid item xs={12}>
        <DataUploadProgress progress={dataUploadProgress} message={dataUploadMessage} hasFailed={dataUploadHasFailed} />
      </Grid>
      {hasVideoUpload && (
        <Grid item xs={12}>
          <VideoUploadProgress progress={videoUploadProgress} message={videoUploadMessage} hasFailed={videoUploadHasFailed} />
        </Grid>
      )}
      <Grid item>
        <ViewResultsButtonContainer>
          {extractHasFailed || dataUploadHasFailed || videoUploadHasFailed || !!errorMessage ? (
            <Button onClick={onBack} variant="outlined">
              Back
            </Button>
          ) : (
            <Button component={Link} to={`/activities/${activity?.id}`} disabled={!allowPreview} variant="contained" color="primary" target="_blank">
              View Results
            </Button>
          )}
        </ViewResultsButtonContainer>
      </Grid>
    </Grid>
  );
};

export const ViewResultsButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing * 4}px;
`;
