import { format as formatDate, parseISO } from "date-fns";
import React from "react";
import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT, LONG_DATE_FORMAT } from "DateFormats";

type FormattedDateProps = { dateTime: string; format: "SHORT_DATE" | "LONG_DATE" | "DATE_WITH_TIME" | string };

export const FormattedDate = ({ dateTime, format }: FormattedDateProps) => {
  let formatString = format;

  switch (format) {
    case "SHORT_DATE":
      formatString = SHORT_DATE_FORMAT;
      break;
    case "LONG_DATE":
      formatString = LONG_DATE_FORMAT;
      break;
    case "DATE_WITH_TIME":
      formatString = DATE_WITH_TIME_FORMAT;
      break;
  }

  return <span>{formatDate(parseISO(dateTime), formatString)}</span>;
};

export const ShortDate = ({ date }: { date: string }) => <FormattedDate dateTime={date} format="SHORT_DATE" />;
export const LongDate = ({ date }: { date: string }) => <FormattedDate dateTime={date} format="LONG_DATE" />;
export const DateWithTime = ({ date }: { date: string }) => <FormattedDate dateTime={date} format="DATE_WITH_TIME" />;
