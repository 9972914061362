import React, { useState, useEffect } from "react";
import { XAxis, YAxis, ResponsiveContainer, BarChart, Bar, Tooltip } from "recharts";
import { Typography, useTheme } from "@material-ui/core";

import { Subheading, BodyText } from "components/primitives";
import styled from "styled-components";
import { InfoTooltip } from ".";
import { barChartConfig, chartConfig } from "./chartConfig";
import { TelemetryLineChart } from "./TelemetryLineChart";
import { ReferenceLines } from "AdminConstants";

type SteeringChartProps = {
  data: any;
  activityType: string;
  activitySkillType: string;
  radius?: [number, number, number, number];
  dataVersion: string;
};

export const SteeringChart = ({ data, activitySkillType, activityType, dataVersion, radius = [4, 4, 0, 0] }: SteeringChartProps) => {
  const theme = useTheme();
  const [mappedData, setMappedData] = useState((data as { X: number }[]).map((d) => ({ ...d, Actual: d.X, Target: 100 })));

  useEffect(() => {
    setMappedData((data as { X: number }[]).map((d) => ({ ...d, Actual: d.X, Target: 100 })));
  }, [data]);

  return (
    <SteeringChartContainer>
      <Subheading gutterBottom>
        Steering{" "}
        <InfoTooltip
          activitySkillType={activitySkillType}
          streetText={
            <Typography>
              This graph compares your average and maximum turning speeds during Avoidance to the best we have seen. The goal is to maximise
              both the average and maximum turning speeds to ensure that in the case of an emergency, you would be able to maneuver the bike
              to safety.
            </Typography>
          }
          trackText={
            <Typography>
              This graph shows your lean angle as you pass through a corner. The goal is to observe a single, smooth, steering input into
              the bike.
            </Typography>
          }
        />
      </Subheading>

      {!data.length && <StyledBodyText>Steering chart is not currently available</StyledBodyText>}

      {mappedData.length > 0 && activitySkillType === "Street" && (
        <ResponsiveContainer width="100%" aspect={chartConfig.aspectRatio}>
          <BarChart data={mappedData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Bar dataKey="Actual" fill={theme.palette.primary.main} radius={radius} {...barChartConfig} />
            <Bar dataKey="Target" fill="#fff" radius={radius} {...barChartConfig} />
            <XAxis dataKey="Label" fontWeight={300} />
            <YAxis />
            <Tooltip cursor={{ opacity: 0.05 }} contentStyle={{ backgroundColor: "#424242", color: "#fff" }} />
          </BarChart>
        </ResponsiveContainer>
      )}

      {mappedData.length > 0 && activitySkillType === "Track" && (
        <TelemetryLineChart
          dataVersion={dataVersion}
          data={data}
          referenceData={activityType === "trackSKILLS 101" ? ReferenceLines.TrackSkills101.Steering : ReferenceLines.Track.Steering}
          xAxisLabel={isVersionAtLeast("1.0.632", dataVersion) ? "Time - sec" : "Distance - M"}
          yAxisLabel="Lean Angle - deg"
          showXZero={true}
          showYZero={false}
          featureLabel="Apex"
          activitySkillType={activitySkillType}
        />
      )}
    </SteeringChartContainer>
  );
};

const SteeringChartContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  .xAxis text {
    fill: #fff;
  }
`;

const StyledBodyText = styled(BodyText)`
  height: 100%;
`;

const isVersionAtLeast = (requiredVersion: string, version: string): boolean => {
  // Version is a string in three parts "X.Y.Z[.A]"
  const requiredVersionSegments: number[] = requiredVersion.split(".").map((x) => parseInt(x, 10));
  const versionSegments: number[] = (version ?? "0.0.0.0").split(".").map((x) => parseInt(x, 10));

  if (requiredVersionSegments.length < 3 || versionSegments.length < 3) {
    console.dir(`Algorithm version ${version} is not in the expected format.  Assuming versions don't match for graph calculations`);
    return false;
  }

  if (versionSegments[0] < requiredVersionSegments[0]) return false;
  if (versionSegments[0] > requiredVersionSegments[0]) return true;
  if (versionSegments[1] < requiredVersionSegments[1]) return false;
  if (versionSegments[1] > requiredVersionSegments[1]) return true;
  if (versionSegments[2] < requiredVersionSegments[2]) return false;

  return true;
};



