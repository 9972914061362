import { DefaultTheme } from "styled-components";
import { createMuiTheme } from "@material-ui/core";

const muiTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#fff165",
      main: "#F3BF30",
      dark: "#bc8f00",
      contrastText: "#000000",
    },
    secondary: {
      light: "#4e4b4c",
      main: "#262324",
      dark: "#000000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Work Sans, sans-serif",
    fontWeightMedium: 300,
    h1: {
      fontSize: "2.1rem",
      fontWeight: 400,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.4rem",
      fontWeight: 400,
    },
    h4: {
      fontSize: "1.3rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 400,
    },
    body1: {
      fontWeight: 300,
    },
    caption: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 400,
    },
  },
  overrides: {
    MuiDialogContent: {
      root: {
        padding: "20px",
      },
    },
  },
});

const theme: DefaultTheme = {
  spacing: 4,
  palette: muiTheme.palette,
  breakpoints: muiTheme.breakpoints,
  muiTheme: muiTheme,
};

export { theme, muiTheme };
