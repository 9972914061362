import React, { useEffect, useState } from "react";
import { Leaderboard, RiderList, JoinGroupForm } from "components/ui";
import { Tabs, Tab, Card, Chip, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Toolbar, Container, AppBar, ListSubheader, Button } from "@material-ui/core";
import { TabPanel, TabContext } from "@material-ui/lab";
import { BodyText, SimpleCard, Heading, Caption, ConfirmDialog, Markdown } from "components/primitives";
import { listGroupRiders, leaveGroup, removeRiderFromGroup } from "api/group";
import { ActivityFeed } from "components/ui/ActivityFeed";
import MoreIcon from "@material-ui/icons/MoreVert";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import LeaveGroupIcon from "@material-ui/icons/ExitToApp";
import { GridMenuIcon } from "@material-ui/data-grid";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { userStore } from "plumbing/auth";
import { analytics } from "plumbing/analytics";
import { useQuery } from "plumbing/routes";
import { listGroupCoaches } from "api/group/groupCoach";
import { RiderListRiderModel } from "../Riders";

type GroupDashboardViewProps = {
  group: GroupModel;
  onChange?: (groupModel?: GroupModel) => Promise<any>;
  previewMode?: boolean;
};

export const GroupDashboardView = observer(({ group, onChange, previewMode }: GroupDashboardViewProps) => {
  const history = useHistory();
  var query = useQuery();
  const view = query.get("view");
  const [selectedTab, setSelectedTab] = React.useState(view || "Leaderboard");
  useEffect(() => {
    if (selectedTab !== view && !previewMode) setSelectedTab(view || "Leaderboard");
  }, [view, selectedTab, previewMode]);

  const handleJoinGroupRequested = (groupModel: GroupModel) => {
    analytics.sendEvent({
      category: "Groups",
      action: "Join",
      label: "Requested",
    });
    if (onChange) {
      const userStatus = groupModel.signUpQuestions.length === 0 ? "Joined" : "PendingApproval";

      onChange({
        ...groupModel,
        ...group,
        userStatus
      });
    }
  };

  const groupMenu = usePopupState({
    variant: "popover",
    popupId: "groupMenu",
  });
  const [isConfirmLeaveDialogOpen, setIsConfirmLeaveDialogOpen] = useState(false);
  const [isLeaveGroupLoading, setIsLeaveGroupLoading] = useState(false);
  const groupTabMenu = usePopupState({
    variant: "popover",
    popupId: "groupTabMenu",
  });
  const tabNames = ["Leaderboard", "Recent Activity", "Members", "Coaches"];
  if (group?.userStatus !== "Joined") tabNames.push("Join");

  const isGroupMenuVisible = group?.userStatus === "Joined" || userStore.isAdmin;

  return (
    <GroupDashboardLayout>
      <GroupHeader>
        <GroupName>
          <Cardheading>{group.name}</Cardheading>
        </GroupName>
        {isGroupMenuVisible && (
          <GroupMenu>
            <IconButton aria-label="Group Menu" {...bindTrigger(groupMenu)}>
              <MoreIcon />
            </IconButton>
            <Menu {...bindMenu(groupMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
              {group?.userStatus === "Joined" && (
                <MenuItem
                  onClick={() => {
                    setIsConfirmLeaveDialogOpen(true);
                    groupMenu.close();
                  }}
                >
                  <ListItemIcon>
                    <LeaveGroupIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Leave Group" />
                </MenuItem>
              )}
              {userStore.isAdmin && (
                <>
                  <StyledListSubheader>Admin</StyledListSubheader>
                  <MenuItem component={Link} to={`/groups/${group.slug}/edit`}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit Group" />
                  </MenuItem>
                </>
              )}
            </Menu>
          </GroupMenu>
        )}

        <GroupSubheading>
          <Chip label={group.activitySkillType} variant="outlined" color="primary" size="small" />
          <Chip label={`${group.riderCount || 0} members`} variant="outlined" color="primary" size="small" />
        </GroupSubheading>
        <GroupDescription>
          <Markdown>{group.description}</Markdown>
        </GroupDescription>
        <GroupImage>
          <img src={group.imageUrl} alt="Group Logo" />
        </GroupImage>
        {group.userStatus === "NotJoined" && (
          <GroupJoin>
            <Button variant="contained" color="primary" size="medium" onClick={() => setSelectedTab("Join")}>
              Join Group
            </Button>
          </GroupJoin>
        )}
      </GroupHeader>
      <GroupContent>
        <Card>
          <TabContext value={selectedTab}>
            <MenuAppBar position="static" color="primary">
              <Toolbar variant="dense">
                <div style={{ flex: 1 }}>
                  <BodyText>{selectedTab.toUpperCase()}</BodyText>
                </div>

                <IconButton edge="end" aria-label="Group Tab Menu" {...bindTrigger(groupTabMenu)} color="secondary">
                  <GridMenuIcon />
                </IconButton>
                <Menu {...bindMenu(groupTabMenu)} getContentAnchorEl={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                  {tabNames.map((tab) => (
                    <MenuItem
                      key={tab}
                      onClick={() => {
                        setSelectedTab(tab);
                        if (!previewMode) history.push(`/groups/${group.slug}?view=${tab}`);
                        groupTabMenu.close();
                      }}
                    >
                      {tab}
                    </MenuItem>
                  ))}
                </Menu>
              </Toolbar>
            </MenuAppBar>
            <TabAppBar position="static">
              <Tabs
                value={selectedTab}
                onChange={(_e, newValue) => {
                  setSelectedTab(newValue);
                  if (!previewMode) history.push(`/groups/${group.slug}?view=${newValue}`);
                }}
                aria-label="Group Page Sections"
              >
                <Tab label="Leaderboard" value="Leaderboard" />
                <Tab label="Recent Activity" value="Recent Activity" />
                <Tab label="Members" value="Members" />
                <Tab label="Coaches" value="Coaches" />
                {group.userStatus !== "Joined" && <Tab label="Join" value="Join" />}
              </Tabs>
            </TabAppBar>
            <TabPanel value="Leaderboard">{previewMode ? <div>Leaderboard</div> : <Leaderboard groupSlug={group.slug} showTrack />}</TabPanel>
            <TabPanel value="Recent Activity">
              {previewMode ? (
                <div>Recent activities</div>
              ) : (
                <ActivityFeedContainer fixed maxWidth="md">
                  <ActivityFeed groupSlug={group.slug} elevation={6} />
                </ActivityFeedContainer>
              )}
            </TabPanel>
            <TabPanel value="Members">
              {previewMode ? (
                <div>Rider list</div>
              ) : (
                <RiderList
                  query={(pagination) => listGroupRiders(group.slug, pagination)}
                  noRidersMessage="This group doesn't have any members yet"
                  removeRider={(riderId: number) => removeRiderFromGroup(group.slug, riderId)}
                  removeRiderConfirmText="The rider will no longer be able to participate in the group."
                  onChange={onChange}
                  addRider={
                    userStore.isAdmin || (userStore.isCoach && group.coaches.some((coach) => coach.externalIds.some((id) => id === userStore.userDetails?.sub)))
                      ? () => history.push(`/groups/${group.slug}/add-rider`)
                      : undefined
                  }
                />
              )}
            </TabPanel>
            <TabPanel value="Coaches">
              {previewMode ? (
                <div>Coach list</div>
              ) : (
                <RiderList
                  query={async (pagination) => {
                    var response = await listGroupCoaches(group.slug, pagination);
                    return { data: { riders: response.data.coaches.map((c) => ({ ...c } as any as RiderListRiderModel)), totalRiders: response.data.totalCoaches } };
                  }}
                  noRidersMessage="This group doesn't have any coaches yet"
                  showJoinedDate={false}
                />
              )}
            </TabPanel>
            <TabPanel value="Join">
              {group.userStatus === "PendingApproval" && <BodyText>Your request to join this group is currently awaiting approval.</BodyText>}
              {group.userStatus === "Rejected" && <BodyText>Your request to join this group has been rejected. If you believe this is an error, please contact support.</BodyText>}
              {group.userStatus === "NotJoined" && (
                <JoinGroupForm
                  slug={group.slug}
                  questions={group.signUpQuestions}
                  signUpText={group.signUpText}
                  onJoinRequested={handleJoinGroupRequested}
                  previewMode={previewMode}
                />
              )}
            </TabPanel>
          </TabContext>
        </Card>
      </GroupContent>
      <ConfirmDialog
        title="Are you sure?"
        open={isConfirmLeaveDialogOpen}
        loading={isLeaveGroupLoading}
        onClose={async (confirmed) => {
          setIsLeaveGroupLoading(true);
          if (confirmed) {
            const response = await leaveGroup(group.slug);

            if (onChange) onChange(response.data);
          }
          setIsConfirmLeaveDialogOpen(false);
          setIsLeaveGroupLoading(false);
        }}
      >
        <BodyText>You will no longer be able to participate in this group.</BodyText>
      </ConfirmDialog>
    </GroupDashboardLayout>
  );
});

const GroupDashboardLayout = styled.div`
  display: grid;
  grid-template:
    "group-header"
    "group-content";

  grid-gap: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
    grid-gap: ${(props) => props.theme.spacing * 3}px;
  }
`;

const GroupHeader = styled(SimpleCard)`
  display: grid;
  grid-template:
    "group-name group-menu group-image"
    "group-subheading group-subheading group-image"
    "group-description group-description group-image"
    "group-join group-join group-image";
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto auto 1fr auto;
  grid-gap: 0 ${(props) => props.theme.spacing * 6}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    grid-template:
      "group-name group-menu group-image"
      "group-subheading group-subheading group-image"
      "group-description group-description group-description"
      "group-join group-join group-join";
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto 1fr auto auto;
    grid-gap: ${(props) => props.theme.spacing * 2}px;
  }
`;

const GroupImage = styled.div`
  grid-area: group-image;

  img {
    max-width: 500px;
    height: 250px;
    object-fit: contain;

    @media (max-width: ${(props) => props.theme.breakpoints.values["lg"]}px) {
      max-width: 300px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
      max-width: 130px;
      height: 100px;
    }
  }
`;

const GroupContent = styled.div`
  grid-area: group-content;
`;

const GroupName = styled.div`
  grid-area: group-name;
`;

const GroupJoin = styled.div`
  grid-area: group-join;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    button {
      display: block;
      width: 100%;
    }
  }
`;

const GroupMenu = styled.div`
  grid-area: group-menu;
  button {
    margin-top: -12px;
  }
`;

const Cardheading = styled(Heading)`
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    font-size: 1.4rem;
  }
`;

const GroupSubheading = styled(Caption)`
  grid-area: group-subheading;
  display: flex;
  gap: ${(props) => props.theme.spacing * 2}px;
`;

const GroupDescription = styled(BodyText)`
  grid-area: group-description;
  margin-top: ${(props) => props.theme.spacing * 6}px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: 0;
  }
`;

const ActivityFeedContainer = styled(Container)`
  padding: 0;
  width: 630px;

  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    width: auto;
  }
`;

const TabAppBar = styled(AppBar)`
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    display: none;
  }
`;

const MenuAppBar = styled(AppBar)`
  @media (min-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    display: none;
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  line-height: 1;
  margin-top: ${(props) => props.theme.spacing}px;
  margin-bottom: ${(props) => props.theme.spacing}px;
`;
