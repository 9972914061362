import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const PageGrid = styled(Grid)`
  margin-top: ${(props) => props.theme.spacing * 6}px;
  margin-bottom: ${(props) => props.theme.spacing * 6}px;
  @media (max-width: ${(props) => props.theme.breakpoints.values["md"]}px) {
    margin-top: ${(props) => props.theme.spacing * 3}px;
    margin-bottom: ${(props) => props.theme.spacing * 3}px;
  }
`;
