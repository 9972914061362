import axios from "axios";
import { apiConfiguration } from "plumbing/api";
import { serialize } from "object-to-formdata";

export const createGroup = (request: CreateGroupRequest) => {
  const url = `${apiConfiguration.apiUrl}/groups`;
  const formData = serialize(request, { indices: true });

  return axios.post<GroupModel>(url, formData);
};
