import React from "react";
import { Card, CardContent } from "@material-ui/core";

export const SimpleCard = ({ children, className, elevation }: { children: React.ReactNode; className?: string; elevation?: number }) => {
  return (
    <Card elevation={elevation}>
      <CardContent className={className}>{children}</CardContent>
    </Card>
  );
};
