import React from "react";
import { TextField, SelectField, CheckboxField } from "components/primitives";
import { MenuItem } from "@material-ui/core";

type QuestionFormItemProps = {
  question: SignUpQuestionModel;
  previewMode?: boolean;
};

export const QuestionFormItem = ({ question, previewMode }: QuestionFormItemProps) => {
  const name = question.id?.toString() || "0";
  if (question.questionType === "Text") return <TextField name={name} label={question.label} disabled={previewMode} />;

  if (question.questionType === "Dropdown")
    return (
      <SelectField name={name} label={question.label} disabled={previewMode}>
        {question.optionsValues!.map((o) => (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        ))}
      </SelectField>
    );

  if (question.questionType === "Checkbox") return <CheckboxField name={name} label={question.label} disabled={previewMode} />;

  return null;
};
