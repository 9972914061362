import React, { useState, useEffect } from "react";
import { ActivityCard, ActivityCardLoading } from "./ActivityCard";
import { Button, Link, Select, MenuItem } from "@material-ui/core";
import { getActivityFeed } from "api/activityFeed";
import { get, set } from "local-storage";
import styled from "styled-components";
import { BodyText } from "components/primitives";

const localStorageKeyUsers: string = "activityFilter-Users";
const localStorageKeySkill: string = "activityFilter-SkillType";

export type ActivityFeedProps = {
  groupSlug?: string;
  challengeSlug?: string;
  elevation?: number;
  showUsersFilter?: boolean;
  showSkillsFilter?: boolean;
};

export const ActivityFeed = ({ showUsersFilter, showSkillsFilter, groupSlug, challengeSlug, elevation = 0 }: ActivityFeedProps) => {
  const [activityFeedModel, setActivityFeedModel] = useState<ActivityFeedModel | undefined>(undefined);
  const [items, setItems] = useState<ActivityFeedItemModel[]>([]);
  const [filterBefore, setFilterBefore] = useState<string | undefined>();
  const [lastRefreshRequested, setLastRefreshRequested] = useState<Date | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [activityUsersFilter, setActivityUsersFilter] = useState<ActivityFeedUserFilter | undefined>(() =>
    showUsersFilter ? get<ActivityFeedUserFilter>(localStorageKeyUsers) || "Everyone" : undefined
  );

  const [activitySkillTypeFilter, setActivitySkillTypeFilter] = useState<ActivityFeedSkillTypeFilter | undefined>(() =>
    showSkillsFilter ? get<ActivityFeedSkillTypeFilter>(localStorageKeySkill) || "Street" : undefined
  );

  const handleRetry = () => {
    setIsError(false);
    refresh();
  };

  const refresh = () => {
    setItems([]);
    setFilterBefore(undefined);
    setLastRefreshRequested(new Date());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const request: GetActivityFeedRequest = {
          usersFilter: activityUsersFilter,
          skillTypeFilter: activitySkillTypeFilter,
          before: filterBefore,
          groupSlug,
          challengeSlug,
        };

        const response = await getActivityFeed(request);
        setActivityFeedModel(response.data);
        setItems((i) => [
          ...i,
          // This filtering is only required because hot reloading gets confused sometimes. In prod, we should never get duplicates
          ...response.data.items.filter((newItem) => i.findIndex((existingItem) => existingItem.activityId === newItem.activityId) === -1),
        ]);
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [activityUsersFilter, activitySkillTypeFilter, filterBefore, groupSlug, lastRefreshRequested, setIsLoading, challengeSlug]);

  const handleActivityUsersFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const usersFilter = event.target.value as ActivityFeedUserFilter;
    set<ActivityFeedUserFilter>(localStorageKeyUsers, usersFilter);
    setActivityUsersFilter(usersFilter);
    refresh();
  };

  const handleActivitySkillTypeFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const skillTypeFilter = event.target.value as ActivityFeedSkillTypeFilter;
    set<ActivityFeedSkillTypeFilter>(localStorageKeySkill, skillTypeFilter);
    setActivitySkillTypeFilter(skillTypeFilter);
    refresh();
  };

  return (
    <>
      {(showUsersFilter || showSkillsFilter) && (
        <ActivityFilter>
          {showUsersFilter && (
            <SelectFilter value={activityUsersFilter} onChange={handleActivityUsersFilterChange}>
              <MenuItem value="Everyone">Everyone</MenuItem>
              <MenuItem value="Mine">My Activities</MenuItem>
            </SelectFilter>
          )}
          {showSkillsFilter && (
            <SelectFilter value={activitySkillTypeFilter} onChange={handleActivitySkillTypeFilterChange}>
              <MenuItem value="Street">Street Riders</MenuItem>
              <MenuItem value="Track">Track Riders</MenuItem>
            </SelectFilter>
          )}
        </ActivityFilter>
      )}
      {activityFeedModel && items.map((i) => <ActivityCard key={i.activityId} activity={i} elevation={elevation} />)}
      {isLoading && <ActivityCardLoading />}
      {!isLoading && !items?.length && !isError && <BodyText>No activities found</BodyText>}
      {activityFeedModel && activityFeedModel.moreAvailable && !isLoading && (
        <div style={{ textAlign: "center", paddingBottom: "16px" }}>
          <Button disabled={isLoading} onClick={() => setFilterBefore(items.length > 0 ? items[items.length - 1].dateTime : undefined)}>
            Load More
          </Button>
        </div>
      )}
      {isError && (
        <div>
          There was a problem loading the activity feed&nbsp;
          <Link href="#" onClick={handleRetry}>
            Try Again
          </Link>
        </div>
      )}
    </>
  );
};

const ActivityFilter = styled.div`
  margin-bottom: ${(props) => props.theme.spacing * 6}px;
`;

const SelectFilter = styled(Select)`
  margin-right: ${(props) => props.theme.spacing * 6}px;
`;
