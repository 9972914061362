import React from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Label, ReferenceLine } from "recharts";
import { useTheme } from "@material-ui/core";
import { lineChartConfig, chartConfig } from "./chartConfig";
import styled from "styled-components";

export type ReferenceData = {
  x: number[];
  y: number[];
};

export type TelemetryLineChartProps = {
  dataVersion: string;
  data: any;
  referenceData: ReferenceData;
  xAxisLabel: string;
  yAxisLabel: string;
  showYZero: boolean;
  showXZero: boolean;
  xFeatureValue?: number;
  yFeatureValue?: number;
  featureLabel?: string;
  activitySkillType: string;
};

type DataPoint = {
  X?: number | string;
  RY?: number | string;
};

export const TelemetryLineChart = ({
  dataVersion,
  data,
  referenceData,
  xAxisLabel,
  yAxisLabel,
  showXZero,
  showYZero,
  featureLabel,
  yFeatureValue,
  xFeatureValue,
  activitySkillType,
}: TelemetryLineChartProps) => {
  const theme = useTheme();
  const axisColor = theme.palette.common.white;

  dataVersion = dataVersion ?? "0.0.0";
  // Latest version is always configured through the props.
  // This will check to see if we need to adjust those props
  // based on the version of the data.
  showXZero = showXZero && isVersionAtLeast("1.0.304", dataVersion);
  showYZero = showYZero && isVersionAtLeast("1.0.304", dataVersion);
  featureLabel = showXZero ? featureLabel : undefined;
  xFeatureValue = showXZero ? xFeatureValue : undefined;
  yFeatureValue = showYZero ? yFeatureValue : undefined;

  const reference = isVersionAtLeast("1.0.304", dataVersion) ? (activitySkillType === "Street" ? convertToInternalData(referenceData) : (isVersionAtLeast("1.0.632", dataVersion) ? [] : convertToInternalData(referenceData))) : [];
  //const reference = isVersionAtLeast("1.0.304", dataVersion) ? convertToInternalData(referenceData) : [];


  return (
    <StyledTelemetryLineChart>
      <ResponsiveContainer width="100%" aspect={chartConfig.aspectRatio}>
        <LineChart margin={{ top: 0, right: 0, bottom: 22, left: 0 }}>
          <Line
            type="monotone"
            dataKey="Y"
            stroke={theme.palette.primary.main}
            dot={false}
            strokeWidth={2}
            {...lineChartConfig}
            data={data}
          />
          <Line type="monotone" dataKey="RY" stroke="green" dot={false} strokeWidth={2} {...lineChartConfig} data={reference} />
          {showXZero && (
            <ReferenceLine x={xFeatureValue ?? 0} stroke={theme.palette.grey[700]} strokeWidth={2} strokeDasharray="5 5">
              {featureLabel && <Label value={featureLabel} position="bottom" offset={22} />}
            </ReferenceLine>
          )}
          {showYZero && <ReferenceLine y={yFeatureValue ?? 0} stroke={theme.palette.grey[700]} strokeWidth={2} strokeDasharray="5 5" />}
          <XAxis stroke={axisColor} type="number" dataKey="X">
            <Label value={xAxisLabel} position="bottom" offset={10} />
          </XAxis>
          <YAxis stroke={axisColor}>
            <Label value={yAxisLabel} angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
          </YAxis>
        </LineChart>
      </ResponsiveContainer>
    </StyledTelemetryLineChart>
  );
};

const convertToInternalData = (data: ReferenceData): DataPoint[] => {
  let array: DataPoint[] = [];
  try {
    data.x.forEach((x, i) => {
      array.push({ X: x, RY: data.y[i] });
    });
  } catch (err) {
    console.dir(err);
  }
  return array;
};

const isVersionAtLeast = (requiredVersion: string, version: string): boolean => {
  // Version is a string in three parts "X.Y.Z[.A]"
  const requiredVersionSegments: number[] = requiredVersion.split(".").map((x) => parseInt(x, 10));
  const versionSegments: number[] = (version ?? "0.0.0.0").split(".").map((x) => parseInt(x, 10));

  if (requiredVersionSegments.length < 3 || versionSegments.length < 3) {
    console.dir(`Algorithm version ${version} is not in the expected format.  Assuming versions don't match for graph calculations`);
    return false;
  }

  if (versionSegments[0] < requiredVersionSegments[0]) return false;
  if (versionSegments[0] > requiredVersionSegments[0]) return true;
  if (versionSegments[1] < requiredVersionSegments[1]) return false;
  if (versionSegments[1] > requiredVersionSegments[1]) return true;
  if (versionSegments[2] < requiredVersionSegments[2]) return false;

  return true;
};

export const StyledTelemetryLineChart = styled.div`
  .xAxis,
  .yAxis,
  .recharts-reference-line {
    text {
      fill: #fff;
      font-size: 1rem;
      font-weight: 300;
    }
  }
`;
