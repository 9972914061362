import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import {
  Home,
  UploadActivityPage,
  ActivityDashboard,
  ManageSubscription,
  Training,
  StreetSkills101,
  TrackSkills101,
  Tutorial,
  CameraSetup,
  CompareActivities,
  Contact,
  FAQ,
  TrainingVideo,
  AboutUs,
  GroupDashboard,
  Landing,
  Groups,
  ChallengeDashboard,
  Challenges,
  EditChallenge,
  CreateChallenge,
  EditGroup,
  CreateGroup,
  TrackSkills,
  MotoChampionLanding,
  UploadActivityVideosPage,
} from "components/pages";
import { ProtectedRoute } from "./plumbing/auth";
import { Container } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingSpinner } from "components/primitives";
import { useGoogleAnalytics } from "plumbing/analytics";
import { AddGroupRider } from "components/pages/Groups/AddGroupRider";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const Routes = () => {
  useGoogleAnalytics();

  return (
    <>
      <ScrollToTop />
      <Switch>
        {/* Private routes */}
        <ProtectedRoute path="/upload-activity">
          <Container maxWidth="md">
            <UploadActivityPage />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/activities/:id" exact>
          <Container maxWidth="lg">
            <ActivityDashboard />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/activities/:id/upload-videos" exact>
          <Container maxWidth="md">
            <UploadActivityVideosPage />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/compare-activities">
          <Container maxWidth="lg">
            <CompareActivities />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/manage-subscription">
          <Container maxWidth="sm">
            <ManageSubscription />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/training" exact>
          <Container maxWidth="lg">
            <Training />
          </Container>
        </ProtectedRoute>
        <Route path="/training/videos/:id">
          <Container maxWidth="lg">
            <TrainingVideo />
          </Container>
        </Route>
        <ProtectedRoute path="/groups/create" requiredPermissions={["Admin"]} exact>
          <Container maxWidth="lg">
            <CreateGroup />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/groups/:slug/edit" requiredPermissions={["Admin"]} exact>
          <Container maxWidth="lg">
            <EditGroup />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/groups/:slug/add-rider" requiredPermissions={["Admin", "Coach"]} exact>
          <Container maxWidth="md">
            <AddGroupRider />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/groups" exact>
          <Container maxWidth="lg">
            <Groups />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/groups/:slug">
          <Container maxWidth="lg">
            <GroupDashboard />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/challenges" exact>
          <Container maxWidth="lg">
            <Challenges />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/challenges/create" requiredPermissions={["Admin"]} exact>
          <Container maxWidth="lg">
            <CreateChallenge />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/challenges/:slug/edit" requiredPermissions={["Admin"]} exact>
          <Container maxWidth="lg">
            <EditChallenge />
          </Container>
        </ProtectedRoute>
        <ProtectedRoute path="/challenges/:slug">
          <Container maxWidth="lg">
            <ChallengeDashboard />
          </Container>
        </ProtectedRoute>

        {/* Public routes */}
        <Route path="/loading">
          <Container maxWidth="lg">
            <LoadingSpinner />
          </Container>
        </Route>
        <Route path="/instructions/camera-set-up">
          <Container maxWidth="lg">
            <CameraSetup />
          </Container>
        </Route>
        <Route path="/instructions/streetSKILLS-101">
          <Container maxWidth="lg">
            <StreetSkills101 />
          </Container>
        </Route>
        <Route path="/instructions/trackSKILLS-101">
          <Container maxWidth="lg">
            <TrackSkills101 />
          </Container>
        </Route>
        <Route path="/instructions/trackSKILLS" exact>
          <Container maxWidth="lg">
            <TrackSkills />
          </Container>
        </Route>
        <ProtectedRoute path="/instructions/tutorial">
          <Container maxWidth="lg">
            <Tutorial />
          </Container>
        </ProtectedRoute>
        <Route path="/contact">
          <Container maxWidth="lg">
            <Contact />
          </Container>
        </Route>
        <Route path="/faq">
          <Container maxWidth="lg">
            <FAQ />
          </Container>
        </Route>
        <Route path="/about">
          <Container maxWidth="lg">
            <AboutUs />
          </Container>
        </Route>
        <Route path="/motochampion">
          <MotoChampionLanding />
        </Route>
        <Route path="/">
          <HomePageSelector />
        </Route>
      </Switch>
    </>
  );
};

const HomePageSelector = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isAuthenticated) {
    return (
      <ProtectedRoute>
        <Container maxWidth="lg">
          <Home />
        </Container>
      </ProtectedRoute>
    );
  }

  return <Landing />;
};
