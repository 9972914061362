import React from "react";

export const WeightDistChart = ({ front, rear }: { front: number; rear: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" height="100%" style={{ fontFamily: "Work Sans" }}>
      <rect
        x="2"
        y="2"
        height="80"
        width="25"
        stroke="transparent"
        strokeWidth="0"
        fill={"#f3bf30"}
        transform-origin="0 82"
        style={{
          transform: `scaleY(${front / 100})`,
          transition: "transform 0.1s linear",
        }}
      />
      <rect x="1" y="1" height="82" width="27" stroke="#fff" strokeWidth="2" rx="1" fill={"transparent"} />
      <rect
        x="73"
        y="2"
        height="80"
        width="25"
        stroke="transparent"
        strokeWidth="0"
        fill={"#f3bf30"}
        transform-origin="0 82"
        style={{
          transform: `scaleY(${rear / 100})`,
          transition: "transform 0.1s linear",
        }}
      />
      <rect x="72" y="1" height="82" width="27" stroke="#fff" strokeWidth="2" rx="1" fill={"transparent"} />
      <text x="14.5" y="100" textAnchor="middle" fontSize="13pt" fill="#fff">
        FR
      </text>
      <text x="84.5" y="100" textAnchor="middle" fontSize="13pt" fill="#fff">
        RR
      </text>
      <text x="50" y="10" textAnchor="middle" fontSize="9pt" fill="#fff">
        100%
      </text>
      <text x="50" y="40" textAnchor="middle" dominantBaseline="central" fontSize="9pt" fill="#fff">
        50%
      </text>
      <text x="50" y="79" textAnchor="middle" fontSize="9pt" fill="#fff">
        0%
      </text>
    </svg>
  );
};
